import { useContext, useEffect, useRef, useState } from 'react';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import { Location } from 'history';
import { HtmlMessage } from '@satreg/drupal';
import { Checkbox, Input, RadioButton, RadioButtonGroup } from '@cb/apricot-react-forms';
import { Glyph } from '@cb/apricot-react-glyph';
import { PrimaryButton } from '@cb/apricot-react-button';
import { Spinner } from '@cb/apricot-react-spinner';
import { webAnalyticsContext } from '@satreg/analytics';
import { MathmlProvider, useMathml } from '@satui/test-questions';
import routes from '../../../../../routes';
import {
  IDomainScore,
  IQbankTailoredQuestionData,
  ITailoredQuestionParams,
  IScoreBand,
} from '../../../../../contexts/questionbank/api/types';
import QbankTailoredQuestionDataProvider, {
  qbankTailoredQuestionDataContext,
} from '../../../../../contexts/questionbank/api/QbankTailoredQuestionDataProvider';
import './styles/index.scss';
import { Illustration } from '@cb/apricot-react-illustration';
import questionbankRoutes from '../routes';
import KsdDisplay from '../../../../ksdDisplay';
import { Score, KsdCode, ProgramCode } from '../../../../ksdDisplay/types';
import { getDifficulty } from '../../../../ksdDisplay/utils';

const TailoredReview: React.FC<{ location: Location }> = ({ location }) => {
  const [currentQuestion, setCurrentQuestion] = useState<IQbankTailoredQuestionData>();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [domainScores, setDomainScores] = useState<IDomainScore[]>();
  const [difficultyLevel, setDifficultyLevel] = useState('');
  const [currentDomain, setCurrentDomain] = useState<IScoreBand>();
  const [asmtEventId, setAsmtEventId] = useState<number>();

  const { getTailoredQbankQuestionData, tailoredQbankQuestionData, updateSelectedAnswer } = useContext(
    qbankTailoredQuestionDataContext,
  );
  const locationState =
    (location.state as {
      title: string;
      date: string;
      tailoredQuestionParams?: ITailoredQuestionParams;
    }) || undefined;
  const history = useHistory();
  const { reportPageData } = useContext(webAnalyticsContext);

  const { isReady, resolveMathml } = useMathml();
  const bodyRef = useRef<HTMLDivElement>(null);

  const onAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.defaultValue;
    updateSelectedAnswer(value, currentQuestionIndex);
  };

  // gets tailored questions params from location.state and calls the get tailored questions api
  useEffect(() => {
    window.scrollTo({ top: 0 });

    // if there are no params, return user to the dashboard
    if (!(location.state || locationState?.tailoredQuestionParams)) history.push(routes.dashboard);

    // get tailored questions, set title, and report page to WA
    if (locationState?.tailoredQuestionParams) {
      getTailoredQbankQuestionData(locationState.tailoredQuestionParams);
      setDomainScores(locationState.tailoredQuestionParams.domainScores);
      setAsmtEventId(locationState.tailoredQuestionParams.asmtEventId);

      document.title = locationState.title;

      reportPageData({
        flowCode: 'satpractice',
        pageCode: 'questionbank/tailored',
        appViewCode: '',
      });
    }
  }, [location, history]);

  useEffect(() => {
    if (tailoredQbankQuestionData) setCurrentQuestion(tailoredQbankQuestionData[currentQuestionIndex]);
  }, [tailoredQbankQuestionData]);

  // initialize mathml
  useEffect(() => {
    if (isReady && bodyRef.current && currentQuestion?.section === 'Math') {
      resolveMathml(bodyRef.current).then(() => {
        // resolved all the mathml code
      });
    }
  }, [isReady, bodyRef.current, currentQuestion]);

  // add mathml alt text to base mathml element
  useEffect(() => {
    if (currentQuestion?.section === 'Math') {
      const mathjaxContainers = document.querySelectorAll('mjx-container');
      mathjaxContainers.forEach((container) => {
        // get alttext from assistive-ml element
        const mathEl = container.childNodes[1].firstChild as HTMLElement;
        const altText = mathEl.getAttribute('alttext');

        // append alttext to main element
        if (altText) {
          container.setAttribute('role', 'img');
          container.setAttribute('aria-label', altText);
        }
      });
    }
  }, [currentQuestion]);

  useEffect(() => {
    if (currentQuestion?.section === 'Reading and Writing') {
      const scoreBands = domainScores?.find((score) => score.anchorScoreTableId === 'theta_table_reading')?.scoreBands;
      setCurrentDomain(scoreBands?.find((score) => score.primaryClassCd === currentQuestion.primary_class_cd));
    }

    if (currentQuestion?.section === 'Math') {
      const scoreBands = domainScores?.find((score) => score.anchorScoreTableId === 'theta_table_math')?.scoreBands;
      setCurrentDomain(scoreBands?.find((score) => score.primaryClassCd === currentQuestion.primary_class_cd));
    }
  }, [currentQuestion?.primary_class_cd]);

  useEffect(() => {
    if (currentDomain?.scoreBandCd) {
      setDifficultyLevel(getDifficulty(asmtEventId as ProgramCode, parseInt(currentDomain.scoreBandCd, 10) as Score));
    }
  }, [currentDomain]);

  if (!(location.state || locationState?.tailoredQuestionParams) || !tailoredQbankQuestionData || !currentQuestion)
    return <Spinner />;

  return (
    <div className="cb-padding-top-48" id="tailored-review">
      <div className="review-window ">
        <div className="header cb-border-bottom cb-border-bottom-2 cb-gray4-border">
          <div className="assessment-title">
            <h2>{locationState.title}</h2>
            <p className="psq-date">{locationState.date}</p>
          </div>
          {currentDomain?.scoreBandCd && (
            <div className="ksd-wrapper">
              <KsdDisplay
                score={parseInt(currentDomain?.scoreBandCd, 10) as Score}
                ksdCode={currentDomain?.primaryClassCd as KsdCode}
                programCode={asmtEventId as ProgramCode}
                psq
                testId=""
              />
            </div>
          )}
        </div>
        <div className="body cb-roboto-slab display-flex flex-wrap" ref={bodyRef}>
          <div className="tailored-question-content">
            <h3
              id={`ques_${(tailoredQbankQuestionData.indexOf(currentQuestion) + 1).toString()}`}
              className="cb-roboto-slab-bold current-question"
            >
              Question {tailoredQbankQuestionData.indexOf(currentQuestion) + 1}
            </h3>
            {difficultyLevel !== '' ? (
              <div className="cb-roboto-slab-bold cb-margin-bottom-16 difficulty-level">
                Difficulty level: {difficultyLevel}
              </div>
            ) : null}
            <div className="stimulus">
              <HtmlMessage message={currentQuestion.stimulus} />
            </div>
            <div className="stem">
              <HtmlMessage message={currentQuestion.stem} />
            </div>
            <div className="answer-options">
              {currentQuestion.answerOptions.length ? (
                <RadioButtonGroup
                  ariaLabelledBy={`ques_${(tailoredQbankQuestionData.indexOf(currentQuestion) + 1).toString()}`}
                  name="export-radio-group"
                  vertical
                  vSpacing="16"
                >
                  {currentQuestion.answerOptions.map((option) => {
                    const isChecked = () => {
                      if (currentQuestion.selectedAnswer === option.letter) return true;
                      return false;
                    };

                    const labelElement = (
                      <div className="answer-option">
                        <p>{option.letter}. </p>
                        <HtmlMessage message={option.content} />
                      </div>
                    );

                    return (
                      <RadioButton
                        onChange={onAnswerChange}
                        checked={isChecked()}
                        key={option.letter}
                        value={option.letter}
                        label={labelElement}
                      />
                    );
                  })}
                </RadioButtonGroup>
              ) : (
                <div className="free-response">
                  <Input
                    value={currentQuestion.selectedAnswer || ''}
                    clearable={false}
                    onChange={(e) => {
                      updateSelectedAnswer(e.currentTarget.value, currentQuestionIndex);
                    }}
                    aria-label="answer box"
                  />
                </div>
              )}
            </div>
            <Checkbox
              onChange={() => setShowAnswer(!showAnswer)}
              className="show-correct"
              label="Show me the correct answer and explanation."
            />
          </div>
          {showAnswer && (
            <div className="tailored-answer-content">
              <div className="correct-answer">
                <h3 className="cb-roboto-slab-bold">Correct Answer: {currentQuestion.correctAnswer}</h3>
                {currentQuestion.selectedAnswer &&
                  currentQuestion.correctAnswer.includes(currentQuestion.selectedAnswer) && (
                    <Glyph name="check-circle" color="green1" />
                  )}
              </div>
              <div className="rationale">
                <h3 className="cb-roboto-slab-bold cb-margin-bottom-16">Rationale</h3>
                <HtmlMessage message={currentQuestion.rationale} />
              </div>
            </div>
          )}
        </div>
        <div className="footer cb-border-top cb-border-top-2 cb-gray4-border">
          <div>
            <PrimaryButton
              disabled={currentQuestionIndex === 0}
              onClick={() => {
                if (currentQuestionIndex > 0) {
                  setCurrentQuestion(tailoredQbankQuestionData[currentQuestionIndex - 1]);
                  setCurrentQuestionIndex(currentQuestionIndex - 1);
                }
              }}
            >
              Back
            </PrimaryButton>
          </div>
          <div>
            <p className="review-progress cb-align-center cb-roboto-bold">
              Question {tailoredQbankQuestionData.indexOf(currentQuestion) + 1} of {tailoredQbankQuestionData.length}
            </p>
          </div>
          <div className="skip-next">
            <PrimaryButton
              disabled={!(currentQuestionIndex < tailoredQbankQuestionData.length - 1)}
              onClick={() => {
                if (currentQuestionIndex < tailoredQbankQuestionData.length - 1) {
                  setCurrentQuestion(tailoredQbankQuestionData[currentQuestionIndex + 1]);
                  setCurrentQuestionIndex(currentQuestionIndex + 1);
                }
              }}
            >
              Next
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className="self-guided-card-wrapper">
        <div className="self-guided-card cb-margin-top-48 cb-padding-24">
          <div className="self-guided-flex-start cb-margin-right-16">
            <div>
              <Illustration name="checklist-device" circular shadow id="self-guided-illus" />
            </div>
            <div className="sqb-card-content cb-margin-left-16">
              <h3 className="cb-font-size-regular cb-font-weight-bold cb-padding-bottom-8" id="sqb-card-title">
                Student Question Bank
              </h3>
              <div>
                <p>
                  Use the Student Question Bank to find additional questions to practice and help improve your score.
                </p>
              </div>
            </div>
          </div>
          <a
            href={`${window.location.origin}${questionbankRoutes.search}`}
            role="button"
            className="cb-btn cb-btn-naked cb-glyph cb-black1-color btn-border-removal cb-font-size-regular"
            target="_blank"
            rel="noreferrer"
          >
            <span className="cb-glyph cb-right" aria-hidden="false">
              <span className="sr-only">Student Question Bank</span>
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

const WrappedTailoredReview: React.FC<RouteComponentProps> = ({ location }) => {
  return (
    <QbankTailoredQuestionDataProvider>
      <MathmlProvider>
        <TailoredReview location={location} />
      </MathmlProvider>
    </QbankTailoredQuestionDataProvider>
  );
};

export default withRouter(WrappedTailoredReview);
