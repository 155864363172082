import { useState } from 'react';
import { Tooltip } from '@cb/apricot-react-tooltip';
import { useFunctionalContentItems } from '@satui/drupal-profile';
import { HtmlMessage } from '@satreg/drupal';
import { KsdCode, ProgramCode, Score } from './types';
import { ksdCodeValuePairs } from './constants';
import { getDifficulty, getKsdDescription } from './utils';
import { KSD_KEYS } from '../drupal';
import './styles/index.scss';

interface KsdDisplayProps {
  ksdCode: KsdCode;
  programCode: ProgramCode;
  score: Score;
  description?: boolean;
  psq?: boolean;
  testId: string;
  className?: string;
}

const KsdDisplay: React.FC<KsdDisplayProps> = ({
  ksdCode,
  programCode,
  score,
  description,
  psq,
  testId,
  className,
}) => {
  const [uuid] = useState(crypto.randomUUID().slice(0, 8));

  const { drupalContent } = useFunctionalContentItems(Object.values(KSD_KEYS));

  if (!drupalContent) {
    return null;
  }

  const {
    [KSD_KEYS.EASY_TOOLTIP]: easyTooltipContent,
    [KSD_KEYS.MEDIUM_TOOLTIP]: mediumTooltipContent,
    [KSD_KEYS.HARD_TOOLTIP]: hardTooltipContent,
    [KSD_KEYS.EASY_TOOLTIP_PSQ]: easyTooltipContentPsq,
    [KSD_KEYS.MEDIUM_TOOLTIP_PSQ]: mediumTooltipContentPsq,
    [KSD_KEYS.HARD_TOOLTIP_PSQ]: hardTooltipContentPsq,
  } = drupalContent;

  return (
    <div className={`ksd-display ${className || ''}`}>
      <div className="ksd-title cb-paragraph1-st cb-font-weight-bold">
        {description ? <p>{ksdCodeValuePairs[ksdCode]}</p> : <p>Knowledge and Skills: {ksdCodeValuePairs[ksdCode]}</p>}
      </div>
      {description && <p className="cb-paragraph2-st description">{getKsdDescription(ksdCode, programCode, testId)}</p>}
      <div className="score-indicators">
        <div className={`score-indicator ${score > 0 && 'filled'}`} />
        <div className={`score-indicator ${score > 1 && 'filled'}`} />
        <div className={`score-indicator ${score > 2 && 'filled'}`} />
        <div className={`score-indicator ${score > 3 && 'filled'}`} />
        <div className={`score-indicator ${score > 4 && 'filled'}`} />
        <div className={`score-indicator ${score > 5 && 'filled'}`} />
        <div className={`score-indicator ${score > 6 && 'filled'}`} />
      </div>
      <p id={`${uuid}-difficulty`} className="cb-text-meta difficulty cb-link-dotted">
        Difficulty level: <span className="cb-blue2-color">{getDifficulty(programCode, score)}</span>
      </p>
      {psq ? (
        <Tooltip trigger={`${uuid}-difficulty`}>
          {getDifficulty(programCode, score) === 'Easy' && <HtmlMessage message={easyTooltipContentPsq.overview} />}
          {getDifficulty(programCode, score) === 'Medium' && <HtmlMessage message={mediumTooltipContentPsq.overview} />}
          {getDifficulty(programCode, score) === 'Hard' && <HtmlMessage message={hardTooltipContentPsq.overview} />}
        </Tooltip>
      ) : (
        <Tooltip trigger={`${uuid}-difficulty`}>
          {getDifficulty(programCode, score) === 'Easy' && <HtmlMessage message={easyTooltipContent.overview} />}
          {getDifficulty(programCode, score) === 'Medium' && <HtmlMessage message={mediumTooltipContent.overview} />}
          {getDifficulty(programCode, score) === 'Hard' && <HtmlMessage message={hardTooltipContent.overview} />}
        </Tooltip>
      )}
    </div>
  );
};

export default KsdDisplay;
