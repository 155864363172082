import { createContext, useEffect, useMemo, useState } from 'react';
import {
  DIGITAL_PRACTICE,
  GET_TESTING_RULES_STATUS,
  POST_TESTING_RULES_STATUS,
  MS_PRACTICE_TERMS_CONDITIONS,
  useApi,
} from '@satreg/api';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { ITestingRulesStatusRes } from '../../types/testingRulesStatus';
import { getTempHeaders } from './utils';

interface ITestingRulesStatusContext {
  testingRulesStatus: ITestingRulesStatusRes | undefined;
  updateTestingRulesStatus: () => void;
  isLoaded: boolean;
  error: boolean;
}

const defaultState: ITestingRulesStatusContext = {
  testingRulesStatus: undefined,
  updateTestingRulesStatus: () => {},
  isLoaded: false,
  error: false,
};

export const testingRulesStatusContext = createContext(defaultState);
const { Provider } = testingRulesStatusContext;

/**
 * Provides functions to call both the getTestingRules service and the updateTestingRules service.
 * Provides the getTestingRules service response to the application.
 */
const TestingRulesStatusProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const testingRulesAPI = useApi(DIGITAL_PRACTICE, MS_PRACTICE_TERMS_CONDITIONS);
  const [testingRulesStatus, setTestingRulesStatus] = useState<ITestingRulesStatusRes>();
  const [getTestingRulesStatusFlag, setGetTestingRulesStatusFlag] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  const cookies = new Cookies();

  /**
   * If request is successful, set testingRulesStatus data to state and set isLoaded to true.
   */
  const onGetSuccess = (res: ITestingRulesStatusRes | undefined) => {
    setTestingRulesStatus(res);
    setIsLoaded(true);
  };

  const onCreateSuccess = () => {
    // flip the value of this flag to trigger a get status call
    setGetTestingRulesStatusFlag(!getTestingRulesStatusFlag);
  };

  /**
   * If request fails, set error and isLoaded to true.
   */
  const onFailure = () => {
    setError(true);
    setIsLoaded(true);
  };

  /**
   * Function to update student's testing rules status to "seen".
   */
  const updateTestingRulesStatus = useMemo(() => {
    const updateStatus = () => {
      if (!testingRulesAPI.isLoaded) return;

      const tempCookie = cookies.get('__Host-tempJwtToken');

      if (tempCookie) {
        axios
          .post(process.env.REACT_APP_CREATE_TESTING_RULES_ENDPOINT!, {}, { headers: getTempHeaders() })
          .then(() => {
            onCreateSuccess();
          })
          .catch(() => {
            onFailure();
          });
      } else {
        if (testingRulesAPI.authUnavailable) return;

        testingRulesAPI.post(POST_TESTING_RULES_STATUS, {}, onCreateSuccess, onFailure);
      }
    };
    return updateStatus;
  }, [testingRulesAPI.authUnavailable, testingRulesAPI.isLoaded]);

  // Call the testing rules microservice and get status
  useEffect(() => {
    if (!testingRulesAPI.isLoaded) return;

    const tempCookie = cookies.get('__Host-tempJwtToken');

    // if temp creds user, append temp headers to request
    if (tempCookie) {
      axios
        .post(process.env.REACT_APP_GET_TESTING_RULES_ENDPOINT!, {}, { headers: getTempHeaders() })
        .then(({ data }: { data: ITestingRulesStatusRes }) => {
          onGetSuccess(data);
          setIsLoaded(true);
        })
        .catch(() => {
          onFailure();
        });
    }
    // If Okta user, make api call through useApi hook rather than through axios.
    else {
      if (testingRulesAPI.authUnavailable) return;

      testingRulesAPI.post(GET_TESTING_RULES_STATUS, {}, onGetSuccess, onFailure);
    }
  }, [testingRulesAPI.authUnavailable, testingRulesAPI.isLoaded, getTestingRulesStatusFlag]);

  return <Provider value={{ testingRulesStatus, updateTestingRulesStatus, isLoaded, error }}>{children}</Provider>;
};

export default TestingRulesStatusProvider;
