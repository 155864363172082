import { ICustomField, ICustomHeader } from '@cb/apricot-react-table';
import { IQbankTableData } from '../../../../../../../../contexts/questionbank/api/types';
import TableCheckbox from '../customComponents/TableCheckbox';
import ViewQuestionButton from '../customComponents/ViewQuestionButton';
import CheckedHeader from '../customComponents/CheckedHeader';
import DifficultyHeader from '../customComponents/DifficultyHeader';
import DomainHeader from '../customComponents/DomainHeader';
import SkillHeader from '../customComponents/SkillHeader';

interface ITableColumnConfig {
  title: string;
  field: keyof IQbankTableData;
  className?: string;
  headerComponent?: ICustomHeader<IQbankTableData>;
  component?: ICustomField<IQbankTableData>;
  header?: boolean;
}

const checkedConfig: ITableColumnConfig = {
  title: 'Checked',
  field: 'checked',
  className: 'checked-column',
  headerComponent: CheckedHeader,
  component: TableCheckbox,
};
const idConfig: ITableColumnConfig = {
  title: 'ID #',
  field: 'questionId',
  className: 'id-column',
  component: ViewQuestionButton,
  header: true,
};
const difficultyConfig: ITableColumnConfig = {
  title: 'Difficulty',
  field: 'difficultyTableComponent',
  className: 'difficulty-column',
  headerComponent: DifficultyHeader,
};
const domainConfig: ITableColumnConfig = {
  title: 'Domain',
  field: 'domain',
  headerComponent: DomainHeader,
};
const skillConfig: ITableColumnConfig = {
  title: 'Skill',
  field: 'skill',
  headerComponent: SkillHeader,
};

// exports the below array, containing configurations for each column in the results table
const tableColumnConfig = [checkedConfig, idConfig, difficultyConfig, domainConfig, skillConfig];
export default tableColumnConfig;
