import { useContext } from 'react';
import { searchQueryContext } from '../../../../../../../../contexts/questionbank/searchQuery';
import { qbankQuestionModalContext } from '../../../../../../../../contexts/questionbank/questionModal';

/**
 * Banner component that appears within the QbankQuestionModal component. Contains
 * info about the question, including Assessment, Section, Domain, Skill, and Difficulty.
 */
const Banner: React.FC = () => {
  const { assessment, section } = useContext(searchQueryContext);
  const { selectedRow } = useContext(qbankQuestionModalContext);

  return (
    <div className="question-detail-info">
      <div className="question-banner">
        <div className="col-sm">
          <h6 className="column-header">Assessment</h6>
          <div className="column-content">{assessment!.label}</div>
        </div>
        <div className="col-sm">
          <h6 className="column-header">Test</h6>
          <div className="column-content">{section!.label}</div>
        </div>
        <div className="col-sm">
          <h6 className="column-header">Domain</h6>
          <div className="column-content">{selectedRow!.domain}</div>
        </div>
        <div className="col-sm">
          <h6 className="column-header">Skill</h6>
          <div className="column-content">{selectedRow!.skill}</div>
        </div>
        <div className="col-sm">
          <h6 className="column-header">Difficulty</h6>
          <div className="column-content">{selectedRow!.difficultyComponent}</div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
