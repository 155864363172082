import { IProgramType } from '@cb/athena-widgets-types/dist/props';

/**
 * If logged in with temp creds, renders a custom temp logout widget. Otherwise,
 * render the default login widget.
 */
const GlobalHeader: React.FC = () => {
  return (
    <div id="header">
      <cbw-header
        app-id={process.env.REACT_APP_APP_ID!}
        title={process.env.REACT_APP_TITLE}
        program-type={process.env.REACT_APP_PROGRAM_TYPE! as IProgramType}
        site-title="sat-suite"
        use-icon-logo="true"
        idp="ECL"
        use-search-widget="false"
        use-login-widget="true"
        home-link={process.env.REACT_APP_BASE_URL}
        skip-target-id="main"
      />
    </div>
  );
};

export default GlobalHeader;
