import { ICustomField } from '@cb/apricot-react-table';
import { NakedButton } from '@cb/apricot-react-button';
import { IQuestionTableRow } from '../../types';
import { IQuestion } from '../../../../../../types/questions';

export type QuestionOnClick = (module: number, number: number, question: IQuestion) => void;

/**
 * Component to render review button used in the QuestionsTable component
 */
const ReviewButton =
  (onClick: QuestionOnClick): ICustomField<IQuestionTableRow> =>
  ({ row }) => (
    <NakedButton
      id={`module-${row.module.toString()}-question-${row.number.toString()}-button`}
      onClick={() => {
        onClick(row.module, row.number, row.question);
      }}
    >
      Review
    </NakedButton>
  );

export default ReviewButton;
