import { Link, useLocation, useHistory } from 'react-router-dom';
import { LeftTextBand } from '@cb/apricot-react-band';
import { useEffect, useState } from 'react';
import { useFunctionalContentItems } from '@satui/drupal-profile';
import { Spinner } from '@cb/apricot-react-spinner';
import { HtmlMessage } from '@satreg/drupal';
import { Breadcrumbs, Crumb } from '@cb/apricot-react-breadcrumbs';
import { TAILORED_BANNER } from '../../../../drupal';
import ROUTES from '../../../../../routes';
import './styles/index.scss';

/**
 * Header component appearing on all pages of the Student Question Bank.
 */
const QuestionbankHeader: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [sqbLocation, setSqbLocation] = useState('');

  useEffect(() => {
    if (location.pathname === '/questionbank/tailored') return setSqbLocation('tailored');
    if (location.pathname === '/questionbank/results') return setSqbLocation('results');
    return setSqbLocation('search');
  }, [location.pathname]);

  const { drupalContent } = useFunctionalContentItems(Object.values(TAILORED_BANNER));

  if (!drupalContent) {
    return <Spinner />;
  }

  const { [TAILORED_BANNER.TAILORED_BANNER]: tailoredBannerContent } = drupalContent;

  return (
    <div id="questionbank-header">
      <div className="navbar display-flex justify-content-between">
        <h2>SAT Level Practice</h2>
        <nav className="cb-menu-list cb-menu-list-horizontal">
          <ul>
            <li>
              <Link className="cb-menu-link" to={`${ROUTES.dashboard}`}>
                <span className="cb-glyph cb-home cb-margin-right-8 cb-font-size-regular" />
                <p>Home</p>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      {sqbLocation === 'tailored' ? (
        <div className="cb-band cb-band-hero cb-blue2-bg cb-white-color" data-cbtrack-comp="apricot-vanilla:hero">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-10 col-md-8 offset-md-1">
                <div className="cb-band-hero-content" id="psq-header">
                  <h1 className="cb-band-hero-title">{tailoredBannerContent.extDescription}</h1>
                  <p className="cb-band-hero-desc">
                    <HtmlMessage message={tailoredBannerContent.overview} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LeftTextBand
          title="Student Question Bank"
          description="Choose your own practice questions and target areas to improve."
          className="cb-blue2-bg cb-white-color"
          condensed
        />
      )}
      <div
        className={`sqb-breadcrumbs ${sqbLocation === 'tailored' && 'sqb-breadcrumbs-tailored'} ${sqbLocation === 'results' && 'sqb-breadcrumbs-results'}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              {sqbLocation === 'tailored' ? (
                <Breadcrumbs pageTitle="Practice Specific Questions">
                  <Crumb title="My Tests" onClick={() => history.push(ROUTES.dashboard)} />
                </Breadcrumbs>
              ) : (
                <Breadcrumbs pageTitle="Student Question Bank">
                  <Crumb title="My Tests" onClick={() => history.push(ROUTES.dashboard)} />
                </Breadcrumbs>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionbankHeader;
