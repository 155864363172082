import { DrupalFunctionalContent } from '@satui/drupal-profile';
import { HtmlMessage } from '@satreg/drupal';
import { ExampleEssay, ExamplePrompt } from '../types';
import { RESPONSE_KEYS } from '../../../../../drupal';

const Response: React.FC<{
  prompt: ExamplePrompt;
  essay: ExampleEssay;
  drupalContent: Record<string, DrupalFunctionalContent>;
}> = ({ prompt, essay, drupalContent }) => {
  const {
    [RESPONSE_KEYS.P_1_RESPONSE_1]: p1Response1Content,
    [RESPONSE_KEYS.P_1_RESPONSE_2]: p1Response2Content,
    [RESPONSE_KEYS.P_1_RESPONSE_3]: p1Response3Content,
    [RESPONSE_KEYS.P_1_RESPONSE_4]: p1Response4Content,
    [RESPONSE_KEYS.P_1_RESPONSE_5]: p1Response5Content,
    [RESPONSE_KEYS.P_1_RESPONSE_6]: p1Response6Content,
    [RESPONSE_KEYS.P_1_RESPONSE_7]: p1Response7Content,
    [RESPONSE_KEYS.P_1_RESPONSE_8]: p1Response8Content,
    [RESPONSE_KEYS.P_1_RESPONSE_9]: p1Response9Content,
    [RESPONSE_KEYS.P_2_RESPONSE_1]: p2Response1Content,
    [RESPONSE_KEYS.P_2_RESPONSE_2]: p2Response2Content,
    [RESPONSE_KEYS.P_2_RESPONSE_3]: p2Response3Content,
    [RESPONSE_KEYS.P_2_RESPONSE_4]: p2Response4Content,
    [RESPONSE_KEYS.P_2_RESPONSE_5]: p2Response5Content,
    [RESPONSE_KEYS.P_2_RESPONSE_6]: p2Response6Content,
    [RESPONSE_KEYS.P_2_RESPONSE_7]: p2Response7Content,
    [RESPONSE_KEYS.P_2_RESPONSE_8]: p2Response8Content,
    [RESPONSE_KEYS.P_2_RESPONSE_9]: p2Response9Content,
    [RESPONSE_KEYS.P_3_RESPONSE_1]: p3Response1Content,
    [RESPONSE_KEYS.P_3_RESPONSE_2]: p3Response2Content,
    [RESPONSE_KEYS.P_3_RESPONSE_3]: p3Response3Content,
    [RESPONSE_KEYS.P_3_RESPONSE_4]: p3Response4Content,
    [RESPONSE_KEYS.P_3_RESPONSE_5]: p3Response5Content,
    [RESPONSE_KEYS.P_3_RESPONSE_6]: p3Response6Content,
    [RESPONSE_KEYS.P_3_RESPONSE_7]: p3Response7Content,
    [RESPONSE_KEYS.P_3_RESPONSE_8]: p3Response8Content,
    [RESPONSE_KEYS.P_3_RESPONSE_9]: p3Response9Content,
  } = drupalContent;

  if (prompt === '1') {
    if (essay === '1') return <HtmlMessage message={p1Response1Content.overview} />;
    if (essay === '2') return <HtmlMessage message={p1Response2Content.overview} />;
    if (essay === '3') return <HtmlMessage message={p1Response3Content.overview} />;
    if (essay === '4') return <HtmlMessage message={p1Response4Content.overview} />;
    if (essay === '5') return <HtmlMessage message={p1Response5Content.overview} />;
    if (essay === '6') return <HtmlMessage message={p1Response6Content.overview} />;
    if (essay === '7') return <HtmlMessage message={p1Response7Content.overview} />;
    if (essay === '8') return <HtmlMessage message={p1Response8Content.overview} />;
    if (essay === '9') return <HtmlMessage message={p1Response9Content.overview} />;
  }
  if (prompt === '2') {
    if (essay === '1') return <HtmlMessage message={p2Response1Content.overview} />;
    if (essay === '2') return <HtmlMessage message={p2Response2Content.overview} />;
    if (essay === '3') return <HtmlMessage message={p2Response3Content.overview} />;
    if (essay === '4') return <HtmlMessage message={p2Response4Content.overview} />;
    if (essay === '5') return <HtmlMessage message={p2Response5Content.overview} />;
    if (essay === '6') return <HtmlMessage message={p2Response6Content.overview} />;
    if (essay === '7') return <HtmlMessage message={p2Response7Content.overview} />;
    if (essay === '8') return <HtmlMessage message={p2Response8Content.overview} />;
    if (essay === '9') return <HtmlMessage message={p2Response9Content.overview} />;
  }
  if (prompt === '3') {
    if (essay === '1') return <HtmlMessage message={p3Response1Content.overview} />;
    if (essay === '2') return <HtmlMessage message={p3Response2Content.overview} />;
    if (essay === '3') return <HtmlMessage message={p3Response3Content.overview} />;
    if (essay === '4') return <HtmlMessage message={p3Response4Content.overview} />;
    if (essay === '5') return <HtmlMessage message={p3Response5Content.overview} />;
    if (essay === '6') return <HtmlMessage message={p3Response6Content.overview} />;
    if (essay === '7') return <HtmlMessage message={p3Response7Content.overview} />;
    if (essay === '8') return <HtmlMessage message={p3Response8Content.overview} />;
    if (essay === '9') return <HtmlMessage message={p3Response9Content.overview} />;
  }
  return null;
};

export default Response;
