import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { DrupalFunctionalContent } from '@satui/drupal-profile';
import { useEffect, useState } from 'react';
import { IScoreCardSummary } from '../dashboard/types';
import RobotoFontRegular from './fonts/Roboto-Regular.ttf';
import RobotoFontBlack from './fonts/Roboto-Black.ttf';
import RobotoFontBold from './fonts/Roboto-Bold.ttf';
import CBLogo from './images/cb-sat-suite.png';
import NONPROD_QR from './images/nonprod-qr.png';
import PROD_QR from './images/prod-qr.png';
import COPYWRIGHT from './images/copywright.png';
import PracticeTestGlyph from './images/practice-test-glyph.png';
import { IScoreBand } from '../../../../contexts/questionbank/api/types';
import { getKsdDescription, orderReadingKsd, orderMathKsd } from '../../../ksdDisplay/utils';
import { ksdCodeValuePairs } from '../../../ksdDisplay/constants';
import { KsdCode, ProgramCode } from '../../../ksdDisplay/types';

// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    padding: 40,
    gap: 15,
    fontWeight: 400,
    fontFamily: 'Roboto',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerColumn: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  disclaimer: {
    fontSize: 8,
    maxWidth: 186,
    textAlign: 'right',
  },
  scoreCard: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #D9D9D9',
    borderRadius: 8,
  },
  scoreCardHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    borderBottom: '1px solid #D9D9D9',
  },
  scoreCardContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    height: 171,
  },
  contentSection: {
    flexDirection: 'column',
    paddingRight: 16,
    flexWrap: 'wrap',
  },
  sectionScore: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  dashedLine: {
    borderTop: '1px dotted #D9D9D9',
    marginBottom: 16,
    marginTop: 16,
  },
  regular: {
    fontWeight: 400,
    fontFamily: 'Roboto',
  },
  black: {
    fontWeight: 900,
    fontFamily: 'Roboto',
  },
  bold: {
    fontWeight: 700,
    fontFamily: 'Roboto',
  },
  resourceCard: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #D9D9D9',
    borderRadius: 8,
  },
  resourceText: {
    display: 'flex',
    gap: 16,
    flexDirection: 'row',
    fontSize: 9,
    padding: 8,
  },
  ksdContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    borderTop: '1px solid #D9D9D9',
    padding: 10,
  },
  ksdFlexStart: {
    display: 'flex',
    flexDirection: 'column',
    gap: 7,
  },
  ksdFlexEnd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  barWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
    paddingTop: 10,
    paddingBottom: 10,
  },
  filled: {
    backgroundColor: 'black',
    width: 28,
    height: 8,
  },
  bar: {
    width: 28,
    height: 8,
    border: '1px solid black',
  },
  copywright: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    fontSize: 8,
    bottom: 0,
    left: 0,
    padding: 40,
  },
});

// Create Document Component
const ScoreReportPDF: React.FC<{
  scoreCardSummary: IScoreCardSummary;
  legalContent: DrupalFunctionalContent;
  resourceContent: DrupalFunctionalContent;
  copywrightContent: DrupalFunctionalContent;
}> = ({ scoreCardSummary, legalContent, resourceContent, copywrightContent }) => {
  const {
    date,
    title,
    totalScore,
    totalScoreRange,
    readingScore,
    readingScoreRange,
    mathScore,
    mathScoreRange,
    totalDetails,
    readingDetails,
    mathDetails,
    asmtId,
    firstNm,
    lastNm,
    questionBankData,
    testId,
  } = scoreCardSummary;

  const [readingKsd, setReadingKsd] = useState<IScoreBand[]>();
  const [mathKsd, setMathKsd] = useState<IScoreBand[]>();

  useEffect(() => {
    if (questionBankData) {
      const rawReadingDomains = questionBankData.domainScores.find(
        (section) => section.anchorScoreTableId === 'theta_table_reading',
      )?.scoreBands;
      const rawMathDomains = questionBankData.domainScores.find(
        (section) => section.anchorScoreTableId === 'theta_table_math',
      )?.scoreBands;

      if (rawReadingDomains && rawMathDomains) {
        setReadingKsd(orderReadingKsd(rawReadingDomains!));
        setMathKsd(orderMathKsd(rawMathDomains!));
      }
    }
  }, [questionBankData]);

  Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: RobotoFontRegular,
        fontWeight: 400,
      },
      {
        src: RobotoFontBlack,
        fontWeight: 900,
      },
      {
        src: RobotoFontBold,
        fontWeight: 700,
      },
    ],
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerColumn}>
            <Image src={CBLogo} style={{ width: 115, height: 28 }} />
            <Text style={[styles.black, { fontSize: 22, marginTop: 24 }]}>Your Practice</Text>
            <Text style={[styles.black, { fontSize: 22 }]}>Score Report</Text>
          </View>
          <View style={{ fontSize: 9, alignItems: 'flex-end' }}>
            <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 16 }}>
              <View>
                <Text>Name: </Text>
              </View>
              <View style={{ fontWeight: 700 }}>
                <Text>
                  {firstNm ?? ''} {lastNm ?? ''}
                </Text>
              </View>
            </View>
            <Text style={styles.disclaimer}>{legalContent.extDescription || ''}</Text>
          </View>
        </View>
        <View style={styles.scoreCard}>
          <View style={styles.scoreCardHeader}>
            <Text style={[styles.black, { fontSize: 16 }]}>{title}</Text>
            <Text style={{ fontSize: 12 }}>{date}</Text>
          </View>
          <View style={styles.scoreCardContent}>
            <View style={[styles.contentSection, { width: 127, borderRight: '1px solid #D9D9D9' }]}>
              <View style={{ padding: 8 }}>
                <View>
                  <Text style={[styles.black, { fontSize: 12 }]}>TOTAL SCORE</Text>
                </View>
                <View>
                  <Text style={[styles.bold, { fontSize: 40 }]}>{totalScore}</Text>
                </View>
                <View style={{ fontSize: 8, flexDirection: 'row' }}>
                  <Text style={styles.bold}>Score Range: </Text>
                  <Text>{totalScoreRange}</Text>
                </View>
                <View style={styles.dashedLine} />
                <View style={{ fontSize: 8, fontWeight: 400 }}>
                  <View style={styles.bold}>
                    <Text>Score Range: </Text>
                  </View>
                  <View style={styles.regular}>
                    <Text>
                      This range reflects the minimum and maximum total score you can get with this assessment.
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={[styles.contentSection, { width: 181, borderRight: '1px solid #D9D9D9' }]}>
              <View style={{ padding: 8 }}>
                <Text style={[styles.black, { fontSize: 10 }]}>SECTION SCORES</Text>
                <Text style={[styles.bold, { fontSize: 8, paddingTop: 8 }]}>Reading and Writing</Text>
                <View style={styles.sectionScore}>
                  <Text style={[styles.bold, { fontSize: 32 }]}>{readingScore}</Text>
                  <View
                    style={{ fontSize: 8, flexDirection: 'column', borderLeft: '0.5px solid #888888', paddingLeft: 12 }}
                  >
                    <Text style={styles.bold}>Score Range:</Text>
                    <Text>{readingScoreRange}</Text>
                  </View>
                </View>
                <View style={[styles.dashedLine, { marginTop: 12 }]} />
                <Text style={[styles.bold, { fontSize: 8 }]}>Math</Text>
                <View style={styles.sectionScore}>
                  <Text style={[styles.bold, { fontSize: 32 }]}>{mathScore}</Text>
                  <View
                    style={{ fontSize: 8, flexDirection: 'column', borderLeft: '0.5px solid #888888', paddingLeft: 12 }}
                  >
                    <Text style={styles.bold}>Score Range:</Text>
                    <Text>{mathScoreRange}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={[styles.contentSection, { width: 226, padding: 8 }]}>
              <Text style={[styles.black, { fontSize: 10 }]}>SCORE DETAILS</Text>
              <Text style={[styles.bold, { fontSize: 8, paddingTop: 8 }]}>Questions Overview</Text>
              <View style={[styles.sectionScore, { gap: 8 }]}>
                <Text style={[styles.bold, { fontSize: 32 }]}>{totalDetails.correctAnswers}</Text>
                <View style={{ fontSize: 8, borderLeft: '0.5px solid #888888', paddingLeft: 8 }}>
                  <Text style={styles.bold}>Correct</Text>
                  <Text style={styles.bold}>Answers</Text>
                </View>
                <View style={{ fontSize: 8, borderLeft: '0.5px solid #888888', paddingLeft: 8 }}>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={styles.bold}>Total Questions: </Text>
                    <Text style={styles.regular}>{totalDetails.totalQuestions}</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={styles.bold}>Total Incorrect: </Text>
                    <Text style={styles.regular}>{totalDetails.incorrectAnswers}</Text>
                  </View>
                </View>
              </View>
              <View style={[styles.dashedLine, { marginTop: 4, marginBottom: 12 }]} />
              <View style={{ flexDirection: 'row', gap: 24 }}>
                <View style={{ flexDirection: 'column' }}>
                  <Text style={[styles.bold, { fontSize: 8 }]}>Reading and Writing</Text>
                  <View style={[styles.sectionScore, { marginTop: 6, gap: 8 }]}>
                    <Text style={[styles.bold, { fontSize: 24 }]}>{readingDetails?.correctAnswers}</Text>
                    <View
                      style={{
                        fontSize: 8,
                        flexDirection: 'column',
                        borderLeft: '0.5px solid #888888',
                        paddingLeft: 8,
                      }}
                    >
                      <Text style={styles.bold}>Correct</Text>
                      <Text style={styles.bold}>Answers</Text>
                    </View>
                  </View>
                  <View style={[styles.bold, { fontSize: 8, marginTop: 6 }]}>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <Text style={styles.bold}>Total Questions: </Text>
                      <Text style={styles.regular}>{readingDetails?.totalQuestions}</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <Text style={styles.bold}>Incorrect Answers: </Text>
                      <Text style={styles.regular}>{readingDetails?.incorrectAnswers}</Text>
                    </View>
                  </View>
                </View>
                <View style={{ flexDirection: 'column' }}>
                  <Text style={[styles.bold, { fontSize: 8 }]}>Math</Text>
                  <View style={[styles.sectionScore, { marginTop: 6, gap: 8 }]}>
                    <Text style={[styles.bold, { fontSize: 24 }]}>{mathDetails?.correctAnswers}</Text>
                    <View
                      style={{
                        fontSize: 8,
                        flexDirection: 'column',
                        borderLeft: '0.5px solid #888888',
                        paddingLeft: 8,
                      }}
                    >
                      <Text style={styles.bold}>Correct</Text>
                      <Text style={styles.bold}>Answers</Text>
                    </View>
                  </View>
                  <View style={[styles.bold, { fontSize: 8, marginTop: 6 }]}>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <Text style={styles.bold}>Total Questions: </Text>
                      <Text style={styles.regular}>{mathDetails?.totalQuestions}</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <Text style={styles.bold}>Incorrect Answers: </Text>
                      <Text style={styles.regular}>{mathDetails?.incorrectAnswers}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          {questionBankData && (
            <View style={styles.ksdContent}>
              <View style={styles.ksdFlexStart}>
                <Text style={{ fontSize: 10, fontWeight: 900 }}>KNOWLEDGE AND SKILLS</Text>
                <Text style={{ fontSize: 7, fontWeight: 400 }}>
                  View your performance across the 8 content domains measured on the SAT
                </Text>
              </View>
              <View style={styles.ksdFlexEnd}>
                <View>
                  <Text style={{ fontSize: 9, fontWeight: 700, paddingBottom: 10 }}>Reading and Writing</Text>
                  {readingKsd?.map((domain, i) => (
                    <View key={i}>
                      <Text style={{ fontSize: 8, fontWeight: 700, paddingBottom: 2 }}>
                        {ksdCodeValuePairs[domain.primaryClassCd as KsdCode]}
                      </Text>
                      <Text style={{ fontSize: 8, fontWeight: 400 }}>
                        {getKsdDescription(domain.primaryClassCd as KsdCode, asmtId as ProgramCode, testId)}
                      </Text>
                      <View style={styles.barWrapper}>
                        <View style={parseInt(domain.scoreBandCd, 10) > 0 ? styles.filled : styles.bar} />
                        <View style={parseInt(domain.scoreBandCd, 10) > 1 ? styles.filled : styles.bar} />
                        <View style={parseInt(domain.scoreBandCd, 10) > 2 ? styles.filled : styles.bar} />
                        <View style={parseInt(domain.scoreBandCd, 10) > 3 ? styles.filled : styles.bar} />
                        <View style={parseInt(domain.scoreBandCd, 10) > 4 ? styles.filled : styles.bar} />
                        <View style={parseInt(domain.scoreBandCd, 10) > 5 ? styles.filled : styles.bar} />
                        <View style={parseInt(domain.scoreBandCd, 10) > 6 ? styles.filled : styles.bar} />
                      </View>
                    </View>
                  ))}
                </View>
                <View>
                  <Text style={{ fontSize: 9, fontWeight: 700, paddingBottom: 10 }}>Math</Text>
                  {mathKsd?.map((domain, i) => (
                    <View key={i}>
                      <Text style={{ fontSize: 8, fontWeight: 700, paddingBottom: 2 }}>
                        {ksdCodeValuePairs[domain.primaryClassCd as KsdCode]}
                      </Text>
                      <Text style={{ fontSize: 8, fontWeight: 400 }}>
                        {getKsdDescription(domain.primaryClassCd as KsdCode, asmtId as ProgramCode, testId)}
                      </Text>
                      <View style={styles.barWrapper}>
                        <View style={parseInt(domain.scoreBandCd, 10) > 0 ? styles.filled : styles.bar} />
                        <View style={parseInt(domain.scoreBandCd, 10) > 1 ? styles.filled : styles.bar} />
                        <View style={parseInt(domain.scoreBandCd, 10) > 2 ? styles.filled : styles.bar} />
                        <View style={parseInt(domain.scoreBandCd, 10) > 3 ? styles.filled : styles.bar} />
                        <View style={parseInt(domain.scoreBandCd, 10) > 4 ? styles.filled : styles.bar} />
                        <View style={parseInt(domain.scoreBandCd, 10) > 5 ? styles.filled : styles.bar} />
                        <View style={parseInt(domain.scoreBandCd, 10) > 6 ? styles.filled : styles.bar} />
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          )}
        </View>
        <View style={styles.resourceCard}>
          <View style={styles.resourceText}>
            <View>
              <Image src={PracticeTestGlyph} style={{ width: 32, height: 32 }} />
            </View>
            <View style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              <Text style={{ width: 385 }}>{resourceContent.extDescription || ''}</Text>
              <Text style={styles.bold}>{resourceContent.intDescription || ''}</Text>
            </View>
          </View>
          <View style={{ padding: 8, marginLeft: 16, borderLeft: '1px solid #D9D9D9' }}>
            {process.env.REACT_APP_API_ENV === 'prod' ? (
              <Image src={PROD_QR} style={{ width: 48, height: 48 }} />
            ) : (
              <Image src={NONPROD_QR} style={{ width: 48, height: 48 }} />
            )}
          </View>
        </View>
        <View style={styles.copywright}>
          <Image src={COPYWRIGHT} style={{ width: 10, height: 10 }} />
          <Text style={{ paddingLeft: 2 }}>{copywrightContent.extDescription || ''}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default ScoreReportPDF;
