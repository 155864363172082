const routes = {
  main: '/',
  access: '/access',
  login: '/login',
  nonstudent: '/nonstudent',
  dashboard: '/dashboard',
  details: '/details',
  essay: '/essay',
  questionbank: '/questionbank',
};

export default routes;
