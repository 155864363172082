import { ICustomHeader } from '@cb/apricot-react-table';
import { IQbankTableData } from '../../../../../../../../contexts/questionbank/api/types';
import TablePopoverHeader from './tablePopoverHeader/TablePopoverHeader';
import DifficultyPopoverContentGraphic from './difficulty/DifficultyPopoverContentGraphic';

const DifficultyHeader: ICustomHeader<IQbankTableData> = () => (
  <TablePopoverHeader title="Difficulty" customComponent={<DifficultyPopoverContentGraphic />} />
);

export default DifficultyHeader;
