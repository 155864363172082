import { useContext, useEffect, useState } from 'react';
import {
  SimpleAccordion,
  SimpleAccordionHeader,
  SimpleAccordionItem,
  SimpleAccordionPanel,
} from '@cb/apricot-react-accordion';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner } from '@cb/apricot-react-spinner';
import { scoresContext } from '../../../../../contexts/api/ScoresProvider';
import { IScoreEssayRes, IScoreObject } from '../../../../../types/scores';
import { orderMathKsd, orderReadingKsd } from '../../../../ksdDisplay/utils';
import KsdDisplay from '../../../../ksdDisplay';
import { KsdCode, ProgramCode, Score } from '../../../../ksdDisplay/types';
import './styles/index.scss';

const TestResultsAccordion: React.FC<{ ariaLabeledBy?: string }> = ({ ariaLabeledBy }) => {
  const { scores } = useContext(scoresContext);

  const [hasMore, setHasMore] = useState(true);
  const [records, setRecords] = useState(5);
  const loadMore = () => {
    if (records >= scores.length) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setRecords(records + 5);
      }, 2000);
    }
  };

  useEffect(() => setRecords(5), []);

  return (
    <SimpleAccordion className="test-results-accordion" role="region" aria-labelledby={ariaLabeledBy}>
      <InfiniteScroll pageStart={0} loadMore={loadMore} hasMore={hasMore} loader={<Spinner />}>
        {scores.slice(0, records).map((score, index) => {
          const isScoreEssayRes = (s: IScoreObject): s is IScoreEssayRes => (s as IScoreEssayRes).essay !== undefined;

          if (isScoreEssayRes(score) || !score.questionBankData) return null;
          return (
            <SimpleAccordionItem key={index}>
              <SimpleAccordionHeader>
                {score.displayTitle} -{' '}
                {new Date(score.asmtSubmissionStartTime).toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}
              </SimpleAccordionHeader>
              <SimpleAccordionPanel>
                <div className="display-flex justify-content-between align-items-center cb-margin-bottom-24 flex-wrap">
                  <h3>Knowledge and Skills</h3>
                  <p className="total-score">Total Score: {score.totalScore.score}</p>
                </div>
                <div className="display-flex justify-content-between align-items-center">
                  <h4>Reading and Writing</h4>
                  <p className="section-score">Score: {score.sectionScores[0].score}</p>
                </div>
                <div className="display-flex flex-wrap justify-content-between cb-margin-top-4">
                  {orderReadingKsd(score.questionBankData.domainScores[0].scoreBands).map((scoreBand) => {
                    return (
                      <div className="ksd-container">
                        <KsdDisplay
                          ksdCode={scoreBand.primaryClassCd as KsdCode}
                          programCode={score.asmtId as ProgramCode}
                          description
                          score={parseInt(scoreBand.scoreBandCd, 10) as Score}
                          testId={score.testId}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="display-flex justify-content-between align-items-center cb-margin-top-24">
                  <h4>Math</h4>
                  <p className="section-score">Score: {score.sectionScores[1].score}</p>
                </div>
                <div className="display-flex flex-wrap">
                  {orderMathKsd(score.questionBankData.domainScores[1].scoreBands).map((scoreBand, i) => {
                    let className: string | undefined;

                    if (i === 0) className = 'algebra';
                    if (i === 1) className = 'advanced-math';

                    return (
                      <div className="ksd-container">
                        <KsdDisplay
                          ksdCode={scoreBand.primaryClassCd as KsdCode}
                          programCode={score.asmtId as ProgramCode}
                          description
                          score={parseInt(scoreBand.scoreBandCd, 10) as Score}
                          testId={score.testId}
                          className={className}
                        />
                      </div>
                    );
                  })}
                </div>
              </SimpleAccordionPanel>
            </SimpleAccordionItem>
          );
        })}
      </InfiniteScroll>
    </SimpleAccordion>
  );
};

export default TestResultsAccordion;
