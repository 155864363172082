import { Difficulty } from '../DifficultyIcon';
import './styles/index.scss';

const DifficultyTableIcon: React.FC<{ difficulty: Difficulty }> = ({ difficulty }) => {
  return (
    <div className="difficulty-table-icon">
      <div className="difficulty-indicator filled" />
      <div className={`difficulty-indicator ${difficulty !== 'Easy' && 'filled'}`} />
      <div className={`difficulty-indicator ${difficulty === 'Hard' && 'filled'}`} />
    </div>
  );
};

export default DifficultyTableIcon;
