import { useContext, useEffect } from 'react';
import { webAnalyticsContext } from '@satreg/analytics';
import { BlackButton, YellowButton } from '@cb/apricot-react-button';
import { useHistory } from 'react-router-dom';
import { useFunctionalContentItems } from '@satui/drupal-profile';
import { Spinner } from '@satreg/spinner';
import { HtmlMessage } from '@satreg/drupal';
import DrupalLoginNotification from '../../../drupalNotification/DrupalLoginNotification';
import { LOGIN_KEYS } from '../../../drupal';
import ROUTES from '../../../../routes';
import './styles/index.scss';

/**
 * Component to render the login page for unauthenticated users.
 */
const Login: React.FC = () => {
  const { reportPageData } = useContext(webAnalyticsContext);
  const history = useHistory();

  const returnUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const oktaUrl = `https://account.collegeboard.org/login/login?appId=${process.env.REACT_APP_APP_ID}&DURL=${encodeURI(
    returnUrl,
  )}&idp=ECL`;

  /**
   * Function to navigate to the access page, for temp cred users.
   */
  const navigateToAccess = () => {
    history.push(ROUTES.access);
  };

  const { drupalContent } = useFunctionalContentItems(Object.values(LOGIN_KEYS));

  // set page title, meta data, and report page to WA
  useEffect(() => {
    document.title = 'My Practice Sign In – SAT Suite | College Board';

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content =
      'Sign in to My Practice to view SAT and PSAT/NMSQT practice test results, and review practice exam items, answers, and explanations';
    document.head.appendChild(metaDescription);

    const metaInternalSearchTitle = document.createElement('meta');
    metaInternalSearchTitle.name = 'internal-search-title';
    metaInternalSearchTitle.content = 'My Practice Sign In – SAT Suite';
    document.head.appendChild(metaInternalSearchTitle);

    reportPageData({ flowCode: 'satpractice', pageCode: 'login' });

    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaInternalSearchTitle);
    };
  }, []);

  if (!drupalContent) {
    return <Spinner />;
  }

  return (
    <div id="login">
      <DrupalLoginNotification />
      <div className="login-card">
        <div className="login-content">
          <span className="cb-glyph cb-glyph-avatar cb-acorn cb-blue5-color" />
          <h2>{drupalContent.login.title || ''}</h2>
          <YellowButton onClick={navigateToAccess}>
            <HtmlMessage message={drupalContent['temp-creds-button'].overview || ''} />
          </YellowButton>
          <div className="button-divider">
            <div className="accent-line" />
            <span>OR</span>
            <div className="accent-line" />
          </div>
          <a href={oktaUrl} className="button-link" aria-label="CB Account login">
            <BlackButton className="cb-margin-bottom-32">
              <HtmlMessage message={drupalContent['okta-login-button'].overview || ''} />
            </BlackButton>
          </a>
          <HtmlMessage message={drupalContent['need-help'].overview || ''} />
        </div>
      </div>
    </div>
  );
};

export default Login;
