import { ReactComponent as TopRight } from './expand-collapse-icons/top_r.svg';
import { PaneFocus } from './types';

const TopRightControl: React.FC<{
  paneFocus: PaneFocus;
  setPaneFocus: React.Dispatch<React.SetStateAction<PaneFocus>>;
}> = ({ paneFocus, setPaneFocus }) => {
  return (
    <button type="button" aria-label="Expand left pane" onClick={() => setPaneFocus(paneFocus)}>
      <TopRight />
    </button>
  );
};

export default TopRightControl;
