import { exampleEssaySelectValues } from '../constants';
import { ExampleEssay, ExamplePrompt } from '../types';

export const getEssayNumber = (essayTitle: string) => {
  if (essayTitle.includes('Practice 1')) return '1';
  if (essayTitle.includes('Practice 2')) return '2';
  if (essayTitle.includes('Practice 3')) return '3';
  return undefined;
};

export const getExampleScore = (prompt: ExamplePrompt, example: ExampleEssay) => {
  let exampleScore = '';
  exampleEssaySelectValues[parseInt(prompt, 10) - 1].forEach((value) => {
    if (value.value === example) exampleScore = value.label.slice(-5);
  });
  return exampleScore;
};
