import { IScoreEssayRes, IScoreObject, IStudentInfo } from '../../../../../types/scores';
import { IEssayCardSummary, IHomeScoresData, IScoreCardSummary } from '../types';
import ROUTES from '../../../../../routes';
import essayRoutes from '../../essay/routes';

/**
 * Util function to find the test version based off of the asmtEventId
 */
export const findTestVersion = (asmtEventId: number) => {
  switch (asmtEventId) {
    case 1000001:
    case 1000013:
    case 1000014:
    case 1000015:
      return 'PRACTICE 1';
    case 1000002:
    case 1000016:
    case 1000025:
    case 1000026:
      return 'PRACTICE 2';
    case 1000003:
    case 1000017:
      return 'PRACTICE 3';
    case 1000004:
      return 'PRACTICE 4';
    case 1000018:
      return 'PRACTICE 5';
    case 1000019:
      return 'PRACTICE 6';
    default:
      return 'PRACTICE 1';
  }
};

export const findProgram = (asmtId: number) => {
  switch (asmtId) {
    case 118:
      return 'SAT';
    case 119:
      return 'PSAT/NMSQT and PSAT 10';
    case 120:
      return 'PSAT 8/9';
    default:
      return 'SAT';
  }
};

/**
 * Util function to format data from scores endpoint to be usable for the dashboard page.
 */
const formatScoresData: (scores: IScoreObject[], studentInfo: IStudentInfo) => IHomeScoresData = (
  scores,
  studentInfo,
) => {
  // Create an array for each test type.
  const satScoreCardSummaries: IScoreCardSummary[] = [];
  const psatScoreCardSummaries: IScoreCardSummary[] = [];
  const essayCardSummaries: IEssayCardSummary[] = [];

  const isIScoreEssayRes = (score: IScoreObject): score is IScoreEssayRes =>
    (score as IScoreEssayRes).essay !== undefined;

  // Loop through raw api response
  scores.forEach((score) => {
    // if it's an essay score...
    if (isIScoreEssayRes(score)) {
      const essayCardSummary: IEssayCardSummary = {
        id: score.rosterEntryId,
        rosterEntryId: score.rosterEntryId,
        title: score.asmtEventTitle,
        date: new Date(score.asmtSubmissionStartTime).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        }),
        detailsLink: `${essayRoutes.details}?${score.rosterEntryId}`,
        examplesLink: `${essayRoutes.examples}?${score.rosterEntryId}`,
        program: 'Essay',
        testVersion: findTestVersion(score.asmtEventId),
      };

      essayCardSummaries.push(essayCardSummary);
    }
    // if it's not an essay...
    else {
      // Format api response into IScoreCardSummary, so that data is usable by the dashboard.
      const scoreCardSummary: IScoreCardSummary = {
        id: score.rosterEntryId,
        asmtId: score.asmtId,
        rosterEntryId: score.rosterEntryId,
        testId: score.testId,
        asmtFamilyCd: score.asmtFamilyCd.toString(),
        asmtEventId: score.asmtEventId.toString(),
        asmtSubmissionStartTime: score.asmtSubmissionStartTime,
        date: new Date(score.asmtSubmissionStartTime).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        }),
        title: score.displayTitle || '',
        totalScore: score.totalScore.score,
        totalScoreRange: `${score.totalScore.minScale}-${score.totalScore.maxScale}`,
        detailsLink: `${ROUTES.details}?${score.rosterEntryId}`,
        khanLink: score.khanAcademyLink!,
        questionBankData: score.questionBankData,
        readingScore: undefined,
        readingScoreRange: '',
        mathScore: undefined,
        mathScoreRange: '',
        program: findProgram(score.asmtId),
        testVersion: findTestVersion(score.asmtEventId),
        totalDetails: {
          correctAnswers: score.totalScore.correctAnswers,
          incorrectAnswers: score.totalScore.incorrectAnswers,
          totalQuestions: score.totalScore.totalQuestions,
        },
        readingDetails: undefined,
        mathDetails: undefined,
        firstNm: studentInfo?.firstNm ?? null,
        lastNm: studentInfo?.lastNm ?? null,
      };

      // Append section score info to the scoreCardSummary object
      score.sectionScores.forEach((section) => {
        if (section.tierName === 'Reading and Writing') {
          scoreCardSummary.readingScore = section.score;
          scoreCardSummary.readingScoreRange = `${section.minScale}-${section.maxScale}`;
          scoreCardSummary.readingDetails = {
            correctAnswers: section.correctAnswers,
            incorrectAnswers: section.incorrectAnswers,
            totalQuestions: section.totalQuestions,
          };
        }

        if (section.tierName === 'Math') {
          scoreCardSummary.mathScore = section.score;
          scoreCardSummary.mathScoreRange = `${section.minScale}-${section.maxScale}`;
          scoreCardSummary.mathDetails = {
            correctAnswers: section.correctAnswers,
            incorrectAnswers: section.incorrectAnswers,
            totalQuestions: section.totalQuestions,
          };
        }
      });

      // Push scoreCardSummary into appropriate array, based on asmtId.
      if (score.asmtId === 118) {
        satScoreCardSummaries.push(scoreCardSummary);
      }

      if (score.asmtId === 119 || score.asmtId === 120) {
        psatScoreCardSummaries.push(scoreCardSummary);
      }
    }
  });

  return { satScoreCardSummaries, psatScoreCardSummaries, essayCardSummaries };
};

export default formatScoresData;
