import axios from 'axios';
import { createContext, useState, useMemo } from 'react';

interface ITempKeyperReportingContext {
  reportLogin: (transactionName: string) => void;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
}

const defaultState: ITempKeyperReportingContext = {
  reportLogin: () => {},
  error: '',
  setError: () => {},
};

export const tempKeyperReportingContext = createContext(defaultState);
const { Provider } = tempKeyperReportingContext;

/**
 * Provides Keyper endpoint to report temp creds login attempts to Keyper
 */
const TempKeyperReportingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [error, setError] = useState('');

  /**
   * Function to report temp creds login attempts to Keyper
   * @param transactionName
   */
  const reportLogin = useMemo(() => {
    const report = (transactionName: string) => {
      const keyperUrl = `${process.env.REACT_APP_KEYPER_ENDPOINT!}?transactionName=${transactionName}`;

      axios
        .get(keyperUrl)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    };

    return report;
  }, []);

  return <Provider value={{ reportLogin, error, setError }}>{children}</Provider>;
};

export default TempKeyperReportingProvider;
