import { Redirect, Route, Switch } from 'react-router-dom';
import questionbankRoutes from './routes';
import Search from './search';
import Results from './results';
import QuestionbankHeader from './header';
import SearchQueryProvider from '../../../../contexts/questionbank/searchQuery';
import QbankLiveItemsDataProvider from '../../../../contexts/questionbank/api/QbankLiveItemsDataProvider';
import TailoredReview from './tailoredReview';

/**
 * Routing component containing self-guided questionbank search and results pages
 */
const Questionbank: React.FC = () => {
  return (
    <>
      <QuestionbankHeader />
      <QbankLiveItemsDataProvider>
        <SearchQueryProvider>
          <Switch>
            <Route path={questionbankRoutes.search} component={Search} />
            <Route path={questionbankRoutes.results} component={Results} />
            <Route path={questionbankRoutes.tailored} component={TailoredReview} />
            <Route render={() => <Redirect to={questionbankRoutes.results} />} />
          </Switch>
        </SearchQueryProvider>
      </QbankLiveItemsDataProvider>
    </>
  );
};

export default Questionbank;
