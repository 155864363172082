import { HtmlMessage } from '@satui/html-message';
import { useMathml } from '@satui/test-questions';
import { useEffect, useRef } from 'react';
import { IQbankQuestionData, IQbankTableData } from '../../../../../../../../contexts/questionbank/api/types';
import PrintableBanner from './PrintableBanner';

export interface IQuestionPage {
  withHeaders: boolean;
  withCorrectAnswers: boolean;
  tableData: IQbankTableData;
  questionData: IQbankQuestionData;
}

/**
 * Component to render question data for export to PDF.
 */
const QuestionPage: React.FC<IQuestionPage> = ({ withHeaders, withCorrectAnswers, tableData, questionData }) => {
  const { isReady, resolveMathml } = useMathml();
  const pageRef = useRef<HTMLDivElement>(null);

  // initialize mathml
  useEffect(() => {
    if (isReady && pageRef.current) {
      resolveMathml(pageRef.current).then(() => {
        // resolved all the mathml code
      });
    }
  }, [isReady, pageRef.current]);

  // add mathml alt text to base mathml element
  useEffect(() => {
    const mathjaxContainers = document.querySelectorAll('mjx-container');
    mathjaxContainers.forEach((container) => {
      // get alttext from assistive-ml element
      const mathEl = container.childNodes[1].firstChild as HTMLElement;
      const altText = mathEl.getAttribute('alttext');

      // append alttext to main element
      if (altText) {
        container.setAttribute('role', 'img');
        container.setAttribute('aria-label', altText);
      }
    });
  }, []);

  return (
    <div id={`export-question-${tableData.questionId}`} className="page row cb-white-bg question-info" ref={pageRef}>
      {withHeaders && (
        <>
          <h4 className="question-header cb-roboto">Question ID {tableData.questionId}</h4>
          <PrintableBanner tableData={tableData} />
        </>
      )}
      <div className="question-content cb-margin-bottom-16">
        <p className="question-id">ID: {tableData.questionId}</p>
        <div className="prompt">
          <HtmlMessage message={questionData.prompt} />
        </div>
        <div className="question cb-margin-top-16">
          <HtmlMessage message={questionData.stem} />
        </div>
        <div className="answer-choices cb-margin-top-8">
          <ul>
            {questionData.answerOptions.map((option, index) => {
              return (
                <li key={index}>
                  <HtmlMessage message={option.content} />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {withCorrectAnswers && (
        <div className="answer-content">
          <p className="question-id">ID: {tableData.questionId} Answer</p>
          <div className="correct-answer">
            <p>
              <span>Correct Answer: </span>
              {questionData.correctAnswer.join(', ')}
            </p>
          </div>
          <div className="rationale cb-margin-top-8">
            <p className="cb-margin-bottom-8">
              <span>Rationale</span>
            </p>
            <HtmlMessage message={questionData.rationale} />
          </div>
          <div className="question-difficulty cb-margin-top-16">
            <p>
              <span>Question Difficulty: </span>
              {tableData.difficulty}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionPage;
