import { useContext, useEffect } from 'react';
import { webAnalyticsContext } from '@satreg/analytics';
import './styles/index.scss';

/**
 * Component to render the not student page. This page renders for
 * authenticated, non-student users.
 */
const NotStudent: React.FC = () => {
  const { reportPageData } = useContext(webAnalyticsContext);

  // report page to WA on mount
  useEffect(() => {
    reportPageData({ flowCode: 'satpractice', pageCode: 'not-student' });
  }, []);
  return (
    <div id="not-student">
      <p>This page is only available for students.</p>
    </div>
  );
};

export default NotStudent;
