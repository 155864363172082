import { ICustomHeader } from '@cb/apricot-react-table';
import { IQbankTableData } from '../../../../../../../../contexts/questionbank/api/types';
import TablePopoverHeader from './tablePopoverHeader/TablePopoverHeader';

const DomainHeader: ICustomHeader<IQbankTableData> = () => (
  <TablePopoverHeader
    title="Domain"
    content="The skills and knowledge measured by each test are divided into broad categories called domains."
  />
);

export default DomainHeader;
