import { IFilterCheckbox, IFilterSkillGroup } from '../../../../../../contexts/questionbank/filteredTableData/types';
import { readingWritingCheckboxValues, mathCheckboxValues } from '../../search/constants';

/**
 * Function to create a readable string using the user-selected domain scores.
 */
export const getDomainScoresString = (section: string, domainScores: string[]) => {
  const domainLabelsArr: string[] = [];
  let domainScoresString = '';

  // if section is reading, get the reading label from the readingWritingCheckboxValues array
  if (section === 'reading') {
    readingWritingCheckboxValues.forEach((value) => {
      if (domainScores.includes(value.value as string)) domainLabelsArr.push(value.label);
    });
  }

  // if section is math, get the math label from the mathCheckboxValues array
  if (section === 'math') {
    mathCheckboxValues.forEach((value) => {
      if (domainScores.includes(value.value as string)) domainLabelsArr.push(value.label);
    });
  }

  /**
   * after pushing the domain labels into the domainLabelsArr, use the labels to create a
   * string to display the chosen domains on results page
   */
  domainLabelsArr.forEach((score, index) => {
    if (index !== domainScores.length - 1) {
      domainScoresString = `${domainScoresString}${score}, `;
    } else {
      domainScoresString += score;
    }
  });

  return domainScoresString;
};

export const generateTags: (difficultyFilters: IFilterCheckbox[], skillFilters: IFilterSkillGroup[]) => string[] = (
  difficultyFilters,
  skillFilters,
) => {
  const tagsArr: string[] = [];

  difficultyFilters.forEach((filter) => {
    if (filter.checked) tagsArr.push(filter.value);
  });
  skillFilters.forEach((filter) => {
    filter.checkboxes.forEach((checkbox) => {
      if (checkbox.checked) tagsArr.push(checkbox.value);
    });
  });

  return tagsArr;
};

export const appendTagAriaLabels = (ref: HTMLDivElement) => {
  Array.from(ref.children[0].children).forEach((child) => {
    if (child.role === 'button') {
      child.setAttribute('aria-label', `${child.textContent}-remove`);
    }
  });
};
