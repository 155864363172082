import { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { LinkTagButton, Button } from '@cb/apricot-react-button';
import { DrupalFunctionalContent } from '@satui/drupal-profile';
import Cookies from 'universal-cookie';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Spinner } from '@cb/apricot-react-spinner';
import ROUTES from '../../../../../routes';
import { IQuestionBankData } from '../../../../../types/scores';
import questionbankRoutes from '../../questionbank/routes';
import { ITailoredQuestionParams } from '../../../../../contexts/questionbank/api/types';
import ScoreReportPDF from '../../pdf/pdf';
import { IScoreCardSummary } from '../../dashboard/types';
import { formatFileName } from '../../utils/formatFileName';
import { loggerContext } from '../../../../../contexts/api/MetricLoggingProvider';
import './styles/index.scss';

interface IDetailsTitle {
  header: string;
  title: string;
  subTitle: string;
  asmtId: number;
  asmtFamilyCd: string;
  asmtEventId: string;
  rosterEntryId: string;
  startDate: string;
  asmtSubmissionStartTime: string;
  onReview: () => void;
  khanAcademyLink?: string;
  khanAcademyLinkText?: string;
  questionBankData?: IQuestionBankData;
  pdfScore: IScoreCardSummary;
  legalContent: DrupalFunctionalContent;
  resourceContent: DrupalFunctionalContent;
  copywrightContent: DrupalFunctionalContent;
}

/**
 * Component to render the details page title
 */
const DetailsHeader: React.FC<IDetailsTitle> = ({
  header,
  title,
  subTitle,
  asmtId,
  asmtFamilyCd,
  asmtEventId,
  rosterEntryId,
  startDate,
  asmtSubmissionStartTime,
  onReview,
  khanAcademyLink,
  khanAcademyLinkText,
  questionBankData,
  pdfScore,
  legalContent,
  resourceContent,
  copywrightContent,
}) => {
  const history = useHistory();
  const cookies = new Cookies();
  const [tempLoggedIn, setTempLoggedIn] = useState(false);
  const { logMetrics } = useContext(loggerContext);

  useEffect(() => {
    const jwt = cookies.get('__Host-tempJwtToken') as string | undefined;
    if (jwt) {
      setTempLoggedIn(true);
    } else {
      setTempLoggedIn(false);
    }
  }, [cookies]);

  const onTailoredClick = () => {
    const getAsmtEventId = () => {
      if (asmtId === 118) return 99;
      if (asmtId === 119) return 100;
      if (asmtId === 120) return 102;

      return 0;
    };

    const tailoredQuestionParams: ITailoredQuestionParams = {
      rosterEntryId,
      asmtEventId: getAsmtEventId(),
      domainScores: questionBankData!.domainScores,
      external_ids: questionBankData!.external_ids,
    };

    history.push(questionbankRoutes.tailored, { title, date: startDate, tailoredQuestionParams });
  };

  const reportClickEvent = (eventName: string) => {
    logMetrics(rosterEntryId, asmtFamilyCd, asmtEventId, asmtId.toString(), eventName);
  };

  return (
    <div id="details-header">
      <div className="navbar display-flex justify-content-between">
        <h2>SAT Level Practice</h2>
        <nav className="cb-menu-list cb-menu-list-horizontal">
          <ul>
            <li>
              <Link className="cb-menu-link" to={`${ROUTES.dashboard}`}>
                <span className="cb-glyph cb-home cb-margin-right-8 cb-font-size-regular" />
                <p>Home</p>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="banner container-fluid">
        <div className="row">
          <div className="offset-xs-1" />
          <div className="col-xs-11">
            <h1 className="cb-margin-bottom-16">{header}</h1>
            <h2 className="cb-margin-bottom-24">{subTitle}</h2>
            <div className="details-button-row display-flex flex-wrap">
              <Button className="review-button" small light onClick={onReview}>
                Review All Questions
              </Button>
              {questionBankData && (
                <Button small light className="tailored-questions-button" onClick={onTailoredClick}>
                  Practice Specific Questions
                </Button>
              )}
              {!tempLoggedIn && (
                <PDFDownloadLink
                  document={
                    <ScoreReportPDF
                      scoreCardSummary={pdfScore!}
                      legalContent={legalContent}
                      resourceContent={resourceContent}
                      copywrightContent={copywrightContent}
                    />
                  }
                  fileName={formatFileName(title, asmtSubmissionStartTime, pdfScore)}
                >
                  {({ loading }) => (
                    <Button
                      small
                      light
                      className="tailored-questions-button"
                      onClick={() => reportClickEvent('pdf_download')}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner size="16" light />
                      ) : (
                        <>
                          <span className="cb-glyph cb-download download-icon" />
                          <span className="cb-padding-left-8">Download Score Report</span>
                        </>
                      )}
                    </Button>
                  )}
                </PDFDownloadLink>
              )}
              {khanAcademyLink && khanAcademyLinkText && asmtId !== 120 && (
                <LinkTagButton
                  offScreen={khanAcademyLinkText}
                  href={khanAcademyLink}
                  className="khan-link-button"
                  target="_blank"
                  small
                  onClick={() => reportClickEvent('khan_view')}
                >
                  {khanAcademyLinkText}
                </LinkTagButton>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsHeader;
