import { useContext, useEffect, useState } from 'react';
import { ICustomField } from '@cb/apricot-react-table';
import { Checkbox } from '@cb/apricot-react-forms';
import { IQbankTableData } from '../../../../../../../../contexts/questionbank/api/types';
import { filteredTableDataContext } from '../../../../../../../../contexts/questionbank/filteredTableData';
import { exportQuestionSetContext } from '../../../../../../../../contexts/questionbank/exportQuestionSet';

const TableCheckbox: ICustomField<IQbankTableData> = ({ row, rowIndex }) => {
  const { addQuestion, removeQuestion, dataLoaded } = useContext(exportQuestionSetContext);
  const { markCheckedState, currentPage } = useContext(filteredTableDataContext);
  const [questionIndex, setQuestionIndex] = useState(rowIndex + (currentPage - 1) * 10);

  const onChange = (checked: boolean) => {
    if (!dataLoaded) {
      if (checked) {
        addQuestion(row);
      } else {
        removeQuestion(row.questionId);
      }

      markCheckedState(questionIndex, checked);
    }
  };

  useEffect(() => setQuestionIndex(rowIndex + (currentPage - 1) * 10), [currentPage]);

  return <Checkbox aria-labelledby={row.questionId} checked={row.checked} onChange={onChange} />;
};

export default TableCheckbox;
