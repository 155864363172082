import { useHistory } from 'react-router-dom';
import { NakedButton } from '@cb/apricot-react-button';
import { Illustration } from '@cb/apricot-react-illustration';
import { IScoreEssayRes } from '../../../../../../types/scores';
import { PaneFocus } from '../../expandCollapse/types';
import ScoringRubricGuide from '../../scoringRubricGuide';
import essayRoutes from '../../routes';
import TopLeftControl from '../../expandCollapse/topLeftControl';
import BottomRightControl from '../../expandCollapse/bottomRightControl';

const SidebarPane: React.FC<{
  paneFocus: PaneFocus;
  setPaneFocus: React.Dispatch<React.SetStateAction<PaneFocus>>;
  score: IScoreEssayRes;
}> = ({ paneFocus, setPaneFocus, score }) => {
  const history = useHistory();

  const getRightSizeControl = () => {
    switch (paneFocus) {
      case 'focusLeft':
        return <TopLeftControl paneFocus="equalFocus" setPaneFocus={setPaneFocus} />;
      case 'equalFocus':
        return <TopLeftControl paneFocus="focusRight" setPaneFocus={setPaneFocus} />;
      case 'focusRight':
        return <BottomRightControl paneFocus="equalFocus" setPaneFocus={setPaneFocus} />;
      default:
        return null;
    }
  };

  const getRightSize = () => {
    switch (paneFocus) {
      case 'focusLeft':
        return '5';
      case 'equalFocus':
        return '6';
      case 'focusRight':
        return '7';
      default:
        return '';
    }
  };

  return (
    <section
      className={`example-sidebar-pane col-xs-12 col-sm-${parseInt(getRightSize(), 10) + 1} col-md-${getRightSize()}`}
    >
      <div className="expand-collapse">{getRightSizeControl()}</div>
      <div className="sidebar-content">
        <div className="view-essay-card">
          <div className="illustration">
            <Illustration name="checklist-doc" circular shadow />
          </div>
          <div className="body">
            <h3>View my Practice Essay</h3>
            <p className="cb-margin-bottom-8">Use the rubric to score your essay.</p>
          </div>
          <NakedButton onClick={() => history.push(`${essayRoutes.details}?${score.rosterEntryId}`)}>
            <span className="cb-glyph cb-right" aria-hidden="false">
              <span className="sr-only">View my Practice Essay link</span>
            </span>
          </NakedButton>
        </div>
        <ScoringRubricGuide />
      </div>
    </section>
  );
};

export default SidebarPane;
