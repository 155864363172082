type DrupalKeys = Record<string, string>;

export const LOGIN_KEYS: DrupalKeys = {
  LOGIN: 'login',
  TEMP_CREDS_BUTTON: 'temp-creds-button',
  OKTA_LOGIN_BUTTON: 'okta-login-button',
  NEED_HELP: 'need-help',
};

export const TEMP_LOGIN_KEYS: DrupalKeys = {
  TEMP_LOGIN_HEADER: 'temp-login-header',
  FORGOT_TEMP_CREDS: 'forgot-temp-creds',
};

export const NOTIFICATION_KEYS: DrupalKeys = {
  NOTIFICATION: 'drupal-notification',
  LOGIN_NOTIFICATION: 'drupal-login-notification',
  TEMP_CREDS_NOTIFICATION: 'temp-creds-notification',
};

export const KSD_KEYS: DrupalKeys = {
  EASY_TOOLTIP: 'easy-tooltip',
  MEDIUM_TOOLTIP: 'medium-tooltip',
  HARD_TOOLTIP: 'hard-tooltip',
  EASY_TOOLTIP_PSQ: 'easy-tooltip-psq',
  MEDIUM_TOOLTIP_PSQ: 'medium-tooltip-psq',
  HARD_TOOLTIP_PSQ: 'hard-tooltip-psq',
};

export const TAILORED_BANNER: DrupalKeys = {
  TAILORED_BANNER: 'tailored-banner',
};

export const DASHBOARD_KEYS: DrupalKeys = {
  NO_SCORES: 'no-scores-content',
  DASHBOARD_HEADER: 'dashboard-header',
};

export const CAROUSEL_HEADER_KEYS: DrupalKeys = {
  SAT_HEADER: 'sat-header',
  PSAT_HEADER: 'psat-header',
  PSAT_89_HEADER: 'psat-89-header',
  ESSAY_HEADER: 'essay-header',
};

export const CAROUSEL_BUTTON_KEYS: DrupalKeys = {
  KHAN_LINK: 'khan-link',
  REVIEW_TEST: 'review-test-button',
};

export const TESTING_RULES_KEYS: DrupalKeys = {
  TESTING_RULES_CONTENT: 'testing-rules-content',
};

export const MORE_RESOURCES_KEYS: DrupalKeys = {
  HEADER: 'more-resources-header',
  REGISTER_SAT: 'more-resources-register-sat',
  STUDENT_QUESTION_BANK: 'more-resources-student-question-bank',
  DIGITAL_SAT: 'more-resources-digital-sat',
  BLUEBOOK: 'more-resources-bluebook',
};

export const DETAILS_KEYS: DrupalKeys = {
  HEADER: 'details-header',
  KHAN_LINK: 'khan-link',
  QUESTIONS: 'questions-overview',
  KSD_POPOVER: 'ksd-popover-content',
};

export const PDF_KEYS: DrupalKeys = {
  PDF_LEGAL: 'pdf-legal',
  PDF_RESOURCE: 'pdf-resource',
  PDF_COPYWRIGHT: 'pdf-copywright',
};

export const SQB_SEARCH_KEYS = {
  SQB_DOMAINS_DESCRIPTION: 'sqb-domains-description',
};

export const RUBRIC_KEYS: DrupalKeys = {
  READING_4: 'reading-4',
  READING_3: 'reading-3',
  READING_2: 'reading-2',
  READING_1: 'reading-1',
  ANALYSIS_4: 'analysis-4',
  ANALYSIS_3: 'analysis-3',
  ANALYSIS_2: 'analysis-2',
  ANALYSIS_1: 'analysis-1',
  WRITING_4: 'writing-4',
  WRITING_3: 'writing-3',
  WRITING_2: 'writing-2',
  WRITING_1: 'writing-1',
};

export const PROMPT_KEYS: DrupalKeys = {
  PROMPT_1: 'prompt-1',
  PROMPT_2: 'prompt-2',
  PROMPT_3: 'prompt-3',
};

export const RESPONSE_KEYS: DrupalKeys = {
  P_1_RESPONSE_1: 'p-1-response-1',
  P_1_RESPONSE_2: 'p-1-response-2',
  P_1_RESPONSE_3: 'p-1-response-3',
  P_1_RESPONSE_4: 'p-1-response-4',
  P_1_RESPONSE_5: 'p-1-response-5',
  P_1_RESPONSE_6: 'p-1-response-6',
  P_1_RESPONSE_7: 'p-1-response-7',
  P_1_RESPONSE_8: 'p-1-response-8',
  P_1_RESPONSE_9: 'p-1-response-9',
  P_2_RESPONSE_1: 'p-2-response-1',
  P_2_RESPONSE_2: 'p-2-response-2',
  P_2_RESPONSE_3: 'p-2-response-3',
  P_2_RESPONSE_4: 'p-2-response-4',
  P_2_RESPONSE_5: 'p-2-response-5',
  P_2_RESPONSE_6: 'p-2-response-6',
  P_2_RESPONSE_7: 'p-2-response-7',
  P_2_RESPONSE_8: 'p-2-response-8',
  P_2_RESPONSE_9: 'p-2-response-9',
  P_3_RESPONSE_1: 'p-3-response-1',
  P_3_RESPONSE_2: 'p-3-response-2',
  P_3_RESPONSE_3: 'p-3-response-3',
  P_3_RESPONSE_4: 'p-3-response-4',
  P_3_RESPONSE_5: 'p-3-response-5',
  P_3_RESPONSE_6: 'p-3-response-6',
  P_3_RESPONSE_7: 'p-3-response-7',
  P_3_RESPONSE_8: 'p-3-response-8',
  P_3_RESPONSE_9: 'p-3-response-9',
};

export const EXPLANATION_KEYS: DrupalKeys = {
  P_1_EXPLANATION_1: 'p-1-explanation-1',
  P_1_EXPLANATION_2: 'p-1-explanation-2',
  P_1_EXPLANATION_3: 'p-1-explanation-3',
  P_1_EXPLANATION_4: 'p-1-explanation-4',
  P_1_EXPLANATION_5: 'p-1-explanation-5',
  P_1_EXPLANATION_6: 'p-1-explanation-6',
  P_1_EXPLANATION_7: 'p-1-explanation-7',
  P_1_EXPLANATION_8: 'p-1-explanation-8',
  P_1_EXPLANATION_9: 'p-1-explanation-9',
  P_2_EXPLANATION_1: 'p-2-explanation-1',
  P_2_EXPLANATION_2: 'p-2-explanation-2',
  P_2_EXPLANATION_3: 'p-2-explanation-3',
  P_2_EXPLANATION_4: 'p-2-explanation-4',
  P_2_EXPLANATION_5: 'p-2-explanation-5',
  P_2_EXPLANATION_6: 'p-2-explanation-6',
  P_2_EXPLANATION_7: 'p-2-explanation-7',
  P_2_EXPLANATION_8: 'p-2-explanation-8',
  P_2_EXPLANATION_9: 'p-2-explanation-9',
  P_3_EXPLANATION_1: 'p-3-explanation-1',
  P_3_EXPLANATION_2: 'p-3-explanation-2',
  P_3_EXPLANATION_3: 'p-3-explanation-3',
  P_3_EXPLANATION_4: 'p-3-explanation-4',
  P_3_EXPLANATION_5: 'p-3-explanation-5',
  P_3_EXPLANATION_6: 'p-3-explanation-6',
  P_3_EXPLANATION_7: 'p-3-explanation-7',
  P_3_EXPLANATION_8: 'p-3-explanation-8',
  P_3_EXPLANATION_9: 'p-3-explanation-9',
};
