/**
 * Web Analytics Events
 */
export const WA_EVENTS = {
  START_REVIEW: 'cbTrack-testDetails',
  TRACK_PRACTICE: 'cbTrack-satPracEvent',
};

/**
 * Web Analytics Action Sources
 */
export const WA_ACTION_SOURCES = {
  DETAILS: 'details',
  QUESTIONS: 'questions',
};

/**
 * Web Analytics Action Types
 */
export const WA_ACTION_TYPES = {
  START: 'start',
  FIRST_QUESTION: 'first-question-view',
  FINAL_QUESTION: 'final-question-view',
};
