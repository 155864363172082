import { useContext } from 'react';
import { Checkbox } from '@cb/apricot-react-forms';
import { NakedButton, PrimaryButton } from '@cb/apricot-react-button';
import { filteredTableDataContext } from '../../../../../../../contexts/questionbank/filteredTableData';
import { IFilterCheckbox } from '../../../../../../../contexts/questionbank/filteredTableData/types';

interface IDifficultyCheckboxGroup {
  currentSelectedDifficulties: IFilterCheckbox[];
  setCurrentSelectedDifficulties: React.Dispatch<React.SetStateAction<IFilterCheckbox[]>>;
}

const DifficultyCheckboxGroup: React.FC<IDifficultyCheckboxGroup> = ({
  currentSelectedDifficulties,
  setCurrentSelectedDifficulties,
}) => {
  const { setDifficultyFilters } = useContext(filteredTableDataContext);

  const onChange = (checked: boolean, event: InputEvent) => {
    const inputEl = event.target as HTMLInputElement;
    const filter: IFilterCheckbox = { value: inputEl.defaultValue, checked };

    setCurrentSelectedDifficulties(
      currentSelectedDifficulties.map((currentFilter) => {
        if (currentFilter.value === filter.value) return filter;
        return currentFilter;
      }),
    );
  };

  const onClear = () => {
    setCurrentSelectedDifficulties(currentSelectedDifficulties.map((filter) => ({ ...filter, checked: false })));
  };

  const onSave = () => {
    setDifficultyFilters(currentSelectedDifficulties);
  };

  return (
    <>
      <p>You can refer to your practice test results to see what levels you can improve on.</p>
      <div className="filter-checkbox-group cb-margin-top-16">
        {currentSelectedDifficulties.map((filter, index) => {
          return (
            <Checkbox
              key={index}
              className="cb-margin-bottom-16"
              label={filter.value}
              value={filter.value}
              checked={filter.checked}
              onChange={onChange}
            />
          );
        })}
      </div>
      <footer className="display-flex justify-content-between cb-padding-top-16 cb-margin-top-32">
        <NakedButton onClick={onClear}>Clear</NakedButton>
        <PrimaryButton data-cb-popover-close onClick={onSave}>
          Save
        </PrimaryButton>
      </footer>
    </>
  );
};
export default DifficultyCheckboxGroup;
