import { Spinner } from '@cb/apricot-react-spinner';
import { useFunctionalContentItems } from '@satui/drupal-profile';
import { MORE_RESOURCES_KEYS } from '../../../../drupal';
import MoreResources from './MoreResources';

const MoreResourcesContainer: React.FC = () => {
  const { drupalContent } = useFunctionalContentItems(Object.values(MORE_RESOURCES_KEYS));

  if (!drupalContent) {
    return <Spinner />;
  }

  const {
    [MORE_RESOURCES_KEYS.HEADER]: headerContent,
    [MORE_RESOURCES_KEYS.REGISTER_SAT]: registerSatContent,
    [MORE_RESOURCES_KEYS.STUDENT_QUESTION_BANK]: studentQuestionBankContent,
    [MORE_RESOURCES_KEYS.DIGITAL_SAT]: digitalSatContent,
    [MORE_RESOURCES_KEYS.BLUEBOOK]: bluebookContent,
  } = drupalContent;

  return (
    <div className="more-resources">
      <h2 className="cb-font-weight-bold cb-margin-top-72 cb-margin-xs-top-48">{headerContent.title || ''}</h2>
      <div className="row">
        <MoreResources
          registerSatContent={registerSatContent}
          studentQuestionBankContent={studentQuestionBankContent}
          digitalSatContent={digitalSatContent}
          bluebookContent={bluebookContent}
        />
      </div>
    </div>
  );
};

export default MoreResourcesContainer;
