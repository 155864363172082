import { createContext, useMemo, useState } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { getCatapultHeaders, getTempHeaders } from './utils';

const defaultState: {
  logMetrics: (
    rosterEntryId: string,
    asmtFamilyCd: string,
    asmtEventId: string,
    asmtId: string,
    eventType: string,
  ) => void;
  isLoaded: boolean;
  error: boolean;
} = { logMetrics: () => {}, isLoaded: false, error: false };

export const loggerContext = createContext(defaultState);
const { Provider } = loggerContext;

const MetricLoggingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  const cookies = new Cookies();

  const onSuccess = () => {
    setIsLoaded(true);
  };

  /**
   * If request fails, set error and isLoaded to true.
   */
  const onFailure = () => {
    setError(true);
    setIsLoaded(true);
  };

  /**
   * Call the log metrics microservice
   */
  const logMetrics = useMemo(() => {
    const logUserMetrics = (
      rosterEntryId: string,
      asmtFamilyCd: string,
      asmtEventId: string,
      asmtId: string,
      eventType: string,
    ) => {
      const tempCookie = cookies.get('__Host-tempJwtToken');
      let headers;
      let channelSource;

      if (tempCookie) {
        headers = getTempHeaders();
        channelSource = 'I';
      } else {
        headers = getCatapultHeaders();
        channelSource = 'C';
      }

      axios
        .post(
          process.env.REACT_APP_METRIC_LOGGING_ENDPOINT!,
          { rosterEntryId, asmtFamilyCd, asmtEventId, asmtId, eventType, channelSource },
          { headers },
        )
        .then(() => {
          onSuccess();
        })
        .catch(() => {
          onFailure();
        });
    };
    return logUserMetrics;
  }, []);

  return <Provider value={{ logMetrics, isLoaded, error }}>{children}</Provider>;
};

export default MetricLoggingProvider;
