import { createContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { getCatapultHeaders, getTempHeaders } from '../../api/utils';
import { IRawQbankTableRes, IQbankTableData } from './types';
import { getDifficultyString } from './utils';
import DifficultyIcon from '../../../components/pages/main/questionbank/results/questionbankTable/columns/customComponents/difficulty/DifficultyIcon';
import DifficultyTableIcon from '../../../components/pages/main/questionbank/results/questionbankTable/columns/customComponents/difficulty/difficultyTableIcon';

interface IQbankTableDataContext {
  getQbankTableData: (params: { asmtEventId: number; test: string; domain: string }) => void;
  qbankTableData: IQbankTableData[];
  isLoaded: boolean;
  error: boolean;
}

const defaultState: IQbankTableDataContext = {
  getQbankTableData: () => {},
  qbankTableData: [],
  isLoaded: false,
  error: false,
};

export const qbankTableDataContext = createContext<IQbankTableDataContext>(defaultState);
const { Provider } = qbankTableDataContext;

/**
 * Provides a function for getting questionbank results table metadata from the API, and provides the response.
 */
const QbankTableDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [qbankTableData, setQbankTableData] = useState<IQbankTableData[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  const cookies = new Cookies();

  /**
   * Calls the get-questions api and sets the response to state
   */
  const getQbankTableData = useMemo(() => {
    const getData = (params: { asmtEventId: number; test: string; domain: string }) => {
      setQbankTableData([]);
      setIsLoaded(false);
      setError(false);

      const tempCookie = cookies.get('__Host-tempJwtToken');
      const headers = tempCookie ? getTempHeaders() : getCatapultHeaders();

      axios
        .post(process.env.REACT_APP_SQB_GET_QUESTIONS_ENDPOINT!, params, { headers })
        .then(({ data }: { data: IRawQbankTableRes[] }) => {
          const formattedResults = data.map((result) => {
            const formattedResult: IQbankTableData = {
              questionId: result.questionId,
              externalId: result.external_id,
              difficulty: getDifficultyString(result.difficulty),
              difficultyComponent: <DifficultyIcon difficulty={getDifficultyString(result.difficulty)} />,
              difficultyTableComponent: <DifficultyTableIcon difficulty={getDifficultyString(result.difficulty)} />,
              domain: result.primary_class_cd_desc,
              skill: result.skill_desc,
              checked: false,
            };

            return formattedResult;
          });

          setQbankTableData(formattedResults);
          setIsLoaded(true);
        })
        .catch(() => {
          setError(true);
          setIsLoaded(true);
        });
    };

    return getData;
  }, []);

  useEffect(() => {
    if (error) throw new Error('Questionbank table service unavailable');
  }, [error]);

  return <Provider value={{ getQbankTableData, qbankTableData, isLoaded, error }}>{children}</Provider>;
};

export default QbankTableDataProvider;
