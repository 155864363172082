/**
 * Function to compare temp creds username entry on temp cred login page
 * to a regex used to identify email addresses. If username looks like an email,
 * this returns true. Otherwise it returns false.
 * @param string
 * @returns boolean
 */
export const checkIfEmail = (string: string) => {
  const emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  if (string.match(emailFormat)) return true;
  return false;
};
