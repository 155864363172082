import { InfoNotification } from '@cb/apricot-react-notification';
import Cookies from 'universal-cookie';
import { useState } from 'react';
import { useFunctionalContentItem } from '@satui/drupal-profile';
import { Spinner } from '@cb/apricot-react-spinner';
import { HtmlMessage } from '@satreg/drupal';
import { NOTIFICATION_KEYS } from '../../../drupal';
import './styles/index.scss';

/**
 * Component to render a drupal notification for authenticated, temp-cred users.
 * Content of the notification is controlled via Drupal.
 */
const TempCredsNotification: React.FC = () => {
  const [showNotification, setShowNotification] = useState(true);
  const cookies = new Cookies();

  const { content } = useFunctionalContentItem(NOTIFICATION_KEYS.TEMP_CREDS_NOTIFICATION);

  // If this is not a temp-cred user, don't render the notification.
  if (!cookies.get('__Host-tempJwtToken') || !showNotification) {
    return null;
  }

  if (!content) return <Spinner />;

  return (
    <div id="cb-account-notification">
      <InfoNotification
        title="Want to Keep Access to These Scores?"
        dismissible
        onDismiss={() => setShowNotification(false)}
      >
        <HtmlMessage message={content.extDescription || ''} />
      </InfoNotification>
    </div>
  );
};

export default TempCredsNotification;
