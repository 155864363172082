import {
  IAnswerOption,
  IQbankQuestionData,
  IQbankTailoredQuestionData,
  IRawQbankQuestionRes,
  IRawQbankTailoredQuestionRes,
} from '../types';

export const getDifficultyString = (difficultyLetter: string) => {
  switch (difficultyLetter) {
    case 'E':
      return 'Easy';
    case 'M':
      return 'Medium';
    case 'H':
      return 'Hard';
    default:
      return 'Easy';
  }
};

/**
 * Formats the API response so that the data is directly usable by the question modal and PDF question page.
 * @param data
 * @returns IQuestionData
 */
export const formatQuestionData: (data: IRawQbankQuestionRes) => IQbankQuestionData = (data) => {
  const formattedAnswerOptions: IAnswerOption[] = data.answerOptions.map((option, index) => {
    return {
      letter: String.fromCharCode(index + 65),
      content: option.content.replace('<mfenced>', '<mn>(</mn>').replace('</mfenced>', '<mn>)</mn>'),
    };
  });

  return {
    externalId: data.externalid,
    rationale: data.rationale,
    stem: data.stem,
    prompt: data.stimulus,
    type: data.type,
    answerOptions: formattedAnswerOptions,
    correctAnswer: data.correct_answer,
  };
};

export const formatTailoredQuestionData: (data: IRawQbankTailoredQuestionRes) => IQbankTailoredQuestionData = (
  data,
) => {
  const formattedAnswerOptions: IAnswerOption[] = data.answerOptions.map((option, index) => {
    return {
      letter: String.fromCharCode(index + 65),
      content: option.content.replace('<mfenced>', '<mn>(</mn>').replace('</mfenced>', '<mn>)</mn>'),
    };
  });

  return {
    section: data.section,
    externalId: data.externalid,
    rationale: data.rationale,
    stem: data.stem,
    stimulus: data.stimulus,
    prompt: data.stimulus,
    type: data.type,
    answerOptions: formattedAnswerOptions,
    correctAnswer: data.correct_answer,
    primary_class_cd: data.primary_class_cd,
  };
};
