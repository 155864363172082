import { useContext, useEffect, useState } from 'react';
import { Modal } from '@cb/apricot-react-modal';
import { Spinner } from '@cb/apricot-react-spinner';
import { qbankQuestionModalContext } from '../../../../../../../contexts/questionbank/questionModal';
import { qbankQuestionDataContext } from '../../../../../../../contexts/questionbank/api/QbankQuestionDataProvider';
import ModalFooter from './footer';
import Banner from './banner';
import './styles/index.scss';
import QbankQuestionCard from './questionCard';
import { MathmlProvider } from '@satui/test-questions';

const QbankQuestionModal: React.FC = () => {
  const { isModalOpen, closeModal, selectedRow } = useContext(qbankQuestionModalContext);
  const { qbankQuestionLoaded, clearQbankQuestionData } = useContext(qbankQuestionDataContext);
  const [showContent, setShowContent] = useState(false);

  /**
   * On close callback function for the question modal. On close,
   * clear the data from the modal and close it.
   */
  const onCloseModal = () => {
    clearQbankQuestionData();
    closeModal();
  };

  // don't set the show content flag to true until data is loaded.
  useEffect(() => {
    if (qbankQuestionLoaded) setShowContent(true);
  }, [qbankQuestionLoaded]);

  return (
    <Modal
      modalId="qbank-question-modal"
      title={`Question ID ${selectedRow?.questionId}`}
      footer={<ModalFooter onCloseModal={onCloseModal} />}
      open={isModalOpen}
      onClose={onCloseModal}
    >
      {showContent ? (
        <div>
          <Banner />
          <MathmlProvider>
            <QbankQuestionCard />
          </MathmlProvider>
        </div>
      ) : (
        <Spinner />
      )}
    </Modal>
  );
};

export default QbankQuestionModal;
