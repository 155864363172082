import { useState } from 'react';
import { Glyph } from '@cb/apricot-react-glyph';
import { Tab } from '..';

interface IRubricRadioGroup {
  tab: Tab;
  setTab: React.Dispatch<React.SetStateAction<Tab>>;
}

const RubricRadioGroup: React.FC<IRubricRadioGroup> = ({ tab, setTab }) => {
  const [active, setActive] = useState(false);

  const onFocus = () => {
    if (!active) setActive(true);
  };

  const onChange = (e: React.FormEvent<HTMLFieldSetElement>) => {
    const fieldsetEl = e.target as HTMLFieldSetElement;
    switch (fieldsetEl.id) {
      case 'radio-reading':
        setTab('Reading');
        break;
      case 'radio-analysis':
        setTab('Analysis');
        break;
      case 'radio-writing':
        setTab('Writing');
        break;
      default:
        setTab(tab);
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLFieldSetElement, Element>) => {
    const relatedTargetEl = e.relatedTarget as HTMLInputElement;
    if (!relatedTargetEl?.type || relatedTargetEl.type !== 'radio') setActive(false);
  };

  return (
    <fieldset className={active ? 'active' : ''} onFocus={onFocus} onChange={onChange} onBlur={onBlur}>
      <label className={`radio-card ${tab === 'Reading' ? 'selected' : ''}`} htmlFor="radio-reading">
        <input type="radio" name="rubric-sections" id="radio-reading" />
        {tab === 'Reading' && <Glyph name="check" className="tab-check" />}
        <p>Reading</p>
      </label>
      <label className={`radio-card ${tab === 'Analysis' ? 'selected' : ''}`} htmlFor="radio-analysis">
        <input type="radio" name="rubric-sections" id="radio-analysis" />
        {tab === 'Analysis' && <Glyph name="check" className="tab-check" />}
        <p>Analysis</p>
      </label>
      <label className={`radio-card ${tab === 'Writing' ? 'selected' : ''}`} htmlFor="radio-writing">
        <input type="radio" name="rubric-sections" id="radio-writing" />
        {tab === 'Writing' && <Glyph name="check" className="tab-check" />}
        <p>Writing</p>
      </label>
    </fieldset>
  );
};

export default RubricRadioGroup;
