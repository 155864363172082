import { IScoreAssessmentRes, IScore, IStudentInfo } from '../../../../../types/scores';
import { IScoreCardSummary } from '../../dashboard/types';
import { findProgram, findTestVersion } from '../../dashboard/utils/formatScoresData';
import ROUTES from '../../../../../routes';

const formatPdfScore = (score: IScoreAssessmentRes, studentInfo: IStudentInfo) => {
  const scoreCardSummary: IScoreCardSummary = {
    id: score.rosterEntryId,
    asmtId: score.asmtId,
    rosterEntryId: score.rosterEntryId,
    testId: score.testId,
    asmtFamilyCd: score.asmtFamilyCd.toString(),
    asmtEventId: score.asmtEventId.toString(),
    asmtSubmissionStartTime: score.asmtSubmissionStartTime,
    date: new Date(score.asmtSubmissionStartTime).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }),
    title: score.displayTitle || '',
    totalScore: score.totalScore.score,
    totalScoreRange: `${score.totalScore.minScale}-${score.totalScore.maxScale}`,
    detailsLink: `${ROUTES.details}?${score.rosterEntryId}`,
    khanLink: score.khanAcademyLink!,
    questionBankData: score.questionBankData,
    readingScore: undefined,
    readingScoreRange: '',
    mathScore: undefined,
    mathScoreRange: '',
    program: findProgram(score.asmtId),
    testVersion: findTestVersion(score.asmtEventId),
    totalDetails: {
      correctAnswers: score.totalScore.correctAnswers,
      incorrectAnswers: score.totalScore.incorrectAnswers,
      totalQuestions: score.totalScore.totalQuestions,
    },
    readingDetails: undefined,
    mathDetails: undefined,
    firstNm: studentInfo?.firstNm ?? null,
    lastNm: studentInfo?.lastNm ?? null,
  };

  // Append section score info to the scoreCardSummary object
  score.sectionScores.forEach((section: IScore) => {
    if (section.tierName === 'Reading and Writing') {
      scoreCardSummary.readingScore = section.score;
      scoreCardSummary.readingScoreRange = `${section.minScale}-${section.maxScale}`;
      scoreCardSummary.readingDetails = {
        correctAnswers: section.correctAnswers,
        incorrectAnswers: section.incorrectAnswers,
        totalQuestions: section.totalQuestions,
      };
    }

    if (section.tierName === 'Math') {
      scoreCardSummary.mathScore = section.score;
      scoreCardSummary.mathScoreRange = `${section.minScale}-${section.maxScale}`;
      scoreCardSummary.mathDetails = {
        correctAnswers: section.correctAnswers,
        incorrectAnswers: section.incorrectAnswers,
        totalQuestions: section.totalQuestions,
      };
    }
  });

  return scoreCardSummary;
};

export default formatPdfScore;
