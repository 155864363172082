export const selectedPromptSelectValues = [
  { label: 'Essay Prompt 1', value: '1' },
  { label: 'Essay Prompt 2', value: '2' },
  { label: 'Essay Prompt 3', value: '3' },
];

export const exampleEssaySelectValues = [
  [
    { label: 'Student Example 1, Score 1/1/1', value: '1' },
    { label: 'Student Example 2, Score 2/1/1', value: '2' },
    { label: 'Student Example 3, Score 2/1/2', value: '3' },
    { label: 'Student Example 4, Score 2/2/2', value: '4' },
    { label: 'Student Example 5, Score 3/1/3', value: '5' },
    { label: 'Student Example 6, Score 3/2/3', value: '6' },
    { label: 'Student Example 7, Score 3/3/3', value: '7' },
    { label: 'Student Example 8, Score 4/3/4', value: '8' },
    { label: 'Student Example 9, Score 4/4/4', value: '9' },
  ],
  [
    { label: 'Student Example 1, Score 1/1/1', value: '1' },
    { label: 'Student Example 2, Score 2/1/2', value: '2' },
    { label: 'Student Example 3, Score 2/2/2', value: '3' },
    { label: 'Student Example 4, Score 3/1/3', value: '4' },
    { label: 'Student Example 5, Score 3/2/3', value: '5' },
    { label: 'Student Example 6, Score 3/3/3', value: '6' },
    { label: 'Student Example 7, Score 3/3/4', value: '7' },
    { label: 'Student Example 8, Score 4/2/4', value: '8' },
    { label: 'Student Example 9, Score 4/3/4', value: '9' },
  ],
  [
    { label: 'Student Example 1, Score 1/1/1', value: '1' },
    { label: 'Student Example 2, Score 2/1/1', value: '2' },
    { label: 'Student Example 3, Score 2/2/2', value: '3' },
    { label: 'Student Example 4, Score 3/1/2', value: '4' },
    { label: 'Student Example 5, Score 3/2/2', value: '5' },
    { label: 'Student Example 6, Score 3/3/3', value: '6' },
    { label: 'Student Example 7, Score 3/3/4', value: '7' },
    { label: 'Student Example 8, Score 4/4/4', value: '8' },
    { label: 'Student Example 9, Score 4/4/4', value: '9' },
  ],
];
