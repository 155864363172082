import { useContext } from 'react';
import { IQbankTableData } from '../../../../../../../../contexts/questionbank/api/types';
import { searchQueryContext } from '../../../../../../../../contexts/questionbank/searchQuery';

interface IPrintableBanner {
  tableData: IQbankTableData;
}

/**
 * Banner component that optionally appears on the QuestionPage component. Contains
 * info about the question, including Test, Assessment, Domain, Skill, and Difficulty.
 */
const PrintableBanner: React.FC<IPrintableBanner> = ({ tableData }) => {
  const { assessment, section } = useContext(searchQueryContext);

  return (
    <div className="question-detail-info">
      <div className="question-banner row">
        <div className="col-sm cb-border cb-gray1-border">
          <div className="column-header">Assessment</div>
          <div className="column-content">{assessment?.label}</div>
        </div>
        <div className="col-sm cb-border cb-gray1-border">
          <div className="column-header">Test</div>
          <div className="column-content">{section?.label}</div>
        </div>
        <div className="col-sm cb-border cb-gray1-border">
          <div className="column-header">Domain</div>
          <div className="column-content">{tableData.domain}</div>
        </div>
        <div className="col-sm cb-border cb-gray1-border">
          <div className="column-header">Skill</div>
          <div className="column-content">{tableData.skill}</div>
        </div>
        <div className="col-sm cb-border cb-gray1-border">
          <div className="column-header">Difficulty</div>
          {tableData.difficulty}
        </div>
      </div>
    </div>
  );
};

export default PrintableBanner;
