import { useContext, useState, useEffect } from 'react';
import { ServiceDirectoryKey } from '@satreg/api';
import Cookies from 'universal-cookie';
import { AccountType, authContext, AuthProvider, LoginManager } from '@satreg/auth';
import { SessionTimeoutManager } from '@satreg/session-timeout-manager';
import { Spinner } from '@satreg/spinner';
import ROUTES from '../../routes';
import Login from '../../components/pages/main/login';
import NotStudent from '../../components/pages/main/notStudent';
import InstitutionalSessionTimeoutManager from './institutionalSessionTimeoutManager';
import './styles/index.scss';

const loginManagerProps = {
  appId: process.env.REACT_APP_APP_ID!,
  allowedAccountTypes: [AccountType.STUDENT],
  awsDomain: process.env.REACT_APP_AWS_DOMAIN!,
  loginRoute: ROUTES.login,
  loginComponent: Login,
  noAccessRoute: ROUTES.nonstudent,
  noAccessComponent: NotStudent,
};

/**
 * Component to render the @satreg/auth LoginManager
 */
const AppLoginManager: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isLoaded } = useContext(authContext);

  const getLandingRoute = () => {
    if (window.sessionStorage.getItem('route-to-qbank')) {
      return ROUTES.questionbank;
    }
    return ROUTES.dashboard;
  };

  useEffect(() => {
    if (window.location.pathname.includes('questionbank')) {
      window.sessionStorage.setItem('route-to-qbank', 'true');
    } else if (!window.location.pathname.includes('login')) {
      window.sessionStorage.removeItem('route-to-qbank');
    }
  }, []);

  return (
    <LoginManager {...loginManagerProps} landingRoute={getLandingRoute()}>
      {!isLoaded ? <Spinner /> : children}
    </LoginManager>
  );
};

/**
 * Component to render AuthProvider and LoginManager from @satreg/auth.
 */
const AppLoginManagerWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [tempLogin, setTempLogin] = useState(false);

  const cookies = new Cookies();

  useEffect(() => {
    if (cookies.get('__Host-tempJwtToken')) {
      setTempLogin(true);
    }
  }, [cookies]);

  // if temp creds user, render just the InstitutionalSessionTimeoutManager.
  if (tempLogin) {
    return (
      <InstitutionalSessionTimeoutManager redirectPath={ROUTES.access}>{children}</InstitutionalSessionTimeoutManager>
    );
  }

  // if Okta user, render the satreg AuthProvider, the AppLoginManager, and the satreg SessionTimeoutManager
  return (
    <AuthProvider directoryName={process.env.REACT_APP_AWS_DOMAIN as ServiceDirectoryKey}>
      <AppLoginManager>
        <SessionTimeoutManager redirectUrl={process.env.REACT_APP_BASE_URL}>{children}</SessionTimeoutManager>
      </AppLoginManager>
    </AuthProvider>
  );
};

export default AppLoginManagerWrapper;
