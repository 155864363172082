import { ReactComponent as BottomLeft } from './expand-collapse-icons/bottom_l.svg';
import { PaneFocus } from './types';

const BottomLeftControl: React.FC<{
  paneFocus: PaneFocus;
  setPaneFocus: React.Dispatch<React.SetStateAction<PaneFocus>>;
}> = ({ paneFocus, setPaneFocus }) => {
  return (
    <button type="button" aria-label="Collapse left pane" onClick={() => setPaneFocus(paneFocus)}>
      <BottomLeft />
    </button>
  );
};

export default BottomLeftControl;
