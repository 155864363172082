import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { IProgramType } from '@cb/athena-widgets-types/dist/props';
import { NakedButton } from '@cb/apricot-react-button';
import { getNameFromJwt } from './utils';
import TempSignoutModal from './tempSignoutModal';
import './styles/index.scss';

const TempHeader: React.FC = () => {
  const cookies = new Cookies();
  const [tempName, setTempName] = useState('');
  const [signoutModalOpen, setSignoutModalOpen] = useState(false);

  /**
   * Checks if jwt is present in browser. If present, set the tempLoggedIn flag to true.
   */
  useEffect(() => {
    const jwt = cookies.get('__Host-tempJwtToken') as string | undefined;
    if (jwt) {
      setTempName(getNameFromJwt(jwt));
    } else {
      setTempName('');
    }
  }, [cookies]);

  return (
    <div id="temp-header">
      <cbw-header
        app-id={process.env.REACT_APP_APP_ID!}
        title={process.env.REACT_APP_TITLE}
        program-type={process.env.REACT_APP_PROGRAM_TYPE! as IProgramType}
        idp="ECL"
        use-search-widget="false"
        use-login-widget="false"
        home-link={process.env.REACT_APP_BASE_URL}
        skip-target-id="main"
      />
      {/* If temp creds user, load the temp-logout-widget which opens the tempSignoutModal when clicked */}
      <div className="temp-logout-widget">
        <NakedButton onClick={() => setSignoutModalOpen(true)}>
          <p>
            Hi, <strong>{tempName}</strong>
          </p>
          <span className="cb-glyph-content-multi cb-user cb-glyph-sm hidden-xs" aria-hidden="true" />
        </NakedButton>
      </div>
      <TempSignoutModal modalOpen={signoutModalOpen} setModalOpen={setSignoutModalOpen} name={tempName} />
    </div>
  );
};

export default TempHeader;
