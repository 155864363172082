import { useContext, useEffect, useRef } from 'react';
import { HtmlMessage } from '@satui/html-message';
import { Spinner } from '@cb/apricot-react-spinner';
import { useMathml } from '@satui/test-questions';
import { qbankQuestionModalContext } from '../../../../../../../../contexts/questionbank/questionModal';
import { qbankQuestionDataContext } from '../../../../../../../../contexts/questionbank/api/QbankQuestionDataProvider';

const QbankQuestionCard: React.FC = () => {
  const { selectedRow } = useContext(qbankQuestionModalContext);
  const { qbankQuestionData } = useContext(qbankQuestionDataContext);
  const { isReady, resolveMathml } = useMathml();

  const cardRef = useRef<HTMLDivElement>(null);

  // initialize mathml
  useEffect(() => {
    if (isReady && cardRef.current) {
      resolveMathml(cardRef.current).then(() => {
        // resolved all the mathml code
      });
    }
  }, [isReady, cardRef.current]);

  // add mathml alt text to base mathml element
  useEffect(() => {
    const mathjaxContainers = document.querySelectorAll('mjx-container');
    mathjaxContainers.forEach((container) => {
      // get alttext from assistive-ml element
      const mathEl = container.childNodes[1].firstChild as HTMLElement;
      const altText = mathEl.getAttribute('alttext');

      // append alttext to main element
      if (altText) {
        container.setAttribute('role', 'img');
        container.setAttribute('aria-label', altText);
      }
    });
  }, []);

  if (!qbankQuestionData) return <Spinner />;

  return (
    <div id="qbank-question-card" className="row" ref={cardRef}>
      <div className="question-content col-xs-12 col-md-6">
        <h5 className="question-id">ID: {selectedRow!.questionId}</h5>
        <div className="prompt cb-margin-top-32">
          <HtmlMessage message={qbankQuestionData.prompt} />
        </div>
        <div className="question cb-margin-top-16">
          <HtmlMessage message={qbankQuestionData.stem} />
        </div>
        <div className="answer-choices cb-margin-top-16">
          <ul>
            {qbankQuestionData.answerOptions.map((option) => {
              return (
                <li key={option.letter}>
                  <HtmlMessage message={option.content} />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="answer-content col-xs-12 col-md-6">
        <h5 className="question-id">ID: {selectedRow!.questionId} Answer</h5>
        <div className="correct-answer cb-margin-top-32">
          <h6 className="cb-font-weight-bold">Correct Answer: </h6>
          <p>{qbankQuestionData.correctAnswer}</p>
        </div>
        <div className="rationale cb-margin-top-16">
          <h6 className="cb-margin-bottom-16 cb-font-weight-bold">Rationale</h6>
          <HtmlMessage message={qbankQuestionData.rationale} />
        </div>
        <div className="question-difficulty cb-margin-top-48">
          <h6 className="cb-font-weight-bold">Question Difficulty: </h6>
          <p>{selectedRow!.difficulty}</p>
        </div>
      </div>
    </div>
  );
};

export default QbankQuestionCard;
