import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { searchQueryContext } from '../../../../../../contexts/questionbank/searchQuery';
import { qbankLiveItemsDataContext } from '../../../../../../contexts/questionbank/api/QbankLiveItemsDataProvider';
import questionbankRoutes from '../../routes';

const DisplayQuery: React.FC = () => {
  const { assessment, setAssessment, section, setSection, domainScores, setDomainScores } =
    useContext(searchQueryContext);
  const { getQbankLiveItemsData } = useContext(qbankLiveItemsDataContext);
  const history = useHistory();

  // if no search query, send user back to search page. Otherwise, set the search query to state if it's not already set
  useEffect(() => {
    const searchQueryString = window.sessionStorage.getItem('sqbSearchQuery');

    if (!searchQueryString) {
      history.push(questionbankRoutes.search);
    } else if (!assessment || !section || !domainScores.length) {
      const searchQuery = JSON.parse(searchQueryString);
      setAssessment(searchQuery.assessment);
      setSection(searchQuery.section);
      setDomainScores(searchQuery.domainScores);
    }
  }, [history]);

  useEffect(() => {
    if (section?.value && domainScores.length) {
      getQbankLiveItemsData({ section: section.value as string });
    }
  }, [section, domainScores]);

  return (
    <div>
      <div id="display-query-card" className="cb-card cb-no-box-shadow">
        <div className="cb-card-content">
          <h2 className="cb-card-title">Question Bank Results</h2>
          <div className="cb-card-desc">
            <div className="sqb-instructions cb-margin-bottom-16">
              <p>Use the additional filters to narrow your results as desired:</p>
              <ul>
                <li>
                  <b>Difficulty:</b> Choose one or more difficulty levels for your questions (easy, medium, or hard).
                  You can refer to your Practice test results to see what levels you can improve on.
                </li>
                <li>
                  <b>Skill:</b> Choose a skill area within your chosen domain that you want to focus on.
                </li>
                <li>
                  <b>Exclude Active Questions: </b>
                  Choose whether to exclude questions already available on full-length practice tests.
                </li>
              </ul>
            </div>
            <div className="sqb-instructions cb-margin-bottom-16">
              <div className="card-text">
                <p>To complete your question set:</p>
                <ul>
                  <li>Select an ID number for a preview of any question.</li>
                  <li>Check boxes to add up to 20 questions to your set.</li>
                  <li>
                    Use the Export button to create a PDF of your question set with or without correct answers and
                    explanations.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayQuery;
