import { assessmentSelectValues, sectionSelectValues } from '../constants';

export const getAssessmentSelectValue = (value: string) => {
  return assessmentSelectValues.find((element) => element.value === value);
};

export const getSectionSelectValue = (value: string) => {
  return sectionSelectValues.find((element) => element.value === value);
};

export const sortDomainScores = (section: string, domainScores: string[]) => {
  if (section === 'reading') {
    return domainScores.sort((a, b) => {
      if (a === 'INI') return -1;
      if (a === 'CAS') {
        if (b === 'INI') return 1;
        return -1;
      }
      if (a === 'EOI') {
        if (b !== 'SEC') return 1;
        return -1;
      }
      if (a === 'SEC') return 1;
      return 0;
    });
  }

  if (section === 'math') {
    return domainScores.sort((a, b) => {
      if (a === 'H') return -1;
      if (a === 'P') {
        if (b === 'H') return 1;
        return -1;
      }
      if (a === 'Q') {
        if (b !== 'S') return 1;
        return -1;
      }
      if (a === 'S') return 1;
      return 0;
    });
  }

  return domainScores;
};
