import { jwtDecode } from 'jwt-decode';

interface IJwtObj {
  cb: {
    context: {
      firstName: string;
    };
  };
}

/**
 * Function to extract temp creds user's first name from JWT
 * @param jwt
 * @returns
 */
export const getNameFromJwt = (jwt: string) => {
  const jwtObj: IJwtObj = jwtDecode(jwt);
  const name = jwtObj.cb.context.firstName;
  return name;
};
