import { Redirect, Route, Switch } from 'react-router-dom';
import essayRoutes from './routes';
import ROUTES from '../../../../routes';
import EssayDetails from './essayDetails';
import EssayExamples from './essayExamples';

const Essay: React.FC = () => {
  return (
    <Switch>
      <Route path={essayRoutes.details} component={EssayDetails} />
      <Route path={essayRoutes.examples} component={EssayExamples} />
      <Route render={() => <Redirect to={ROUTES.dashboard} />} />
    </Switch>
  );
};

export default Essay;
