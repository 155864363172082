import { useEffect, useState, createContext } from 'react';
import { DIGITAL_PRACTICE, MS_PRACTICE_TESTRESULTS, GET_PRACTICE_SCORES, useApi } from '@satreg/api';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { IScoreObject, IScoreRes, IStudentInfo } from '../../types/scores';
import { getTempHeaders } from './utils';

interface IScoresContext {
  scores: IScoreObject[];
  studentInfo: IStudentInfo;
  isLoaded: boolean;
  error: boolean;
}

const defaultState: IScoresContext = {
  scores: [],
  studentInfo: null,
  isLoaded: false,
  error: false,
};

export const scoresContext = createContext<IScoresContext>(defaultState);
const { Provider } = scoresContext;

/**
 * Calls the scores microservice and provides the response to the application.
 */
const ScoresProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const scoresApi = useApi(DIGITAL_PRACTICE, MS_PRACTICE_TESTRESULTS);
  const [scores, setScores] = useState<IScoreObject[]>([]);
  const [studentInfo, setStudentInfo] = useState<IStudentInfo>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<boolean>(false);

  const cookies = new Cookies();

  /**
   * If request is successful, set scores data to state and set isLoaded to true.
   */
  const onSuccess = (res: IScoreRes) => {
    // set default khan link for all records without khan link
    const resWithDefaultLink = res.scoreObjects?.map((score) => {
      if (!score.khanAcademyLink) score.khanAcademyLink = 'https://www.khanacademy.org/digital-sat';
      return score;
    });

    setScores(resWithDefaultLink!);
    setStudentInfo(res.studentInfo);
    setIsLoaded(true);
  };

  /**
   * If request fails, set error and isLoaded to true.
   */
  const onFailure = () => {
    setError(true);
    setIsLoaded(true);
  };

  /**
   * Call the scores microservice
   */
  useEffect(() => {
    if (!scoresApi.isLoaded) return;

    const tempCookie = cookies.get('__Host-tempJwtToken');

    // if user has temp creds, call the get institutional scores service
    if (tempCookie) {
      axios
        .post(process.env.REACT_APP_SCORES_ENDPOINT!, undefined, { headers: getTempHeaders() })
        .then(({ data }: { data: IScoreObject[] }) => {
          onSuccess({
            // Institutional lambda doesn't return student info, only the scoreObjects
            scoreObjects: data,
            studentInfo: null,
          });
          setIsLoaded(true);
        })
        .catch(() => {
          onFailure();
        });
    }
    // if user is an Okta user, call the get scores service.
    else {
      if (scoresApi.authUnavailable) return;

      scoresApi.post(GET_PRACTICE_SCORES, {}, onSuccess, onFailure);
    }
  }, [scoresApi, scoresApi.authUnavailable, scoresApi.isLoaded]);

  return <Provider value={{ scores, studentInfo, isLoaded, error }}>{children}</Provider>;
};

export default ScoresProvider;
