import { createContext, useState } from 'react';
import { IQbankTableData } from '../api/types';

interface IQbankQuestionModalContext {
  openModal: () => void;
  closeModal: () => void;
  isModalOpen: boolean;
  selectedRow?: IQbankTableData;
  setSelectedRow: React.Dispatch<React.SetStateAction<IQbankTableData | undefined>>;
  selectedRowIndex?: number;
  setSelectedRowIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const defaultState: IQbankQuestionModalContext = {
  openModal: () => {},
  closeModal: () => {},
  isModalOpen: false,
  setSelectedRow: () => {},
  setSelectedRowIndex: () => {},
};

export const qbankQuestionModalContext = createContext(defaultState);
const { Provider } = qbankQuestionModalContext;

/**
 * Provides the state of the question modal, and functions to open, close, and set the selected question.
 */
const QbankQuestionModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<IQbankTableData>();
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>();

  /**
   * Function to open the modal.
   */
  const openModal = () => {
    setIsModalOpen(true);
  };

  /**
   * Function to close the modal.
   */
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Provider
      value={{
        openModal,
        closeModal,
        isModalOpen,
        selectedRow,
        setSelectedRow,
        selectedRowIndex,
        setSelectedRowIndex,
      }}
    >
      {children}
    </Provider>
  );
};

export default QbankQuestionModalProvider;
