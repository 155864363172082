import { createContext, useContext, useMemo, useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import ROUTES from '../../routes';
import { tempKeyperReportingContext } from './TempKeyperReportingProvider';
import { TEMP_AUTH_CODES, TEMP_AUTH_KEYPER_CODES } from './constants';

interface ITempAuthContext {
  getTempToken: (username: string, password: string) => void;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
}

const defaultState: ITempAuthContext = {
  getTempToken: () => {},
  error: '',
  setError: () => {},
};

export const tempAuthContext = createContext(defaultState);
const { Provider } = tempAuthContext;

/**
 * Provides the necessary APIs for authenticating with temp creds to the application.
 * Once the temp JWT is received, sets the JWT to a cookie in the browser.
 */
const TempAuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [error, setError] = useState('');

  const { reportLogin } = useContext(tempKeyperReportingContext);

  const cookies = new Cookies();
  const awsDomains: string = window.cb.core.utils.DeploymentProfile.iam.aws.cbAWSDomains.toString();

  /**
   * Function to retrieve the temp creds JWT, and set it to a browser cookie.
   * @param username
   * @param password
   */
  const getTempToken = useMemo(() => {
    const getToken = (username: string, password: string) => {
      // reset from last request
      setError('');

      const rmtUrl = process.env.REACT_APP_RMT_ENDPOINT!;
      const rmtBody = { username, password, fingerprint: 'not-nice' };
      const rmtHeaders = { 'x-api-key': process.env.REACT_APP_RMT_API_KEY! };

      // call the DAP Auth API to retrieve the RMT token.
      axios
        .post(rmtUrl, rmtBody, { headers: rmtHeaders })
        .then((rmtRes) => {
          const { code } = rmtRes.data;

          // Report login attempt to Keyper
          switch (code) {
            case TEMP_AUTH_CODES.SUCCESS:
              reportLogin(TEMP_AUTH_KEYPER_CODES.SUCCESS);
              break;
            case TEMP_AUTH_CODES.ERROR_BAD_CREDENTIALS:
              reportLogin(TEMP_AUTH_KEYPER_CODES.BAD_CREDENTIALS);
              break;
            case TEMP_AUTH_CODES.EXPIRED_CREDENTIALS:
              reportLogin(TEMP_AUTH_KEYPER_CODES.EXPIRED);
              break;
            case TEMP_AUTH_CODES.INACTIVE_CREDENTIALS:
              reportLogin(TEMP_AUTH_KEYPER_CODES.INACTIVE);
              break;
            default:
          }

          return rmtRes;
        })
        .then((rmtRes) => {
          const { rmt, code } = rmtRes.data;

          if (rmt) {
            const tokenUrl = `${process.env.REACT_APP_JWT_ENDPOINT!}?cbEnv=${process.env
              .REACT_APP_CB_ENV!}&cbAWSDomains=${awsDomains}&cacheNonce=0`;
            const tokenHeaders = { Authorization: `CBLoginWeak ${rmt}` };

            // using RMT, get the JWT token.
            axios
              .get(tokenUrl, { headers: tokenHeaders })
              .then((tokenRes) => {
                const { cbJwtToken } = tokenRes.data;
                // set expiration date to three hours from current time
                const expirationDate = new Date(new Date().getTime() + 3 * 60 * 60 * 1000);

                // set cookie with JWT to browser
                cookies.set('__Host-tempJwtToken', cbJwtToken, {
                  path: '/',
                  sameSite: 'strict',
                  secure: true,
                  expires: expirationDate,
                });

                // navigate to dashboard or questionbank
                if (window.sessionStorage.getItem('route-to-qbank')) {
                  window.sessionStorage.removeItem('route-to-qbank');
                  window.location.replace(ROUTES.questionbank);
                } else {
                  window.location.replace(ROUTES.dashboard);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            setError(code);
          }
        })
        .catch((err) => {
          setError(err.code);
        });
    };
    return getToken;
  }, []);

  return <Provider value={{ getTempToken, error, setError }}>{children}</Provider>;
};

export default TempAuthProvider;
