import { useContext, useState } from 'react';
import { useFunctionalContentItems } from '@satui/drupal-profile';
import { Spinner } from '@cb/apricot-react-spinner';
import { Modal } from '@cb/apricot-react-modal';
import { HtmlMessage } from '@satreg/drupal';
import { useHistory } from 'react-router-dom';
import HorizontalCarousel from './HorizontalCarousel';
import { CAROUSEL_HEADER_KEYS, TESTING_RULES_KEYS } from '../../../../drupal';
import { IEssayCardSummary, IScoreCardSummary } from '../types';
import CarouselScoreCard from './carouselScoreCard';
import { testingRulesStatusContext } from '../../../../../contexts/api/TestingRulesStatusProvider';
import './styles/index.scss';
import CarouselEssayCard from './carouselEssayCard';

interface IScoresCarousel {
  satScoreCardSummaries: IScoreCardSummary[];
  psatScoreCardSummaries: IScoreCardSummary[];
  essayCardSummaries: IEssayCardSummary[];
}

/**
 * Score card carousel component.
 */
const ScoresCarousel: React.FC<IScoresCarousel> = ({
  satScoreCardSummaries,
  psatScoreCardSummaries,
  essayCardSummaries,
}) => {
  const history = useHistory();
  const { testingRulesStatus, updateTestingRulesStatus, isLoaded } = useContext(testingRulesStatusContext);
  const [modalState, setModalState] = useState(false);
  const [detailsLink, setDetailsLink] = useState('');

  const { drupalContent } = useFunctionalContentItems([
    ...Object.values(CAROUSEL_HEADER_KEYS),
    ...Object.values(TESTING_RULES_KEYS),
  ]);

  if (!drupalContent || !isLoaded) {
    return <Spinner />;
  }

  const {
    [CAROUSEL_HEADER_KEYS.SAT_HEADER]: satContent,
    [CAROUSEL_HEADER_KEYS.PSAT_HEADER]: psatContent,
    [CAROUSEL_HEADER_KEYS.ESSAY_HEADER]: essayContent,
    [TESTING_RULES_KEYS.TESTING_RULES_CONTENT]: testingRulesContent,
  } = drupalContent;

  return (
    <>
      {!!satScoreCardSummaries.length && (
        <>
          <HtmlMessage message={satContent.overview || ''} />
          <HorizontalCarousel
            scrollDistance={380}
            itemClass="carousel-score-card"
            className="cb-padding-bottom-72 cb-padding-xs-bottom-48"
          >
            <ul role="none" className="sat-carousel carousel-container">
              {satScoreCardSummaries.map((summary, index) => {
                return (
                  <CarouselScoreCard
                    key={index}
                    scoreCardSummary={summary}
                    setModalState={setModalState}
                    setDetailsLink={setDetailsLink}
                  />
                );
              })}
            </ul>
          </HorizontalCarousel>
        </>
      )}
      {!!psatScoreCardSummaries.length && (
        <>
          <HtmlMessage message={psatContent.overview || ''} />
          <HorizontalCarousel scrollDistance={380} className="cb-padding-bottom-72 cb-padding-xs-bottom-48">
            <ul className="psat-carousel carousel-container">
              {psatScoreCardSummaries.map((summary, index) => {
                return (
                  <CarouselScoreCard
                    key={index}
                    scoreCardSummary={summary}
                    setModalState={setModalState}
                    testingRulesStatus={testingRulesStatus}
                    setDetailsLink={setDetailsLink}
                  />
                );
              })}
            </ul>
          </HorizontalCarousel>
        </>
      )}
      {!!essayCardSummaries.length && (
        <>
          <HtmlMessage message={essayContent.overview || ''} />
          <HorizontalCarousel className="essay-no-border cb-padding-bottom-72 cb-padding-xs-bottom-48">
            <ul className="essay-carousel carousel-container">
              {essayCardSummaries.map((summary, index) => {
                return <CarouselEssayCard key={index} essayCardSummary={summary} />;
              })}
            </ul>
          </HorizontalCarousel>
        </>
      )}
      <Modal
        title={testingRulesContent.title || ''}
        open={modalState}
        onClose={() => {
          setModalState(false);
          updateTestingRulesStatus();
          history.push(detailsLink);
        }}
        closeButtonLabel="Okay"
      >
        <HtmlMessage message={testingRulesContent.overview || ''} />
      </Modal>
    </>
  );
};

export default ScoresCarousel;
