import { ICustomField } from '@cb/apricot-react-table';
import { HtmlMessage } from '@satreg/drupal';
import { IQuestionTableRow } from '../../types';

/** Display the correct answer as HTML */
const CorrectAnswerContent: ICustomField<IQuestionTableRow> = ({ value }) => (
  <HtmlMessage message={(value as string) || ''} />
);

export default CorrectAnswerContent;
