import { useContext, useEffect, useState } from 'react';
import QbankQuestionDataProvider from '../../../../../contexts/questionbank/api/QbankQuestionDataProvider';
import QbankTableDataProvider from '../../../../../contexts/questionbank/api/QbankTableDataProvider';
import ExportQuestionSetProvider from '../../../../../contexts/questionbank/exportQuestionSet';
import FilteredTableDataProvider from '../../../../../contexts/questionbank/filteredTableData';
import QbankQuestionModalProvider from '../../../../../contexts/questionbank/questionModal';
import DisplayQuery from './displayQuery';
import FiltersExport from './filtersExport';
import QuestionbankTable from './questionbankTable';
import ViewPracticeScores from './viewPracticeScore';
import { IScoreEssayRes, IScoreObject } from '../../../../../types/scores';
import './styles/index.scss';
import { scoresContext } from '../../../../../contexts/api/ScoresProvider';

const Results: React.FC = () => {
  const { scores } = useContext(scoresContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showKsd, setShowKsd] = useState(false);

  const hasKSD = () => {
    const isScoreEssayRes = (s: IScoreObject): s is IScoreEssayRes => (s as IScoreEssayRes).essay !== undefined;
    return scores.some((s) => !isScoreEssayRes(s) && s.questionBankData !== undefined);
  };

  const getMainContentClass = () => {
    if (hasKSD()) {
      if (showKsd) return `col-sm-8 col-xs-12`;
      return 'col-sm-9 col-xs-12';
    }
    return 'col-xs-12';
  };

  // set title
  // set screensize listener
  useEffect(() => {
    window.scrollTo({ top: 0 });

    document.title = 'MyPractice - Question Bank - Results';

    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <ExportQuestionSetProvider>
      <QbankTableDataProvider>
        <FilteredTableDataProvider>
          <QbankQuestionModalProvider>
            <QbankQuestionDataProvider>
              <div id="questionbank-results" className="container">
                <div className="row">
                  <div className="col-xs-12">
                    <DisplayQuery />
                  </div>
                  <div className={getMainContentClass()}>
                    <FiltersExport
                      windowWidth={windowWidth}
                      showKsd={showKsd}
                      setShowKsd={setShowKsd}
                      hasKSD={hasKSD}
                    />
                    <QuestionbankTable windowWidth={windowWidth} showKsd={showKsd} />
                  </div>
                  {hasKSD() && windowWidth > 767 && (
                    <div className={showKsd ? 'col-xs-4' : 'col-xs-3'}>
                      <ViewPracticeScores showKsd={showKsd} setShowKsd={setShowKsd} />
                    </div>
                  )}
                </div>
              </div>
            </QbankQuestionDataProvider>
          </QbankQuestionModalProvider>
        </FilteredTableDataProvider>
      </QbankTableDataProvider>
    </ExportQuestionSetProvider>
  );
};

export default Results;
