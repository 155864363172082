import { Tabs, Tab } from '@cb/apricot-react-tabs';
import { ITableNav } from './types';
import './styles/index.scss';

/**
 * Component to render the navigation tabs for the details page
 */
const TableNav: React.FC<ITableNav> = ({ labelArr, onTabChange }) => {
  /**
   * Invokes the onTabChange callback passing the panelId of the tab as a parameter.
   * @param panelId
   */
  const onChange = (panelId: string) => {
    if (onTabChange) {
      onTabChange(panelId);
    }
  };

  /**
   * Maps over the labelArr prop to render the tab components
   */
  const tabMenuItems = labelArr.map((label: string, index: number) => {
    const tabProps = {
      key: `index-${index}`,
      label,
      panelId: `Tab ${index}`,
      labelClassName: 'cb-roboto-slab-bold justify-content-center cb-palette-black',
      selected: false,
    };
    if (index === 0) tabProps.selected = true;

    return <Tab {...tabProps} />;
  });

  return (
    <div id="table-nav" className="table-tabs">
      <div className="tabs-container">
        <Tabs onTabChange={(panelId: string) => onChange(panelId)}>{tabMenuItems}</Tabs>
      </div>
    </div>
  );
};

export default TableNav;
