import { IQuestionRes } from '../../../../../types/questions';
import { IDetailsQuestionData, IQuestionTableData, IQuestionTableRow } from '../types';

/**
 * Takes questions data from microservice and formats it to be used by details page
 * @param questions
 */
const formatQuestionsData: (questions: IQuestionRes) => IDetailsQuestionData = (questions) => {
  // sort questions within their section objects by sequence number
  const sortedQuestions = questions.map((questionObj) => {
    questionObj.items = questionObj.items.sort((a, b) => a.sequence - b.sequence);
    return questionObj;
  });

  // Maps question data from microservice for consumption by questionsTable component
  const tableQuestionData: IQuestionTableData = {};
  sortedQuestions.forEach((section) => {
    // convert IQuestion[] to IQuestionTableRow[]
    tableQuestionData[section.id] = section.items.map((question, index) => {
      if (question.section === 'Reading') question.section = 'Reading and Writing';

      return {
        number: Number.parseInt(question.displayNumber, 10),
        section: question.section,
        correct: question.answer.correctChoice,
        provided: question.answer.response,
        isCorrect: question.answer.correct,
        module: Number.parseInt(question.displayNumber, 10) - 1 < index ? 2 : 1,
        question,
      };
    });
  });

  // create an array that contains all questions
  const allQuestions: IQuestionTableRow[] = [];
  Object.values(tableQuestionData).forEach((section) => {
    section.forEach((question) => {
      allQuestions.push(question);
    });
  });
  tableQuestionData.all = allQuestions;

  return { tableQuestionData };
};

export default formatQuestionsData;
