import { Fragment, useContext } from 'react';
import { Checkbox } from '@cb/apricot-react-forms';
import { NakedButton, PrimaryButton } from '@cb/apricot-react-button';
import { filteredTableDataContext } from '../../../../../../../contexts/questionbank/filteredTableData';
import { IFilterCheckbox, IFilterSkillGroup } from '../../../../../../../contexts/questionbank/filteredTableData/types';

interface ISkillCheckboxGroup {
  currentSelectedSkills: IFilterSkillGroup[];
  setCurrentSelectedSkills: React.Dispatch<React.SetStateAction<IFilterSkillGroup[]>>;
}

const SkillCheckboxGroup: React.FC<ISkillCheckboxGroup> = ({ currentSelectedSkills, setCurrentSelectedSkills }) => {
  const { setSkillFilters } = useContext(filteredTableDataContext);

  const onChange = (checked: boolean, event: InputEvent) => {
    const inputEl = event.target as HTMLInputElement;
    const filter: IFilterCheckbox = { value: inputEl.defaultValue, checked };

    setCurrentSelectedSkills(
      currentSelectedSkills.map((currentFilter) => {
        return {
          domain: currentFilter.domain,
          checkboxes: currentFilter.checkboxes.map((checkbox) => {
            if (checkbox.value === filter.value) return filter;
            return checkbox;
          }),
        };
      }),
    );
  };

  const onClear = () => {
    setCurrentSelectedSkills(
      currentSelectedSkills.map((filter) => {
        const unchecked = filter.checkboxes.map((checkboxes) => ({ value: checkboxes.value, checked: false }));
        return { domain: filter.domain, checkboxes: unchecked };
      }),
    );
  };

  const onSave = () => {
    setSkillFilters(currentSelectedSkills);
  };

  return (
    <>
      <div className="filter-checkbox-group">
        {currentSelectedSkills.map((filter, index) => {
          return (
            <Fragment key={index}>
              <div role="group" aria-labelledby={filter.domain} className="skill-group cb-margin-bottom-32">
                <h4 id={filter.domain} className="cb-margin-bottom-16">
                  {filter.domain}
                </h4>
                {filter.checkboxes.map((checkbox) => {
                  return (
                    <Checkbox
                      key={checkbox.value}
                      className="cb-margin-bottom-16"
                      label={checkbox.value}
                      value={checkbox.value}
                      checked={checkbox.checked}
                      onChange={onChange}
                    />
                  );
                })}
              </div>
              {index !== currentSelectedSkills.length - 1 && <div className="checkbox-divider" />}
            </Fragment>
          );
        })}
      </div>
      <footer className="display-flex justify-content-between cb-padding-top-16 cb-margin-top-32">
        <NakedButton onClick={onClear}>Clear</NakedButton>
        <PrimaryButton data-cb-popover-close onClick={onSave}>
          Save
        </PrimaryButton>
      </footer>
    </>
  );
};

export default SkillCheckboxGroup;
