import { typedTableComponents } from '@cb/apricot-react-table';
import tableColumnConfig from './config';
import { IQbankTableData } from '../../../../../../../contexts/questionbank/api/types';

const getQuestionbankTableColumns: () => JSX.Element[] = () => {
  const { Column } = typedTableComponents<IQbankTableData>();

  return tableColumnConfig.map((config, index) => {
    const headerComponent = config.headerComponent ? config.headerComponent : undefined;
    const component = config.component ? config.component : undefined;

    return (
      <Column
        key={index + 1}
        field={config.field}
        title={config.title}
        className={config.className}
        headerComponent={headerComponent}
        component={component}
        rowHeader={config.header}
      />
    );
  });
};

export default getQuestionbankTableColumns;
