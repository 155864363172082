import { useFunctionalContentItem } from '@satui/drupal-profile';
import { NOTIFICATION_KEYS } from '../drupal';
import DrupalNotification from './satuiDrupalNotification';
import './styles/index.scss';

/**
 * Generic notification component controlled by Drupal. Content is updated through drupal, this
 * notification displays on all authenticated pages.
 */
const DrupalAppNotification: React.FC = () => {
  const drupalContent = useFunctionalContentItem(NOTIFICATION_KEYS.NOTIFICATION);

  return <DrupalNotification drupalContent={drupalContent.content} />;
};

export default DrupalAppNotification;
