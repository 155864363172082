import { NakedButton, YellowButton } from '@cb/apricot-react-button';
import { Tooltip } from '@cb/apricot-react-tooltip';
import { Icon } from '@cb/apricot-react-icon';
import { useHistory } from 'react-router-dom';
import { IEssayCardSummary } from '../../types';

interface ICarouselEssayCard {
  essayCardSummary: IEssayCardSummary;
}

const CarouselEssayCard: React.FC<ICarouselEssayCard> = ({ essayCardSummary }) => {
  const history = useHistory();
  const { id, date, detailsLink, examplesLink, rosterEntryId, program, testVersion } = essayCardSummary;

  return (
    <li id={id} className="carousel-essay-card cb-white-bg">
      <div className="header">
        <p>{program}</p>
      </div>
      <div className="test-version">
        <div className="version">{testVersion}</div>
        <div className="date">{date}</div>
      </div>
      <div className="body">
        <div className="section-scores">
          <div className="reading-writing border-bottom">
            <div>
              <p>Reading</p>
            </div>
            <div className="score-display">
              <span className="sr-only">In a range</span>
              <div id={`${rosterEntryId}-reading`}>
                <p className="section-score-range cb-link-black cb-link-dotted">1-4</p>
              </div>
              <Tooltip trigger={`${rosterEntryId}-reading`} placement="left" className="tooltip-wrapper">
                <p>This range reflects the minimum and maximum total score you can get with this assessment.</p>
              </Tooltip>
            </div>
          </div>
          <div className="reading-writing border-bottom">
            <div>
              <p>Analysis</p>
            </div>
            <div className="score-display">
              <span className="sr-only">In a range</span>
              <div id={`${rosterEntryId}-analysis`}>
                <p className="section-score-range cb-link-black cb-link-dotted">1-4</p>
              </div>
              <Tooltip trigger={`${rosterEntryId}-analysis`} placement="left" className="tooltip-wrapper">
                <p>This range reflects the minimum and maximum total score you can get with this assessment.</p>
              </Tooltip>
            </div>
          </div>
          <div className="reading-writing border-bottom">
            <div>
              <p>Writing</p>
            </div>
            <div className="score-display">
              <span className="sr-only">In a range</span>
              <div id={`${rosterEntryId}-writing`}>
                <p className="section-score-range cb-link-black cb-link-dotted">1-4</p>
              </div>
              <Tooltip trigger={`${rosterEntryId}-writing`} placement="left" className="tooltip-wrapper">
                <p>This range reflects the minimum and maximum total score you can get with this assessment.</p>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="footer">
          <YellowButton
            aria-label="Detailed test view link"
            onClick={() => history.push(detailsLink)}
            className="cb-black-color"
            small
          >
            See Your Essay
          </YellowButton>
          <NakedButton
            className="essay-examples-button"
            aria-label="Essay examples view link"
            onClick={() => {
              window.scrollTo({ top: 0 });
              history.push(examplesLink);
            }}
            small
          >
            <Icon name="practice-test" />
            <span className="essay-examples-text">See Student Essay Example</span>
          </NakedButton>
        </div>
      </div>
    </li>
  );
};

export default CarouselEssayCard;
