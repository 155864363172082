import { createContext, useState } from 'react';
import { IQbankTableData } from '../api/types';

interface IExportQuestionSetContext {
  exportData: IQbankTableData[];
  addQuestion: (question: IQbankTableData) => void;
  removeQuestion: (questionId: string) => void;
  dataLoaded: boolean;
  setDataLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultState: IExportQuestionSetContext = {
  exportData: [],
  addQuestion: () => {},
  removeQuestion: () => {},
  dataLoaded: false,
  setDataLoaded: () => {},
};

export const exportQuestionSetContext = createContext<IExportQuestionSetContext>(defaultState);
const { Provider } = exportQuestionSetContext;

/**
 * Provider to track which questions user has selected for export
 */
const ExportQuestionSetProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [exportData, setExportData] = useState<IQbankTableData[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  /**
   * Adds a question to the array or exported questions
   * @param question
   */
  const addQuestion = (question: IQbankTableData) => {
    const currentData = [...exportData];
    currentData.push(question);
    setExportData(currentData);
  };

  /**
   * Removes a question from the list of exported questions
   * @param questionId
   */
  const removeQuestion = (questionId: string) => {
    setExportData(
      exportData.filter((row) => {
        if (row.questionId !== questionId) return true;
        return false;
      }),
    );
  };

  return <Provider value={{ exportData, addQuestion, removeQuestion, dataLoaded, setDataLoaded }}>{children}</Provider>;
};

export default ExportQuestionSetProvider;
