import DifficultyIcon, { Difficulty } from './DifficultyIcon';
import './index.scss';

/**
 * A graphic to be displayed in the popover for difficulty. Shows a few different difficulty levels with descriptions, and a header.
 * @constructor
 */
const DifficultyPopoverContentGraphic: React.FC = () => {
  const difficultyLevels: Difficulty[] = ['Easy', 'Medium', 'Hard'];

  return (
    <div>
      <div>Difficulty Icon Key</div>
      {difficultyLevels.map((difficultyLevel, index: number) => {
        return (
          <div key={index}>
            <DifficultyIcon difficulty={difficultyLevel} />
            <span className="tqdifficulty-text">{difficultyLevel}</span>
          </div>
        );
      })}
    </div>
  );
};

export default DifficultyPopoverContentGraphic;
