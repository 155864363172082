import { ILabeledValue } from '@cb/apricot-react-forms';
import { createContext, useState } from 'react';

interface ISearchQueryContext {
  assessment: ILabeledValue | null;
  setAssessment: React.Dispatch<React.SetStateAction<ILabeledValue | null>>;
  section: ILabeledValue | null;
  setSection: React.Dispatch<React.SetStateAction<ILabeledValue | null>>;
  domainScores: string[];
  setDomainScores: React.Dispatch<React.SetStateAction<string[]>>;
}

const defaultState: ISearchQueryContext = {
  assessment: null,
  setAssessment: () => {},
  section: null,
  setSection: () => {},
  domainScores: [],
  setDomainScores: () => {},
};

export const searchQueryContext = createContext(defaultState);
const { Provider } = searchQueryContext;

/**
 * Provides functions to set the user's search query to state, and provides that search query to the results page
 */
const SearchQueryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [assessment, setAssessment] = useState<ILabeledValue | null>(null);
  const [section, setSection] = useState<ILabeledValue | null>(null);
  const [domainScores, setDomainScores] = useState<string[]>([]);

  return (
    <Provider value={{ assessment, setAssessment, section, setSection, domainScores, setDomainScores }}>
      {children}
    </Provider>
  );
};

export default SearchQueryProvider;
