import React, { FunctionComponent, ReactInstance, useRef } from 'react';
import ReactToPrint from 'react-to-print';

export interface IPrintPDFProps {
  trigger: React.ReactElement;
  children: React.ReactElement;
  onBeforeGetContent: () => void | Promise<any>;
}

/**
 * This component prints the rendered children in the browser print dialog.
 * It takes a ReactElement as a trigger element, e.g. a button
 *
 * @param trigger
 * @param children
 * @param onBeforeGetContent
 */
const PrintPDF: FunctionComponent<IPrintPDFProps> = ({ trigger, children, onBeforeGetContent }) => {
  const componentRef = useRef(null);

  const contentFunction = () => {
    return componentRef.current as unknown as ReactInstance;
  };

  const triggerFunction = () => {
    return trigger;
  };

  return (
    <>
      {/* @see https://github.com/gregnb/react-to-print */}
      <ReactToPrint
        trigger={triggerFunction}
        content={contentFunction}
        onBeforeGetContent={onBeforeGetContent}
        suppressErrors
      />
      <div ref={componentRef} className="print-pdf">
        {children}
      </div>
    </>
  );
};

export default PrintPDF;
