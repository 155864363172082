import { useContext, useEffect, useMemo, useState } from 'react';
import { typedTableComponents } from '@cb/apricot-react-table';
import { Pager } from '@cb/apricot-react-pager';
import { TestQuestionsDisplay } from '@satui/test-questions';
import { webAnalyticsContext } from '@satreg/analytics';
import { NakedButton } from '@cb/apricot-react-button';
import { paginate, questionsSortFunction } from './utils';
import { IQuestionTableRow, ITest } from '../types';
import ReviewButton from './reviewButton';
import YourAnswerContent from './yourAnswerContent';
import { IQuestion } from '../../../../../types/questions';
import CorrectAnswerContent from './correctAnswerContent';
import { WA_ACTION_TYPES, WA_EVENTS } from '../../../../analytics/constants';
import './styles/index.scss';

const { Table, Column } = typedTableComponents<IQuestionTableRow>();

interface IQuestionsTable {
  test: ITest;
  data: IQuestionTableRow[];
  testTitle: string;
  reviewButtonActive: boolean;
  tab: number;
  setReviewButtonActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export type PageSize = '10' | '30' | 'all';

/**
 * Component to display an Apricot table of student's test questions.
 * Includes question number, correct answer, student answer, and a review button bringing students to individual question review.
 */
const QuestionsTable: React.FC<IQuestionsTable> = ({
  test,
  data,
  testTitle,
  reviewButtonActive,
  tab,
  setReviewButtonActive,
}) => {
  const [index, setIndex] = useState<number>();
  const [sortedQuestions, setSortedQuestions] = useState<IQuestionTableRow[]>([]);
  const { notifyAnalytics, reportPageData } = useContext(webAnalyticsContext);
  const [reportQuestion, setReportQuestion] = useState<number>();
  const [openTestReview, setOpenTestReview] = useState(false);
  const [currentButtonId, setCurrentButtonId] = useState<string>();
  const [pageSize, setPageSize] = useState<PageSize>('10');
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedQuestions, setPaginatedQuestions] = useState(data);
  const [pagerPadding, setPagerPadding] = useState(72);

  /** Array of displayed questions */
  const displayQuestions = useMemo(() => {
    return sortedQuestions.map((row) => row.question);
  }, [sortedQuestions]);

  useEffect(() => {
    setPaginatedQuestions(paginate(data, pageSize, currentPage));
  }, [data, pageSize, currentPage]);

  useEffect(() => {
    const cbPagination = document.getElementsByClassName('cb-pagination');
    if (cbPagination.length) {
      const cbPaginationLength = cbPagination[0].children.length;
      switch (cbPaginationLength) {
        case 4:
          setPagerPadding(120);
          break;
        case 5:
          setPagerPadding(96);
          break;
        case 6:
          setPagerPadding(72);
          break;
        case 7:
          setPagerPadding(48);
          break;
        case 8:
          setPagerPadding(24);
          break;
        default:
          setPagerPadding(0);
      }
    }
  }, [paginatedQuestions]);

  // if there is a question loaded in the question modal, report it to WA.
  useEffect(() => {
    if (reportQuestion !== undefined) {
      // report the currently selected number
      reportPageData({
        flowCode: 'satpractice',
        pageCode: 'question',
        appViewCode: sortedQuestions[reportQuestion].number.toString().padStart(3, '0'),
        stateCode: test.title,
      });
      // reset the reported question number
      setReportQuestion(undefined);
    }
  }, [reportQuestion]);

  // add border in between section modules
  useEffect(() => {
    const tableRowEls = Array.from(document.getElementsByClassName('table-row'));

    // remove existing border classes
    tableRowEls.forEach((row) => {
      row.className = row.className.replace('module-border', '');
    });

    // add new border class
    tableRowEls.forEach((row, i) => {
      // if it's not the last row
      if (tableRowEls[i + 1]) {
        // if the next row isn't module 1 add border
        if (row.classList.contains('module-1')) {
          if (!tableRowEls[i + 1].classList.contains('module-1')) {
            row.className = `${row.className} module-border`;
          }
        }

        // if the next row isn't module 2 add border
        if (row.classList.contains('module-2')) {
          if (!tableRowEls[i + 1].classList.contains('module-2')) {
            row.className = `${row.className} module-border`;
          }
        }
      }
    });
  }, [sortedQuestions]);

  useEffect(() => {
    setCurrentPage(1);
  }, [tab]);

  /**
   * onClick function for review question button. Opens the questions modal and
   * sets the selected question to state.
   */
  const onClick = (module: number, number: number, question: IQuestion) => {
    if (!reviewButtonActive) return;

    // report question to WA.
    notifyAnalytics(WA_EVENTS.TRACK_PRACTICE, {
      actionType: WA_ACTION_TYPES.FIRST_QUESTION,
      practiceTestNumber: test.number.toString(),
      questionNumber: number.toString().padStart(3, '0'),
      stateCode: test.title,
    });

    setCurrentButtonId(`module-${module.toString()}-question-${number.toString()}-button`);
    setIndex(displayQuestions.indexOf(question));

    setReviewButtonActive(false);
    setOpenTestReview(true);
  };

  /** Reset the question */
  const onCleanup = () => {
    setOpenTestReview(false);
    document.getElementById(currentButtonId!)!.focus();

    setTimeout(() => {
      setReviewButtonActive(true);
    }, 500);
  };

  const onPageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const getPagerMax = () => {
    if (pageSize === '10') return Math.ceil(data.length / 10);
    if (pageSize === '30') return Math.ceil(data.length / 30);
    return 0;
  };

  return (
    <>
      {displayQuestions && (
        <div id="individual-review">
          <TestQuestionsDisplay
            open={openTestReview}
            index={index}
            questions={displayQuestions.map((question) => ({ number: `${question.displayNumber}`, question }))}
            onCleanup={onCleanup}
            hideDifficulty
            showExplanation
            allowPrint={false}
            withAnswerTrigger
            withExpandableAnswer={false}
            titleClassName="cb-roboto-black cb-h4"
            titlePrefix={testTitle}
            prefixClassName="display-block"
            onQuestionRendered={setReportQuestion}
          />
        </div>
      )}
      <div id="questions-table" className="col-xs-12">
        <div className="page-size">
          <p>View:</p>
          <NakedButton
            disabled={pageSize === '10'}
            className={`${pageSize === '10' && 'selected'}`}
            onClick={() => {
              setPageSize('10');
              setCurrentPage(1);
            }}
          >
            10
          </NakedButton>
          <p>|</p>
          <NakedButton
            disabled={pageSize === '30'}
            className={`${pageSize === '30' && 'selected'}`}
            onClick={() => {
              setPageSize('30');
              setCurrentPage(1);
            }}
          >
            30
          </NakedButton>
          <p>|</p>
          <NakedButton
            disabled={pageSize === 'all'}
            className={`${pageSize === 'all' && 'selected'}`}
            onClick={() => {
              setPageSize('all');
              setCurrentPage(1);
            }}
          >
            All
          </NakedButton>
        </div>
        <Table
          className={`${pageSize === 'all' && 'no-pagination'}`}
          responsive
          data={paginatedQuestions}
          sortType="inline"
          borderless
          borderlessColumn
          initialSort={{ field: 'number', ascending: true }}
          sortFn={(records, sortState) => {
            const sorted = questionsSortFunction(data, sortState);
            if (pageSize === 'all') {
              setSortedQuestions(sorted);
              return sorted;
            }
            const paginated = paginate(sorted, pageSize, currentPage);
            setSortedQuestions(paginated);
            // setPaginatedQuestions(paginated);
            return paginated;
          }}
          rowClassNameFunc={(row) => `table-row ${row.section.toLowerCase().replaceAll(' ', '-')} module-${row.module}`}
        >
          <Column field="number" title="Question" rowHeader sortable />
          <Column field="section" title="Section" />
          <Column field="correct" title="Correct Answer" component={CorrectAnswerContent} />
          <Column field="provided" title="Your Answer" sortable component={YourAnswerContent} />
          <Column field="actions" title="Actions" component={ReviewButton(onClick)} />
        </Table>
        {pageSize !== 'all' && (
          <div className="table-pager-wrapper" style={{ paddingLeft: pagerPadding }}>
            <Pager current={currentPage} max={getPagerMax()} responsive delta={1} onPageChange={onPageChange} />
          </div>
        )}
      </div>
    </>
  );
};

export default QuestionsTable;
