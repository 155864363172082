import { Breadcrumbs, Crumb } from '@cb/apricot-react-breadcrumbs';
import { useHistory } from 'react-router-dom';
import routes from '../../../../../routes';
import './styles/index.scss';

interface IBreadcrumbsNav {
  test: string;
  date: string;
}

/**
 * Component to render navigational breadcrumbs for MyPractice.
 */
const BreadcrumbsNav: React.FC<IBreadcrumbsNav> = ({ test, date }) => {
  const history = useHistory();

  return (
    <div id="breadcrumbs" className="cb-gray5-bg">
      <Breadcrumbs pageTitle="">
        {/* TODO: Drupal here for title? */}
        <Crumb title="My Tests" onClick={() => history.push(routes.dashboard)} />
        <Crumb title={`${test} - ${date}`} />
      </Breadcrumbs>
    </div>
  );
};

export default BreadcrumbsNav;
