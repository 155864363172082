import { ICustomField } from '@cb/apricot-react-table';
import { IQuestionTableRow } from '../../types';

/**
 * Component to render the Your Answer column of the QuestionsTable component.
 * Sets color of answer to match omitted, correct, and incorrect answers.
 */
const YourAnswerContent: ICustomField<IQuestionTableRow> = ({ value, row }) => {
  if (!row.provided) {
    return <p className="cb-red1-color">Omitted</p>;
  }

  if (row.isCorrect) {
    return <p className="correct">{`${value}; Correct`}</p>;
  }

  return <p className="cb-red1-color">{`${value}; Incorrect`}</p>;
};

export default YourAnswerContent;
