import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Spinner } from '@cb/apricot-react-spinner';
import { CircularButton, NakedButton } from '@cb/apricot-react-button';
import { HtmlMessage } from '@satreg/drupal';
import ROUTES from '../../../../../routes';
import { scoresContext } from '../../../../../contexts/api/ScoresProvider';
import { IScoreEssayRes } from '../../../../../types/scores';
import Header from './header';
import { PaneFocus } from '../expandCollapse/types';
import BottomLeftControl from '../expandCollapse/bottomLeftControl';
import TopRightControl from '../expandCollapse/topRightControl';
import SidebarPane from './sidebarPane';
import MobileSidebar from './mobileSidebar';
import './styles/index.scss';

const EssayDetails: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();

  const { isLoaded, scores } = useContext(scoresContext);
  const [score, setScore] = useState<IScoreEssayRes>();
  const [paneFocus, setPaneFocus] = useState<PaneFocus>('focusLeft');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [showPrompt, setShowPrompt] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const getTitle = () => {
    if (score) {
      switch (score.asmtEventId) {
        case 1000015:
          return 'My Practice Essay 1';
        case 1000016:
          return 'My Practice Essay 2';
        case 1000017:
          return 'My Practice Essay 3';
        default:
          return '';
      }
    }

    return '';
  };

  const getStartDate = () => {
    if (score) {
      return new Date(score.asmtSubmissionStartTime).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });
    }

    return '';
  };

  const getLeftSizeControl = () => {
    switch (paneFocus) {
      case 'focusLeft':
        return <BottomLeftControl paneFocus="equalFocus" setPaneFocus={setPaneFocus} />;
      case 'equalFocus':
        return <TopRightControl paneFocus="focusLeft" setPaneFocus={setPaneFocus} />;
      case 'focusRight':
        return <TopRightControl paneFocus="equalFocus" setPaneFocus={setPaneFocus} />;
      default:
        return null;
    }
  };

  const getLeftSize = () => {
    switch (paneFocus) {
      case 'focusLeft':
        return '6';
      case 'equalFocus':
        return '5';
      case 'focusRight':
        return '4';
      default:
        return '';
    }
  };

  // if no query parameter, send user back to dashboard
  useEffect(() => {
    if (!search) history.push(ROUTES.dashboard);
  }, [search, history]);

  useEffect(() => {
    if (isLoaded) {
      scores.forEach((s) => {
        if (s.rosterEntryId === search.substring(1)) {
          setScore(s as IScoreEssayRes);
        }
      });
    }
  }, [search, isLoaded, scores]);

  useEffect(() => {
    if (score) {
      document.title = `MyPractice - Essay - ${score.asmtEventTitle}`;
    }
  }, [score]);

  useEffect(() => {
    if (showPrompt) {
      const essayPromptEl = document.getElementsByClassName('essay-prompt')[0] as HTMLDivElement;
      essayPromptEl.focus();
    }
  }, [showPrompt]);

  // add a resize event listener with a callback function to update the windowWidth state.
  useEffect(() => {
    window.scrollTo({ top: 0 });

    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  if (!score) return <Spinner />;

  return (
    <div id="essay-details">
      <Header essayTitle={getTitle()} />
      <div className={`essay-main-content container${sidebarOpen ? ' sidebar-open' : ''}`}>
        <div className="row">
          <section className={`essay-response-pane col-xs-12 col-sm-${getLeftSize()} offset-md-1`}>
            <div className="essay-response-content">
              <h2 className="cb-margin-bottom-8">{getTitle()}</h2>
              <p className="cb-margin-top-24">{getStartDate()}</p>
              <div className="essay-prompt" tabIndex={-1}>
                {showPrompt && (
                  <HtmlMessage className="cb-margin-top-8 cb-margin-bottom-16" message={score.essay[0].stem} />
                )}
                {showPrompt ? (
                  <NakedButton onClick={() => setShowPrompt(!showPrompt)}>
                    <span className="show-prompt-text">Hide Essay Prompt</span>
                    <span className="cb-glyph cb-minus" />
                  </NakedButton>
                ) : (
                  <NakedButton onClick={() => setShowPrompt(!showPrompt)}>
                    <span className="show-prompt-text">Show Essay Prompt</span>
                    <span className="cb-glyph cb-plus" />
                  </NakedButton>
                )}
              </div>
              <div className="response cb-margin-top-32 cb-padding-bottom-8">
                <h3>{getTitle().slice(3)} Response</h3>
                <HtmlMessage className="cb-margin-top-32" message={score.essay[0].response} />
              </div>
            </div>
            {windowWidth < 768 && (
              <CircularButton
                className="sidebar-button"
                label="Open sidebar"
                icon="practice-test"
                onClick={() => {
                  setSidebarOpen(true);
                }}
              />
            )}
            {windowWidth >= 768 ? (
              <div className="expand-collapse">{getLeftSizeControl()}</div>
            ) : (
              <MobileSidebar open={sidebarOpen} setOpen={setSidebarOpen} score={score} />
            )}
          </section>
          {windowWidth >= 768 && <SidebarPane paneFocus={paneFocus} setPaneFocus={setPaneFocus} score={score} />}
        </div>
      </div>
    </div>
  );
};

export default EssayDetails;
