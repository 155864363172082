import { IScoreAssessmentRes } from '../../../../../types/scores';
import { KsdCode, Score } from '../../../../ksdDisplay/types';
import { orderMathKsd, orderReadingKsd } from '../../../../ksdDisplay/utils';
import { IQuestionTallies, IDetailsScoreData, IScoreDetails, IScoreBreadcrumb, ITest, KSDInfo } from '../types';

/**
 * Takes score data from microservice and formats it to be consumed by the details page.
 * @param score
 */
const formatScoreData: (score: IScoreAssessmentRes) => IDetailsScoreData = (score: IScoreAssessmentRes) => {
  const {
    asmtId,
    asmtEventId,
    rosterEntryId,
    asmtFamilyCd,
    channelSource,
    khanAcademyLink,
    questionBankData,
    asmtSubmissionStartTime,
    testId,
  } = score;

  // format score date to users local time.
  const startDate = new Date(score.asmtSubmissionStartTime).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  const breadcrumb: IScoreBreadcrumb = {
    test: score.displayTitle || '',
    date: startDate,
  };

  const header = {
    title: score.displayTitle || '',
  };

  // Used to display details title component
  const scoreDetails: IScoreDetails = {
    test: score.displayTitle || '',
    date: startDate,
  };

  // used to generate tabs for tableNav component
  const tableNavLabelArr: string[] = [];
  // TODO: Use drupal to render "All Questions" tab here?
  tableNavLabelArr.push('All Questions');
  score.sectionScores.forEach((sectionScore) => {
    tableNavLabelArr.push(sectionScore.tierName);
  });

  // used to render questionTallies component
  const questionTallies: IQuestionTallies[] = [];
  questionTallies.push({
    totalQuestions: score.totalScore.totalQuestions,
    correctAnswers: score.totalScore.correctAnswers,
    incorrectAnswers: score.totalScore.incorrectAnswers,
  });
  score.sectionScores.forEach((sectionScore) => {
    questionTallies.push({
      totalQuestions: sectionScore.totalQuestions,
      correctAnswers: sectionScore.correctAnswers,
      incorrectAnswers: sectionScore.incorrectAnswers,
    });
  });

  const test: ITest = {
    title: score.displayTitle || '',
    number: score.asmtFamilyCd,
  };

  const readingWritingKsdInfo: KSDInfo[] = [];
  const mathKsdInfo: KSDInfo[] = [];

  if (questionBankData) {
    questionBankData.domainScores.forEach((domainScore) => {
      if (domainScore.anchorScoreTableId === 'theta_table_reading') {
        orderReadingKsd(domainScore.scoreBands).forEach((scoreBand) => {
          readingWritingKsdInfo.push({
            ksdCode: scoreBand.primaryClassCd as KsdCode,
            score: parseInt(scoreBand.scoreBandCd, 10) as Score,
          });
        });
      }
      if (domainScore.anchorScoreTableId === 'theta_table_math') {
        orderMathKsd(domainScore.scoreBands).forEach((scoreBand) => {
          mathKsdInfo.push({
            ksdCode: scoreBand.primaryClassCd as KsdCode,
            score: parseInt(scoreBand.scoreBandCd, 10) as Score,
          });
        });
      }
    });
  }

  return {
    asmtId,
    rosterEntryId,
    asmtEventId: asmtEventId.toString(),
    asmtFamilyCd,
    channelSource,
    startDate,
    asmtSubmissionStartTime,
    khanAcademyLink,
    questionBankData,
    breadcrumb,
    header,
    scoreDetails,
    tableNavLabelArr,
    questionTallies,
    test,
    readingWritingKsdInfo,
    mathKsdInfo,
    testId,
  };
};

export default formatScoreData;
