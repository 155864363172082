import { Icon } from '@cb/apricot-react-icon';
import { Popover } from '@cb/apricot-react-popover';
import KsdDisplay from '../../../../ksdDisplay';
import { ProgramCode } from '../../../../ksdDisplay/types';
import { KSDInfo } from '../types';
import './styles/index.scss';
import { useFunctionalContentItem } from '@satui/drupal-profile';
import { DETAILS_KEYS } from '../../../../drupal';
import { HtmlMessage } from '@satreg/drupal';

interface KnowledgeAndSkills {
  asmtId: number;
  readingWritingKsdInfo: KSDInfo[];
  mathKsdInfo: KSDInfo[];
  tab: number;
  testId: string;
}

const KnowledgeAndSkills: React.FC<KnowledgeAndSkills> = ({
  asmtId,
  readingWritingKsdInfo,
  mathKsdInfo,
  tab,
  testId,
}) => {
  const { content } = useFunctionalContentItem(DETAILS_KEYS.KSD_POPOVER);

  if (!content) return null;

  return (
    <div id="knowledge-and-skills" className="cb-margin-top-48">
      <div className="row">
        <div className="col-xs-12 display-flex">
          <h2>Knowledge and Skills</h2>
          <span className="info">
            <Icon id="ksd-info-icon" name="info-circle" size="16" />
            <p>New!</p>
          </span>
          <Popover trigger="ksd-info-icon" placement="top" popoverTitle=" " closeButton>
            <HtmlMessage message={content.overview} />
          </Popover>
        </div>
        <div className="col-xs-12 cb-margin-bottom-32">
          {tab === 0 && <p>View your performance across the 8 content domains measured on the SAT.</p>}
          {tab === 1 && (
            <p>View your performance across the 4 Reading and Writing content domains measured on the SAT.</p>
          )}
          {tab === 2 && <p>View your performance across the 4 Math content domains measured on the SAT.</p>}
        </div>
        {tab !== 2 && (
          <>
            <div className="section col-xs-12">
              <h3>Reading and Writing</h3>
            </div>
            {readingWritingKsdInfo.map((ksdInfo) => {
              return (
                <div className="col-xs-12 col-sm-6">
                  <KsdDisplay
                    description
                    ksdCode={ksdInfo.ksdCode}
                    programCode={asmtId as ProgramCode}
                    score={ksdInfo.score}
                    testId={testId}
                  />
                </div>
              );
            })}
          </>
        )}
        {tab !== 1 && (
          <>
            <div className={`section col-xs-12 ${tab === 0 && 'cb-margin-top-48'}`}>
              <h3>Math</h3>
            </div>
            {mathKsdInfo.map((ksdInfo) => {
              return (
                <div className="col-xs-12 col-sm-6">
                  <KsdDisplay
                    description
                    ksdCode={ksdInfo.ksdCode}
                    programCode={asmtId as ProgramCode}
                    score={ksdInfo.score}
                    testId={testId}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default KnowledgeAndSkills;
