import Cookies from 'universal-cookie';

const cookies = new Cookies();

/**
 * @returns http headers for temp creds api requests.
 */
export const getTempHeaders = () => {
  return {
    'X-Cb-Catapult-Authentication-Token': 'tempCreds',
    'X-Cb-Catapult-Authorization-Token': cookies.get('__Host-tempJwtToken') as string,
  };
};

/**
 * @returns http headers for Catapult requests
 */
export const getCatapultHeaders = () => {
  return {
    'X-Cb-Catapult-Authentication-Token': window.cb.core.iam.getAuthenticationToken(),
    'X-Cb-Catapult-Authorization-Token': window.cb.core.iam.getAuthorizationToken(),
  };
};
