import { ICustomHeader } from '@cb/apricot-react-table';
import { IQbankTableData } from '../../../../../../../../contexts/questionbank/api/types';

const CheckedHeader: ICustomHeader<IQbankTableData> = () => (
  <>
    <span className="sr-only">Select Question</span>✓
  </>
);

export default CheckedHeader;
