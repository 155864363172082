/* eslint-disable import/prefer-default-export */
import { IScoreCardSummary } from '../dashboard/types';

export const formatFileName = (title: string, asmtSubmissionStartTime: string, scoreCardSummary: IScoreCardSummary) => {
  const firstLetter = scoreCardSummary.firstNm ? scoreCardSummary.firstNm[0] : '';
  const lastNm = scoreCardSummary.lastNm?.toUpperCase() ?? '';
  const date = new Date(asmtSubmissionStartTime)
    .toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    })
    .replaceAll('/', '');
  const shortenedTitle = title.includes('PSAT/NMSQT and PSAT 10')
    ? `PSAT_NMSQT_10_${title.split(' ').slice(-2).join('_').toUpperCase()}` // adds the practice test number back to the title
    : title.toUpperCase();

  return `${firstLetter + lastNm}_${shortenedTitle}_${date}.pdf`.replaceAll(' ', '_');
};
