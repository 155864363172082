import { useContext } from 'react';
import { Button } from '@cb/apricot-react-button';
import { qbankQuestionDataContext } from '../../../../../../../contexts/questionbank/api/QbankQuestionDataProvider';
import { exportQuestionSetContext } from '../../../../../../../contexts/questionbank/exportQuestionSet';
import QuestionPage from './questionPage';
import PrintPDF from './printPDF';
import './styles/index.scss';
import { searchQueryContext } from '../../../../../../../contexts/questionbank/searchQuery';
import { MathmlProvider } from '@satui/test-questions';

interface IQuestionSet {
  withHeaders: boolean;
  withCorrectAnswers: boolean;
  printDisabled: boolean;
}

/**
 * Component to create, sort, and render PDF question pages for the user's chosen exported questions.
 */
const QuestionSet: React.FC<IQuestionSet> = ({ withHeaders, withCorrectAnswers, printDisabled }) => {
  const { assessment, section } = useContext(searchQueryContext);
  const { multipleQbankQuestionData } = useContext(qbankQuestionDataContext);
  const { exportData } = useContext(exportQuestionSetContext);

  /**
   * Function to create a QuestionPage component for each exported question. Sorts QuestionPages
   * to appear in the same order questions appear in question table.
   * @returns QuestionPage[]
   */
  const getQuestionPages = () => {
    const questionPages: JSX.Element[] = [];

    // for each exported question, create a corresponding QuestionPage
    multipleQbankQuestionData.forEach((question) => {
      const tableData = exportData.find((row) => row.externalId === question.externalId);

      if (tableData) {
        questionPages.push(
          <QuestionPage
            key={question.externalId}
            withHeaders={withHeaders}
            withCorrectAnswers={withCorrectAnswers}
            questionData={question}
            tableData={tableData}
          />,
        );
      }
    });

    return questionPages;
  };

  // Report user's PDF Export options to WA.
  const reportWA = () => {
    let pdfTypeSelection = '';
    if (withHeaders) {
      if (withCorrectAnswers) pdfTypeSelection = 'With correct answers and explanations';
      else pdfTypeSelection = 'No correct answers or explanations';
    } else pdfTypeSelection = 'Without answers or headers';

    const cbTrackEventData = {
      searchCriteria: {
        assessment: assessment!.value,
        test: section!.value,
      },
      pdfTypeSelection,
      totalQuestionsExported: multipleQbankQuestionData.length,
    };

    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push({
      event: 'cbTrackEvent',
      eventInfo: {
        sourceApp: 'satQuestionBank',
        eventKey: 'questions-export-generated',
        eventData: cbTrackEventData,
      },
    });
  };

  return (
    <MathmlProvider>
      <PrintPDF trigger={<Button disabled={printDisabled}>Export PDF</Button>} onBeforeGetContent={reportWA}>
        <div id="printable-question-set" className="question-view">
          {getQuestionPages()}
        </div>
      </PrintPDF>
    </MathmlProvider>
  );
};

export default QuestionSet;
