import difficultyImage from './difficulty.png';
import './index.scss';

export type Difficulty = 'Easy' | 'Medium' | 'Hard';

/**
 * An image that displays one, two, or three filled squared based on difficulty.
 * @param props
 * @constructor
 */
const DifficultyIcon: React.FC<{ difficulty: Difficulty }> = (props) => {
  const { difficulty } = props;

  // Using an inline style for the image based on code from react-cloudportal-ui. It was needed for rollup. Keeping for now.
  const difficultyImageStyle = {
    backgroundImage: `url(${difficultyImage})`,
    marginTop: '10px',
  };

  return (
    <span
      className={`tqdifficulty ${difficulty.toLowerCase()}`}
      role="img"
      aria-label={difficulty}
      style={difficultyImageStyle}
    />
  );
};

export default DifficultyIcon;
