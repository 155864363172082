import { createContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { IQbankTailoredQuestionData, IRawQbankTailoredQuestionRes, ITailoredQuestionParams } from './types';
import { getCatapultHeaders, getTempHeaders } from '../../api/utils';
import { formatTailoredQuestionData } from './utils';

interface IQbankTailoredQuestionDataContext {
  getTailoredQbankQuestionData: (params: ITailoredQuestionParams) => void;
  tailoredQbankQuestionData: IQbankTailoredQuestionData[];
  updateSelectedAnswer: (answer: string, index: number) => void;
  tailoredQbankQuestionDataLoaded: boolean;
  error: boolean;
}

const defaultState: IQbankTailoredQuestionDataContext = {
  getTailoredQbankQuestionData: () => {},
  tailoredQbankQuestionData: [],
  updateSelectedAnswer: () => {},
  tailoredQbankQuestionDataLoaded: false,
  error: false,
};

export const qbankTailoredQuestionDataContext = createContext<IQbankTailoredQuestionDataContext>(defaultState);
const { Provider } = qbankTailoredQuestionDataContext;

/**
 * Provides functions for retrieving question data for tailored questions and provides the responses.
 */
const QbankTailoredQuestionDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tailoredQbankQuestionData, setTailoredQbankQuestionData] = useState<IQbankTailoredQuestionData[]>([]);
  const [tailoredQbankQuestionDataLoaded, setTailoredQbankQuestionDataLoaded] = useState(false);
  const [error, setError] = useState(false);

  const cookies = new Cookies();

  /**
   * Makes a call go get data for Tailored questions from the API, and sets the response to state
   */
  const getTailoredQbankQuestionData = useMemo(() => {
    const getData = async (params: ITailoredQuestionParams) => {
      const tempCookie = cookies.get('__Host-tempJwtToken');
      const headers = tempCookie ? getTempHeaders() : getCatapultHeaders();

      axios
        .post(process.env.REACT_APP_SQB_GET_TAILORED_QUESTIONS_ENDPOINT!, params, { headers })
        .then(({ data }: { data: IRawQbankTailoredQuestionRes[] }) => {
          setTailoredQbankQuestionData(data.map((questionContent) => formatTailoredQuestionData(questionContent)));
          setTailoredQbankQuestionDataLoaded(true);
        })
        .catch(() => {
          setError(true);
          setTailoredQbankQuestionDataLoaded(true);
        });
    };
    return getData;
  }, []);

  const updateSelectedAnswer = (answer: string, index: number) => {
    const updatedQuestions = [...tailoredQbankQuestionData];
    const updatedQuestion = updatedQuestions[index];
    updatedQuestion.selectedAnswer = answer;
    setTailoredQbankQuestionData(updatedQuestions);
  };

  useEffect(() => {
    if (error) throw new Error('Questionbank tailored service unavailable');
  }, [error]);

  return (
    <Provider
      value={{
        getTailoredQbankQuestionData,
        tailoredQbankQuestionData,
        updateSelectedAnswer,
        tailoredQbankQuestionDataLoaded,
        error,
      }}
    >
      {children}
    </Provider>
  );
};

export default QbankTailoredQuestionDataProvider;
