import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Spinner } from '@cb/apricot-react-spinner';
import { CircularButton, NakedButton } from '@cb/apricot-react-button';
import { useFunctionalContentItems } from '@satui/drupal-profile';
import { HtmlMessage } from '@satreg/drupal';
import Header from './header';
import { scoresContext } from '../../../../../contexts/api/ScoresProvider';
import { IScoreEssayRes } from '../../../../../types/scores';
import ROUTES from '../../../../../routes';
import { ExampleEssay, ExamplePrompt } from './types';
import { getEssayNumber, getExampleScore } from './utils';
import { EXPLANATION_KEYS, PROMPT_KEYS, RESPONSE_KEYS } from '../../../../drupal';
import Response from './response';
import Explanation from './explanation';
import BottomLeftControl from '../expandCollapse/bottomLeftControl';
import TopRightControl from '../expandCollapse/topRightControl';
import { PaneFocus } from '../expandCollapse/types';
import SidebarPane from './sidebarPane';
import './styles/index.scss';
import MobileSidebar from './mobileSidebar';

const EssayExamples: React.FC = () => {
  const { search } = useLocation();
  const history = useHistory();

  const { isLoaded, scores } = useContext(scoresContext);
  const [score, setScore] = useState<IScoreEssayRes>();

  const [selectedPrompt, setSelectedPrompt] = useState<ExamplePrompt>();
  const [example, setExample] = useState<ExampleEssay>('9');
  const [paneFocus, setPaneFocus] = useState<PaneFocus>('focusLeft');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [showPrompt, setShowPrompt] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const headerRef = useRef<HTMLDivElement>(null);

  const getTitle = () => {
    if (score) {
      switch (score.asmtEventId) {
        case 1000015:
          return 'My Practice Essay 1';
        case 1000016:
          return 'My Practice Essay 2';
        case 1000017:
          return 'My Practice Essay 3';
        default:
          return '';
      }
    }

    return '';
  };

  const getLeftSizeControl = () => {
    switch (paneFocus) {
      case 'focusLeft':
        return <BottomLeftControl paneFocus="equalFocus" setPaneFocus={setPaneFocus} />;
      case 'equalFocus':
        return <TopRightControl paneFocus="focusLeft" setPaneFocus={setPaneFocus} />;
      case 'focusRight':
        return <TopRightControl paneFocus="equalFocus" setPaneFocus={setPaneFocus} />;
      default:
        return null;
    }
  };

  const getLeftSize = () => {
    switch (paneFocus) {
      case 'focusLeft':
        return '6';
      case 'equalFocus':
        return '5';
      case 'focusRight':
        return '4';
      default:
        return '';
    }
  };

  // if no query parameter, send user back to dashboard
  useEffect(() => {
    if (!search) history.push(ROUTES.dashboard);
  }, [search, history]);

  // set title, scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0 });
    document.title = 'MyPractice - Essay - Examples';
  }, []);

  useEffect(() => {
    if (isLoaded) {
      scores.forEach((s) => {
        if (s.rosterEntryId === search.substring(1)) {
          setScore(s as IScoreEssayRes);
          setSelectedPrompt(getEssayNumber((s as IScoreEssayRes).asmtEventTitle));
        }
      });
    }
  }, [search, isLoaded, scores]);

  useEffect(() => {
    if (showPrompt) {
      const instructionEls = Array.from(document.getElementsByClassName('instructions')) as HTMLDivElement[];
      instructionEls.forEach((el) => {
        el.role = 'region';
        el.ariaLabel = 'instructions';
      });

      const essayPromptEl = document.getElementsByClassName('essay-prompt')[0] as HTMLDivElement;
      essayPromptEl.focus();
    }
  }, [showPrompt, selectedPrompt]);

  // add a resize event listener with a callback function to update the windowWidth state.
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const drupalPromptContent = useFunctionalContentItems(Object.values(PROMPT_KEYS));
  const drupalResponseContent = useFunctionalContentItems(Object.values(RESPONSE_KEYS));
  const drupalExplanationContent = useFunctionalContentItems(Object.values(EXPLANATION_KEYS));

  if (!score || !selectedPrompt || !drupalPromptContent || !drupalResponseContent || !drupalExplanationContent)
    return <Spinner />;

  const {
    [PROMPT_KEYS.PROMPT_1]: prompt1Content,
    [PROMPT_KEYS.PROMPT_2]: prompt2Content,
    [PROMPT_KEYS.PROMPT_3]: prompt3Content,
  } = drupalPromptContent.drupalContent;

  const loadedDrupalResponseContent = drupalResponseContent.drupalContent;
  const loadedDruaplExplanationContent = drupalExplanationContent.drupalContent;

  return (
    <div id="essay-examples">
      <Header
        essayTitle={getTitle()}
        selectedPrompt={selectedPrompt}
        setSelectedPrompt={setSelectedPrompt}
        example={example}
        setExample={setExample}
      />
      <div className={`example-main-content container${sidebarOpen ? ' sidebar-open' : ''}`}>
        <div className="row">
          <section className={`example-response-pane col-xs-12 col-sm-${getLeftSize()} offset-md-1`}>
            <div className="example-response-content">
              <div className="header" ref={headerRef}>
                <h2>Sample Essay Prompt {selectedPrompt}</h2>
                <p className="cb-margin-top-16">{`This response scored a ${getExampleScore(selectedPrompt, example)}.`}</p>
              </div>
              <div className="essay-prompt" tabIndex={-1}>
                {showPrompt && (
                  <>
                    {selectedPrompt === '1' && (
                      <HtmlMessage className="cb-margin-top-8 cb-margin-bottom-16" message={prompt1Content.overview} />
                    )}
                    {selectedPrompt === '2' && (
                      <HtmlMessage className="cb-margin-top-8 cb-margin-bottom-16" message={prompt2Content.overview} />
                    )}
                    {selectedPrompt === '3' && (
                      <HtmlMessage className="cb-margin-top-8 cb-margin-bottom-16" message={prompt3Content.overview} />
                    )}
                  </>
                )}
                {showPrompt ? (
                  <NakedButton
                    onClick={() => {
                      setShowPrompt(!showPrompt);
                      headerRef.current?.scrollIntoView();
                    }}
                  >
                    <span className="show-prompt-text">Hide Essay Prompt</span>
                    <span className="cb-glyph cb-minus" />
                  </NakedButton>
                ) : (
                  <NakedButton onClick={() => setShowPrompt(!showPrompt)}>
                    <span className="show-prompt-text">Show Essay Prompt</span>
                    <span className="cb-glyph cb-plus" />
                  </NakedButton>
                )}
              </div>
              <div className="response cb-margin-top-32 cb-padding-bottom-8">
                <h3 className="cb-margin-bottom-32">Student Response {example}</h3>
                <Response prompt={selectedPrompt} essay={example} drupalContent={loadedDrupalResponseContent} />
              </div>
              <div className="explanation cb-margin-top-32">
                <h3 className="cb-padding-bottom-16">Score Explanation</h3>
                <Explanation prompt={selectedPrompt} essay={example} drupalContent={loadedDruaplExplanationContent} />
              </div>
            </div>
            {windowWidth < 768 && (
              <CircularButton
                className="sidebar-button"
                label="Open sidebar"
                icon="practice-test"
                onClick={() => {
                  setSidebarOpen(true);
                }}
              />
            )}
            {windowWidth >= 768 ? (
              <div className="expand-collapse">{getLeftSizeControl()}</div>
            ) : (
              <MobileSidebar open={sidebarOpen} setOpen={setSidebarOpen} score={score} />
            )}
          </section>
          {windowWidth >= 768 && <SidebarPane paneFocus={paneFocus} setPaneFocus={setPaneFocus} score={score} />}
        </div>
      </div>
    </div>
  );
};

export default EssayExamples;
