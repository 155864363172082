import { ReactComponent as TopLeft } from './expand-collapse-icons/top_l.svg';
import { PaneFocus } from './types';

const TopLeftControl: React.FC<{
  paneFocus: PaneFocus;
  setPaneFocus: React.Dispatch<React.SetStateAction<PaneFocus>>;
}> = ({ paneFocus, setPaneFocus }) => {
  return (
    <button type="button" aria-label="Expand right pane" onClick={() => setPaneFocus(paneFocus)}>
      <TopLeft />
    </button>
  );
};

export default TopLeftControl;
