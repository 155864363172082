import { DrupalFunctionalContent } from '@satui/drupal-profile';
import { HtmlMessage } from '@satreg/drupal';
import { ExampleEssay, ExamplePrompt } from '../types';
import { EXPLANATION_KEYS } from '../../../../../drupal';

const Explanation: React.FC<{
  prompt: ExamplePrompt;
  essay: ExampleEssay;
  drupalContent: Record<string, DrupalFunctionalContent>;
}> = ({ prompt, essay, drupalContent }) => {
  const {
    [EXPLANATION_KEYS.P_1_EXPLANATION_1]: p1Explanation1Content,
    [EXPLANATION_KEYS.P_1_EXPLANATION_2]: p1Explanation2Content,
    [EXPLANATION_KEYS.P_1_EXPLANATION_3]: p1Explanation3Content,
    [EXPLANATION_KEYS.P_1_EXPLANATION_4]: p1Explanation4Content,
    [EXPLANATION_KEYS.P_1_EXPLANATION_5]: p1Explanation5Content,
    [EXPLANATION_KEYS.P_1_EXPLANATION_6]: p1Explanation6Content,
    [EXPLANATION_KEYS.P_1_EXPLANATION_7]: p1Explanation7Content,
    [EXPLANATION_KEYS.P_1_EXPLANATION_8]: p1Explanation8Content,
    [EXPLANATION_KEYS.P_1_EXPLANATION_9]: p1Explanation9Content,
    [EXPLANATION_KEYS.P_2_EXPLANATION_1]: p2Explanation1Content,
    [EXPLANATION_KEYS.P_2_EXPLANATION_2]: p2Explanation2Content,
    [EXPLANATION_KEYS.P_2_EXPLANATION_3]: p2Explanation3Content,
    [EXPLANATION_KEYS.P_2_EXPLANATION_4]: p2Explanation4Content,
    [EXPLANATION_KEYS.P_2_EXPLANATION_5]: p2Explanation5Content,
    [EXPLANATION_KEYS.P_2_EXPLANATION_6]: p2Explanation6Content,
    [EXPLANATION_KEYS.P_2_EXPLANATION_7]: p2Explanation7Content,
    [EXPLANATION_KEYS.P_2_EXPLANATION_8]: p2Explanation8Content,
    [EXPLANATION_KEYS.P_2_EXPLANATION_9]: p2Explanation9Content,
    [EXPLANATION_KEYS.P_3_EXPLANATION_1]: p3Explanation1Content,
    [EXPLANATION_KEYS.P_3_EXPLANATION_2]: p3Explanation2Content,
    [EXPLANATION_KEYS.P_3_EXPLANATION_3]: p3Explanation3Content,
    [EXPLANATION_KEYS.P_3_EXPLANATION_4]: p3Explanation4Content,
    [EXPLANATION_KEYS.P_3_EXPLANATION_5]: p3Explanation5Content,
    [EXPLANATION_KEYS.P_3_EXPLANATION_6]: p3Explanation6Content,
    [EXPLANATION_KEYS.P_3_EXPLANATION_7]: p3Explanation7Content,
    [EXPLANATION_KEYS.P_3_EXPLANATION_8]: p3Explanation8Content,
    [EXPLANATION_KEYS.P_3_EXPLANATION_9]: p3Explanation9Content,
  } = drupalContent;

  if (prompt === '1') {
    if (essay === '1') return <HtmlMessage message={p1Explanation1Content.overview} />;
    if (essay === '2') return <HtmlMessage message={p1Explanation2Content.overview} />;
    if (essay === '3') return <HtmlMessage message={p1Explanation3Content.overview} />;
    if (essay === '4') return <HtmlMessage message={p1Explanation4Content.overview} />;
    if (essay === '5') return <HtmlMessage message={p1Explanation5Content.overview} />;
    if (essay === '6') return <HtmlMessage message={p1Explanation6Content.overview} />;
    if (essay === '7') return <HtmlMessage message={p1Explanation7Content.overview} />;
    if (essay === '8') return <HtmlMessage message={p1Explanation8Content.overview} />;
    if (essay === '9') return <HtmlMessage message={p1Explanation9Content.overview} />;
  }
  if (prompt === '2') {
    if (essay === '1') return <HtmlMessage message={p2Explanation1Content.overview} />;
    if (essay === '2') return <HtmlMessage message={p2Explanation2Content.overview} />;
    if (essay === '3') return <HtmlMessage message={p2Explanation3Content.overview} />;
    if (essay === '4') return <HtmlMessage message={p2Explanation4Content.overview} />;
    if (essay === '5') return <HtmlMessage message={p2Explanation5Content.overview} />;
    if (essay === '6') return <HtmlMessage message={p2Explanation6Content.overview} />;
    if (essay === '7') return <HtmlMessage message={p2Explanation7Content.overview} />;
    if (essay === '8') return <HtmlMessage message={p2Explanation8Content.overview} />;
    if (essay === '9') return <HtmlMessage message={p2Explanation9Content.overview} />;
  }
  if (prompt === '3') {
    if (essay === '1') return <HtmlMessage message={p3Explanation1Content.overview} />;
    if (essay === '2') return <HtmlMessage message={p3Explanation2Content.overview} />;
    if (essay === '3') return <HtmlMessage message={p3Explanation3Content.overview} />;
    if (essay === '4') return <HtmlMessage message={p3Explanation4Content.overview} />;
    if (essay === '5') return <HtmlMessage message={p3Explanation5Content.overview} />;
    if (essay === '6') return <HtmlMessage message={p3Explanation6Content.overview} />;
    if (essay === '7') return <HtmlMessage message={p3Explanation7Content.overview} />;
    if (essay === '8') return <HtmlMessage message={p3Explanation8Content.overview} />;
    if (essay === '9') return <HtmlMessage message={p3Explanation9Content.overview} />;
  }
  return null;
};

export default Explanation;
