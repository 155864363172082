import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import '@cb/apricot/CBBase';
import '@cb/apricot/CBGrid';
import '@cb/apricot/CBLogo';
import '@cb/apricot/CBButton';
import '@cb/apricot/CBGlyph';
import '@cbw/widgets';
import '@cb/athena-widgets-types';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { DrupalInstanceProvider } from '@satreg/drupal';
import { ErrorFallback, ServiceErrorBoundary } from '@satreg/error-handling';
import { DrupalProfileInstanceProvider } from '@satui/drupal-profile';
import { webAnalyticsContext, WebAnalyticsProvider } from '@satreg/analytics';
import CBInputMethod from '@cb/apricot/CBInputMethod';
import ROUTES from '../routes';
import Header from './globalHeader';
import TempHeader from './globalHeader/tempHeader';
import Footer from './globalFooter';
import APIEnvironmentProvider from '../contexts/api';
import Main from './pages/main';
import TempCredsLogin from './pages/tempCredsLogin';
import TempAuthProvider from '../contexts/session/TempAuthProvider';
import TempKeyperReportingProvider from '../contexts/session/TempKeyperReportingProvider';

const App: React.FC = () => {
  const cookies = new Cookies();
  const [tempLoggedIn, setTempLoggedIn] = useState(false);
  const { reportPageData } = useContext(webAnalyticsContext);

  /**
   * Checks if jwt is present in browser. If present, set the tempLoggedIn flag to true.
   */
  useEffect(() => {
    const jwt = cookies.get('__Host-tempJwtToken') as string | undefined;
    if (jwt) {
      setTempLoggedIn(true);
    } else {
      setTempLoggedIn(false);
    }
  }, [cookies]);

  /**
   * On mount - enable Apricot accessibility and start Web Analytics
   */
  useEffect(() => {
    // Enable CB Input library for Accessibility
    CBInputMethod();
    // set the initial Web Analytics data - empty pageCode forces WA to retrieve page from URL
    reportPageData({ flowCode: 'satpractice', pageCode: '' });
  }, []);

  return (
    <WebAnalyticsProvider>
      {tempLoggedIn ? <TempHeader /> : <Header />}
      <DrupalInstanceProvider drupalUrl={process.env.REACT_APP_DRUPAL_URL as string}>
        <DrupalProfileInstanceProvider drupalUrl={process.env.REACT_APP_DRUPAL_PROFILES_URL as string}>
          <ServiceErrorBoundary
            errorPage={
              <ErrorFallback
                error={new Error('An error was caught by the Error Boundary')}
                redirectRoute={ROUTES.dashboard}
              />
            }
          >
            <APIEnvironmentProvider>
              <BrowserRouter>
                <TempKeyperReportingProvider>
                  <TempAuthProvider>
                    <Switch>
                      <Route path={ROUTES.access} component={TempCredsLogin} />
                      <Route path={ROUTES.main} component={Main} />
                    </Switch>
                  </TempAuthProvider>
                </TempKeyperReportingProvider>
              </BrowserRouter>
            </APIEnvironmentProvider>
          </ServiceErrorBoundary>
        </DrupalProfileInstanceProvider>
      </DrupalInstanceProvider>
      <Footer />
    </WebAnalyticsProvider>
  );
};

export default App;
