import { useFunctionalContentItem } from '@satui/drupal-profile';
import { NOTIFICATION_KEYS } from '../drupal';
import DrupalNotification from './satuiDrupalNotification';

/**
 * Generic notification component controlled by Drupal. Content is updated through drupal, this
 * notification displays on the login page.
 */
const DrupalLoginNotification: React.FC = () => {
  const drupalContent = useFunctionalContentItem(NOTIFICATION_KEYS.LOGIN_NOTIFICATION);

  return <DrupalNotification drupalContent={drupalContent.content} />;
};

export default DrupalLoginNotification;
