import {
  psat10Descriptions,
  psat89Descriptions,
  satDescriptions,
  satLinearDescriptions,
  psat10LinearDescriptions,
  psat89LinearDescriptions,
} from '../constants';
import { KsdCode, ProgramCode, Score } from '../types';
import { IScoreBand } from '../../../contexts/questionbank/api/types';

export const getKsdDescription = (ksd: KsdCode, programCode: ProgramCode, testId: string) => {
  switch (programCode) {
    case 118:
      if (testId.includes('linear')) return satLinearDescriptions[ksd];
      return satDescriptions[ksd];
    case 119:
      if (testId.includes('linear')) return psat10LinearDescriptions[ksd];
      return psat10Descriptions[ksd];
    case 120:
      if (testId.includes('linear')) return psat89LinearDescriptions[ksd];
      return psat89Descriptions[ksd];
    default:
      return '';
  }
};

export const getDifficulty = (programCode: ProgramCode, score: Score) => {
  switch (programCode) {
    case 118:
    case 99: {
      switch (score) {
        case 0:
        case 1:
        case 2:
        case 3:
          return 'Easy';
        case 4:
        case 5:
          return 'Medium';
        case 6:
        case 7:
          return 'Hard';
        default:
          return '';
      }
    }
    case 119:
    case 100: {
      switch (score) {
        case 0:
        case 1:
        case 2:
          return 'Easy';
        case 3:
        case 4:
          return 'Medium';
        case 5:
        case 6:
        case 7:
          return 'Hard';
        default:
          return '';
      }
    }
    case 120:
    case 102: {
      switch (score) {
        case 0:
        case 1:
          return 'Easy';
        case 2:
        case 3:
          return 'Medium';
        case 4:
        case 5:
        case 6:
        case 7:
          return 'Hard';
        default:
          return '';
      }
    }
    default:
      return '';
  }
};

export const orderReadingKsd = (domains: IScoreBand[]): IScoreBand[] => {
  const orderedDomains: IScoreBand[] = new Array(4);
  domains?.forEach((domain: IScoreBand) => {
    switch (domain.primaryClassCd) {
      case 'INI':
        orderedDomains[0] = domain;
        break;
      case 'EOI':
        orderedDomains[1] = domain;
        break;
      case 'CAS':
        orderedDomains[2] = domain;
        break;
      case 'SEC':
        orderedDomains[3] = domain;
        break;
      default:
    }
  });

  return orderedDomains;
};

export const orderMathKsd = (domains: IScoreBand[]): IScoreBand[] => {
  const orderedDomains: IScoreBand[] = new Array(4);
  domains?.forEach((domain: IScoreBand) => {
    switch (domain.primaryClassCd) {
      case 'H':
        orderedDomains[0] = domain;
        break;
      case 'P':
        orderedDomains[1] = domain;
        break;
      case 'Q':
        orderedDomains[2] = domain;
        break;
      case 'S':
        orderedDomains[3] = domain;
        break;
      default:
    }
  });

  return orderedDomains;
};
