import { ReactComponent as BottomRight } from './expand-collapse-icons/bottom_r.svg';
import { PaneFocus } from './types';

const BottomRightControl: React.FC<{
  paneFocus: PaneFocus;
  setPaneFocus: React.Dispatch<React.SetStateAction<PaneFocus>>;
}> = ({ paneFocus, setPaneFocus }) => {
  return (
    <button type="button" aria-label="Collapse right pane" onClick={() => setPaneFocus(paneFocus)}>
      <BottomRight />
    </button>
  );
};

export default BottomRightControl;
