import { KsdCode, KsdReadingCode, KsdValue } from '../types';

export const ksdCodeValuePairs: { [key in KsdCode]: KsdValue } = {
  INI: 'Information and Ideas',
  CAS: 'Craft and Structure',
  EOI: 'Expression of Ideas',
  SEC: 'Standard English Conventions',
  H: 'Algebra',
  P: 'Advanced Math',
  Q: 'Problem-Solving and Data Analysis',
  S: 'Geometry and Trigonometry',
};

const readingWritingDescriptions: { [key in KsdReadingCode]: string } = {
  INI: '(26% of test section, 12 - 14 questions)',
  CAS: '(28% of test section, 13 - 15 questions)',
  EOI: '(20% of test section, 8 - 12 questions)',
  SEC: '(26% of test section, 11 - 15 questions)',
};

export const satDescriptions: { [key in KsdCode]: string } = {
  ...readingWritingDescriptions,
  H: '(35% of test section, 13 - 15 questions)',
  P: '(35% of test section, 13 - 15 questions)',
  Q: '(15% of test section, 5 - 7 questions)',
  S: '(15% of test section, 5 - 7 questions)',
};

export const psat10Descriptions: { [key in KsdCode]: string } = {
  ...readingWritingDescriptions,
  H: '(35% of test section, 13 - 15 questions)',
  P: '(32.5% of test section, 12 - 14 questions)',
  Q: '(20% of test section, 7 - 9 questions)',
  S: '(12.5% of test section, 4 - 6 questions)',
};

export const psat89Descriptions: { [key in KsdCode]: string } = {
  ...readingWritingDescriptions,
  H: '(42.5% of test section, 16 - 18 questions)',
  P: '(20% of test section, 7 - 9 questions)',
  Q: '(25% of test section, 9 - 11 questions)',
  S: '(12.5% of test section, 4 - 6 questions)',
};

const readingWritingLinearDescriptions: { [key in KsdReadingCode]: string } = {
  INI: '(26% of test section, 16 - 18 questions)',
  CAS: '(28% of test section, 17 - 20 questions)',
  EOI: '(19.5% of test section, 11 - 15 questions)',
  SEC: '(26.5% of test section, 15 - 20 questions)',
};

export const satLinearDescriptions: { [key in KsdCode]: string } = {
  ...readingWritingLinearDescriptions,
  H: '(33.5% of test section, 16 - 20 questions)',
  P: '(33.5% of test section, 16 - 20 questions)',
  Q: '(16.5% of test section, 7 - 11 questions)',
  S: '(16.5% of test section, 7 - 11 questions)',
};

export const psat10LinearDescriptions: { [key in KsdCode]: string } = {
  ...readingWritingLinearDescriptions,
  H: '(34.5% of test section, 16 - 21 questions)',
  P: '(32.5% of test section, 16 - 19 questions)',
  Q: '(19.5% of test section, 9 - 12 questions)',
  S: '(13% of test section, 6 - 8 questions)',
};

export const psat89LinearDescriptions: { [key in KsdCode]: string } = {
  ...readingWritingLinearDescriptions,
  H: '(42.5% of test section, 20 - 26 questions)',
  P: '(16.5% of test section, 8 - 10 questions)',
  Q: '(26% of test section, 12 - 16 questions)',
  S: '(12% of test section, 6 - 7 questions)',
};
