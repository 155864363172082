import { ILabeledValue } from '@cb/apricot-react-forms';

export const assessmentSelectValues: ILabeledValue[] = [
  { label: 'Select Assessment', value: '' },
  { label: 'SAT', value: '99' },
  { label: 'PSAT/NMSQT & PSAT 10', value: '100' },
  { label: 'PSAT 8/9', value: '102' },
];

export const sectionSelectValues: ILabeledValue[] = [
  { label: 'Select Section', value: '' },
  { label: 'Reading and Writing', value: 'reading' },
  { label: 'Math', value: 'math' },
];

export const readingWritingCheckboxValues: ILabeledValue[] = [
  { label: 'Information and Ideas', value: 'INI' },
  { label: 'Craft and Structure', value: 'CAS' },
  { label: 'Expression of Ideas', value: 'EOI' },
  { label: 'Standard English Conventions', value: 'SEC' },
];

export const mathCheckboxValues: ILabeledValue[] = [
  { label: 'Algebra', value: 'H' },
  { label: 'Advanced Math', value: 'P' },
  { label: 'Problem-Solving and Data Analysis', value: 'Q' },
  { label: 'Geometry and Trigonometry', value: 'S' },
];
