import { Environment, EnvironmentProvider } from '@satreg/api';

/**
 * Provides context to append catapult headers to API requests.
 */
const APIEnvironmentProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <EnvironmentProvider
      environment={process.env.REACT_APP_API_ENV as Environment}
      appId={process.env.REACT_APP_APP_ID}
    >
      {children}
    </EnvironmentProvider>
  );
};

export default APIEnvironmentProvider;
