import { DIGITAL_PRACTICE, MS_PRACTICE_TESTRESULTS, GET_PRACTICE_QUESTIONS, useApi } from '@satreg/api';
import { createContext, useMemo, useState } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { IQuestionRes } from '../../types/questions';
import { getCatapultHeaders, getTempHeaders } from './utils';
import { IChannelSource } from '../../types/scores';

interface IQuestionsContext {
  getQuestions: (rosterEntryId: string, asmtFamilyCd: number, channelSource: IChannelSource) => void;
  questions: IQuestionRes | undefined;
  isLoaded: boolean;
  error: boolean;
}

const defaultState: IQuestionsContext = { getQuestions: () => {}, questions: undefined, isLoaded: false, error: false };

export const questionsContext = createContext(defaultState);
const { Provider } = questionsContext;

/**
 * Calls the questions microservice and provides the response to the application.
 */
const QuestionsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const questionsApi = useApi(DIGITAL_PRACTICE, MS_PRACTICE_TESTRESULTS);
  const [questions, setQuestions] = useState<IQuestionRes>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  const cookies = new Cookies();

  /**
   * If request is successful, set questions data to state and set isLoaded to true.
   */
  const onSuccess = (res: IQuestionRes) => {
    setQuestions(res);
    setIsLoaded(true);
  };

  /**
   * If request fails, set error and isLoaded to true.
   */
  const onFailure = () => {
    setError(true);
    setIsLoaded(true);
  };

  /**
   * Call the questions microservice
   */
  const getQuestions = useMemo(() => {
    const getTestQuestions = (rosterEntryId: string, asmtFamilyCd: number, channelSource: IChannelSource) => {
      if (!questionsApi.isLoaded) return;

      const tempCookie = cookies.get('__Host-tempJwtToken');

      // if this is an institutional exam, call the get institutional questions service
      if (tempCookie || channelSource === 'I') {
        let headers;

        if (tempCookie) {
          headers = getTempHeaders();
        } else {
          headers = getCatapultHeaders();
        }

        axios
          .post(process.env.REACT_APP_QUESTIONS_ENDPOINT!, { rosterEntryId, asmtFamilyCd }, { headers })
          .then(({ data }: { data: IQuestionRes }) => {
            onSuccess(data);
            setIsLoaded(true);
          })
          .catch(() => {
            onFailure();
          });
      }
      // if this is a consumer exam, call the get questions service.
      else {
        if (questionsApi.authUnavailable) return;

        questionsApi.post(GET_PRACTICE_QUESTIONS, { rosterEntryId, asmtFamilyCd }, onSuccess, onFailure);
      }
    };
    return getTestQuestions;
  }, [questionsApi.authUnavailable, questionsApi.isLoaded]);

  return <Provider value={{ getQuestions, questions, isLoaded, error }}>{children}</Provider>;
};

export default QuestionsProvider;
