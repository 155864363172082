/**
 * Error messages to display if dap auth api returns a status code other than SUCCESS.
 */
export const ERROR_MESSAGES = {
  BAD_CREDENTIALS: 'Your user ID or registration number is wrong. Please check your sign-in ticket and try again.',
  EXPIRED_CREDENTIALS: 'Your user ID or registration number is invalid. Please ask someone at your school for help.',
  INACTIVE_CREDENTIALS: 'Your temporary credentials are not active. Please contact your school for assistance.',
  SECURITY_LOCKOUT_5:
    "You've entered the wrong info several times. Check your sign-in ticket and try again after 5 minutes.",
  SECURITY_LOCKOUT_25:
    "You've entered the wrong info several times. Check your sign-in ticket and try again after 25 minutes.",
  SECURITY_LOCKOUT:
    'This user ID and registration number are now locked. Ask your school for help or call Customer Service at 866-756-7346.',
  GENERIC:
    'There is a problem with your user ID or registration number. Please check your sign-in ticket and try again',
};
