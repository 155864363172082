import { useContext, useEffect, useState } from 'react';
import { Input, IValidationState } from '@cb/apricot-react-forms';
import { NakedButton, PrimaryButton } from '@cb/apricot-react-button';
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom';
import { Spinner } from '@satreg/spinner';
import { useFunctionalContentItems } from '@satui/drupal-profile';
import { HtmlMessage } from '@satreg/drupal';
import { Toasts, Toast } from '@cb/apricot-react-toasts';
import { tempAuthContext } from '../../../contexts/session/TempAuthProvider';
import ROUTES from '../../../routes';
import { checkIfEmail } from './utils/utils';
import './styles/index.scss';
import { TEMP_LOGIN_KEYS } from '../../drupal';
import { TEMP_AUTH_CODES } from '../../../contexts/session/constants';
import { ERROR_MESSAGES } from './constants';

/**
 * Component to render the temp creds login page, found at "/access".
 */
const TempCredsLogin: React.FC = () => {
  const [userId, setUserId] = useState('');
  const [userIdValidation, setUserIdValidation] = useState<IValidationState>();
  const [registrationNo, setRegistrationNo] = useState('');
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { getTempToken, error, setError } = useContext(tempAuthContext);
  const cookies = new Cookies();
  const history = useHistory();

  const backToLogin = () => {
    history.push(ROUTES.login);
  };

  const login = () => {
    getTempToken(userId, registrationNo);
  };

  const { drupalContent } = useFunctionalContentItems(Object.values(TEMP_LOGIN_KEYS));

  /**
   * Checks if user has inputted a valid username and password. If they
   * have, enable the login button. Also, If user inputted an email as their
   * username, show the userIdValidation message.
   */
  useEffect(() => {
    // controls if login button is enabled
    if (userId && registrationNo && !userIdValidation) setLoginDisabled(false);
    else setLoginDisabled(true);

    // controls if email error message is displayed
    if (checkIfEmail(userId)) setUserIdValidation('error');
    else setUserIdValidation(undefined);
  }, [userId, registrationNo, userIdValidation]);

  // if user is already logged in with temp creds or through Okta, direct them to the dashboard.
  useEffect(() => {
    if (cookies.get('__Host-tempJwtToken') || window.cb.core.iam.getJWTToken()) {
      history.push(ROUTES.dashboard);
    }
  }, [cookies]);

  // clear error message on load
  useEffect(() => {
    setError('');
  }, []);

  /**
   * Contains switch statement to handle status codes from the dap auth api. If
   * the status is something other than SUCCESS, display the appropriate error on the UI
   * when user tries to login.
   */
  useEffect(() => {
    if (error) {
      switch (error) {
        case TEMP_AUTH_CODES.ERROR_BAD_CREDENTIALS:
          setErrorMessage(ERROR_MESSAGES.BAD_CREDENTIALS);
          setShowWarning(true);
          break;
        case TEMP_AUTH_CODES.EXPIRED_CREDENTIALS:
          setErrorMessage(ERROR_MESSAGES.EXPIRED_CREDENTIALS);
          setShowWarning(true);
          break;
        case TEMP_AUTH_CODES.INACTIVE_CREDENTIALS:
          setErrorMessage(ERROR_MESSAGES.INACTIVE_CREDENTIALS);
          setShowWarning(true);
          break;
        case TEMP_AUTH_CODES.SECURITY_LOCKOUT_5:
          setErrorMessage(ERROR_MESSAGES.SECURITY_LOCKOUT_5);
          setShowWarning(true);
          break;
        case TEMP_AUTH_CODES.SECURITY_LOCKOUT_25:
          setErrorMessage(ERROR_MESSAGES.SECURITY_LOCKOUT_25);
          setShowWarning(true);
          break;
        case TEMP_AUTH_CODES.SECURITY_LOCKOUT:
          setErrorMessage(ERROR_MESSAGES.SECURITY_LOCKOUT);
          setShowWarning(true);
          break;
        default:
          setErrorMessage(ERROR_MESSAGES.GENERIC);
          setShowWarning(true);
      }
    } else {
      setErrorMessage('');
      setShowWarning(false);
    }
  }, [error]);

  if (!drupalContent) {
    return <Spinner />;
  }

  const {
    [TEMP_LOGIN_KEYS.TEMP_LOGIN_HEADER]: headerContent,
    [TEMP_LOGIN_KEYS.FORGOT_TEMP_CREDS]: forgetCredsContent,
  } = drupalContent;

  return (
    <div id="temp-login">
      <div className="temp-login-card">
        <div className="temp-login-header">
          <NakedButton onClick={backToLogin} className="temp-back-button" noPadding>
            <span className="cb-icon cb-left cb-margin-right-4" />
            <p>Back</p>
          </NakedButton>
        </div>
        <div className="temp-login-content">
          <span className="cb-glyph cb-glyph-avatar cb-acorn cb-blue5-color" />
          <h2>{headerContent.extDescription || ''}</h2>
          <Input
            validation={userIdValidation}
            validationMsg="It seems you are trying to login with a College Board account. Please login using the main page."
            label="User ID"
            onChange={(e) => setUserId(e.target.value)}
          />
          <Input
            className="cb-margin-top-16"
            label="Registration Number"
            onChange={(e) => setRegistrationNo(e.target.value)}
          />
          <HtmlMessage message={forgetCredsContent.overview || ''} />
          <PrimaryButton className="sign-in-button" disabled={loginDisabled} onClick={login}>
            Submit
          </PrimaryButton>
        </div>
        <Toasts>
          <Toast
            className="cb-toast-msg-no-truncate"
            type="warning"
            controlled
            message={errorMessage}
            show={showWarning}
            onDismiss={() => {
              setShowWarning(false);
            }}
          />
        </Toasts>
      </div>
    </div>
  );
};

export default TempCredsLogin;
