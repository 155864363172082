import { useContext } from 'react';
import { ICustomField } from '@cb/apricot-react-table';
import { NakedButton } from '@cb/apricot-react-button';
import { IQbankTableData } from '../../../../../../../../contexts/questionbank/api/types';
import { qbankQuestionModalContext } from '../../../../../../../../contexts/questionbank/questionModal';
import { qbankQuestionDataContext } from '../../../../../../../../contexts/questionbank/api/QbankQuestionDataProvider';

const ViewQuestionButton: ICustomField<IQbankTableData> = ({ row, rowIndex }) => {
  const { openModal, setSelectedRow, setSelectedRowIndex } = useContext(qbankQuestionModalContext);
  const { getQbankQuestionData } = useContext(qbankQuestionDataContext);

  /**
   * When button is clicked, get the data for that question and open the question modal.
   */
  const onClick = () => {
    setSelectedRow(row);
    setSelectedRowIndex(rowIndex);
    getQbankQuestionData(row.externalId);
    openModal();
  };

  return (
    <NakedButton
      id={row.questionId}
      onClick={onClick}
      aria-label={`Question ${row.questionId}`}
      className="cb-no-padding"
    >
      {row.questionId}
    </NakedButton>
  );
};

export default ViewQuestionButton;
