import React, { useState } from 'react';
import { ONE_MINUTE, ONE_SECOND } from '@satreg/time-utils';
import { useIdleTimer } from 'react-idle-timer';
import TimeoutModal from './timeoutModal';
import './styles/index.scss';

const FIFTEEN_MINUTES = ONE_MINUTE * 15;
const DEBOUNCE_VALUE = ONE_SECOND * 0.5;

interface IInstitutionalSessionTimeoutManager {
  children: React.ReactNode;
  redirectPath: string;
}

/**
 * A Manager component for handling session timeout
 * @param redirectPath
 * @param children
 */
const InstitutionalSessionTimeoutManager: React.FC<IInstitutionalSessionTimeoutManager> = ({
  children,
  redirectPath,
}) => {
  const [showModal, setShowModal] = useState(false);

  /**
   * After the user has been idle for fifteen minutes we show the modal
   */
  const handleOnIdle = () => {
    setShowModal(true);
  };

  /**
   * if another tab resets, reset this tab
   */
  const handleOnMessage = (reset: () => boolean) => {
    setShowModal(false);
    reset();
  };

  /**
   * A hook to detect if the user is inactive or not
   */
  const { reset, message } = useIdleTimer({
    timeout: FIFTEEN_MINUTES,
    onIdle: handleOnIdle,
    onMessage: () => handleOnMessage(reset),
    debounce: DEBOUNCE_VALUE,
    stopOnIdle: true,
    crossTab: true,
    leaderElection: true,
  });

  /**
   * On continue we dismiss the modal and reset the timout
   */
  const onSessionExtend = () => {
    setShowModal(false);
    // message any other open tabs to reset those tabs
    message('extending session');
    reset();
  };

  return (
    <div>
      <TimeoutModal redirectPath={redirectPath} onContinue={onSessionExtend} isVisible={showModal} />
      {children}
    </div>
  );
};

export default InstitutionalSessionTimeoutManager;
