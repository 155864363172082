import { useEffect, useRef } from 'react';
import CBHorizontalCarousel from '@cb/apricot/CBHorizontalCarousel';
import cn from 'classnames';

export const carouselVariants = ['light', 'dark', 'menu', 'menu-condensed'] as const;

export interface IHorizontalCarousel {
  /** horizontal carousel Id */
  carouselId?: string;
  /** aria label value */
  ariaLabel?: string;
  /** carousel variant */
  variant?: (typeof carouselVariants)[number];
  /** background color class for navigation if variant is used */
  bgClass?: string;
  /** color class for navigation glyph if variant is used */
  arrowClass?: string;
  /** distance (px) the carousel will scroll (left|right) */
  scrollDistance?: number;
  /** class name for nods/cards with focusable items  */
  itemClass?: string;
  /** class name for selected items  */
  selectedClass?: string;
  className?: string;
  children?: React.ReactNode;
}

/**
 * Generic horizontal carousel component.
 */
const HorizontalCarousel: React.FC<IHorizontalCarousel> = ({
  carouselId,
  children,
  ariaLabel,
  variant,
  bgClass,
  arrowClass,
  scrollDistance = 150,
  selectedClass,
  itemClass,
  className,
}) => {
  const carouselClassName = cn(`cb-horizontal-carousel`, variant && `cb-horizontal-carousel-${variant}`, className);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      CBHorizontalCarousel({
        elem: ref.current,
        markup: false,
        scrollDistance,
        selectedClass,
        itemClass,
      });
    }, 200);
  }, []);
  return (
    <div id={carouselId} role="region" aria-label={ariaLabel} className={carouselClassName} ref={ref}>
      <div className="cb-horizontal-nav cb-horizontal-nav-left">
        <a
          aria-label="scroll content to left"
          href="/#"
          tabIndex={-1}
          aria-disabled="true"
          className={cn(bgClass && bgClass, arrowClass && arrowClass)}
        >
          <span
            className={cn(`cb-icon`, !variant && `cb-left`, variant && `cb-carousel-backward`)}
            aria-hidden="true"
          />
        </a>
      </div>

      <div className="cb-horizontal-carousel-nav">
        <div className="cb-horizontal-carousel-content">{children}</div>
      </div>

      <div className="cb-horizontal-nav cb-horizontal-nav-right">
        <a
          aria-label="scroll content to right"
          href="/#"
          tabIndex={-1}
          aria-disabled="true"
          className={cn(bgClass, arrowClass)}
        >
          <span
            className={cn(`cb-icon`, !variant && `cb-right`, variant && `cb-carousel-forward`)}
            aria-hidden="true"
          />
        </a>
      </div>
    </div>
  );
};

export default HorizontalCarousel;
