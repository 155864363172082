import { orderBy } from 'lodash';
import { ISortState } from '@cb/apricot-react-table';
import { IQuestionTableRow } from '../../types';
import { PageSize } from '..';

/** Custom Questions Sort function */
export const questionsSortFunction: (records: IQuestionTableRow[], sortState: ISortState) => IQuestionTableRow[] = (
  records,
  sortState,
) => {
  // sort questions into sections
  const readingArr = records.filter((record) => record.section === 'Reading and Writing');
  const mathArr = records.filter((record) => record.section === 'Math');

  // sort questions into modules
  const readingModuleOneArr: IQuestionTableRow[] = [];
  const readingModuleTwoArr: IQuestionTableRow[] = [];
  let currentModule = 1;
  let currentQuestion = 1;

  // sort reading questions into modules
  readingArr.forEach((record) => {
    // if current questions number is less than currentQuestion, start pushing to moduleTwo array.
    if (record.number < currentQuestion) {
      currentModule += 1;
    }

    // set current question
    currentQuestion = record.number;

    if (currentModule === 1) {
      readingModuleOneArr.push(record);
    }
    if (currentModule === 2) {
      readingModuleTwoArr.push(record);
    }
  });

  const mathModuleOneArr: IQuestionTableRow[] = [];
  const mathModuleTwoArr: IQuestionTableRow[] = [];
  currentModule = 1;
  currentQuestion = 1;

  // sort math questions into modules
  mathArr.forEach((record) => {
    // if current questions number is less than currentQuestion, start pushing to moduleTwo array.
    if (record.number < currentQuestion) {
      currentModule += 1;
    }

    // set current question
    currentQuestion = record.number;

    if (currentModule === 1) {
      mathModuleOneArr.push(record);
    }
    if (currentModule === 2) {
      mathModuleTwoArr.push(record);
    }
  });

  // sort by correct, incorrect, and omitted questions
  if (sortState.field === 'provided') {
    const correctReadingModuleOneArr = orderBy(
      readingModuleOneArr.filter((record) => record.isCorrect),
      ['number'],
      ['asc'],
    );
    const incorrectReadingModuleOneArr = orderBy(
      readingModuleOneArr.filter((record) => !record.isCorrect && record.provided),
      ['number'],
      ['asc'],
    );
    const omittedReadingModuleOneArr = orderBy(
      readingModuleOneArr.filter((record) => !record.provided),
      ['number'],
      ['asc'],
    );
    const correctReadingModuleTwoArr = orderBy(
      readingModuleTwoArr.filter((record) => record.isCorrect),
      ['number'],
      ['asc'],
    );
    const incorrectReadingModuleTwoArr = orderBy(
      readingModuleTwoArr.filter((record) => !record.isCorrect && record.provided),
      ['number'],
      ['asc'],
    );
    const omittedReadingModuleTwoArr = orderBy(
      readingModuleTwoArr.filter((record) => !record.provided),
      ['number'],
      ['asc'],
    );
    const correctMathModuleOneArr = orderBy(
      mathModuleOneArr.filter((record) => record.isCorrect),
      ['number'],
      ['asc'],
    );
    const incorrectMathModuleOneArr = orderBy(
      mathModuleOneArr.filter((record) => !record.isCorrect && record.provided),
      ['number'],
      ['asc'],
    );
    const omittedMathModuleOneArr = orderBy(
      mathModuleOneArr.filter((record) => !record.provided),
      ['number'],
      ['asc'],
    );
    const correctMathModuleTwoArr = orderBy(
      mathModuleTwoArr.filter((record) => record.isCorrect),
      ['number'],
      ['asc'],
    );
    const incorrectMathModuleTwoArr = orderBy(
      mathModuleTwoArr.filter((record) => !record.isCorrect && record.provided),
      ['number'],
      ['asc'],
    );
    const omittedMathModuleTwoArr = orderBy(
      mathModuleTwoArr.filter((record) => !record.provided),
      ['number'],
      ['asc'],
    );

    // if user sorts by answer choice, order questions by correct, incorrect, and omitted within each module
    return sortState.ascending
      ? [
          ...correctReadingModuleOneArr,
          ...incorrectReadingModuleOneArr,
          ...omittedReadingModuleOneArr,
          ...correctReadingModuleTwoArr,
          ...incorrectReadingModuleTwoArr,
          ...omittedReadingModuleTwoArr,
          ...correctMathModuleOneArr,
          ...incorrectMathModuleOneArr,
          ...omittedMathModuleOneArr,
          ...correctMathModuleTwoArr,
          ...incorrectMathModuleTwoArr,
          ...omittedMathModuleTwoArr,
        ]
      : [
          ...omittedReadingModuleOneArr,
          ...incorrectReadingModuleOneArr,
          ...correctReadingModuleOneArr,
          ...omittedReadingModuleTwoArr,
          ...incorrectReadingModuleTwoArr,
          ...correctReadingModuleTwoArr,
          ...omittedMathModuleOneArr,
          ...incorrectMathModuleOneArr,
          ...correctMathModuleOneArr,
          ...omittedMathModuleTwoArr,
          ...incorrectMathModuleTwoArr,
          ...correctMathModuleTwoArr,
        ];
  }

  // if sorting by item number (default) return in order of question number within each module
  return sortState.ascending
    ? [
        ...orderBy(readingModuleOneArr, ['number'], ['asc']),
        ...orderBy(readingModuleTwoArr, ['number'], ['asc']),
        ...orderBy(mathModuleOneArr, ['number'], ['asc']),
        ...orderBy(mathModuleTwoArr, ['number'], ['asc']),
      ]
    : [
        ...orderBy(mathModuleTwoArr, ['number'], ['desc']),
        ...orderBy(mathModuleOneArr, ['number'], ['desc']),
        ...orderBy(readingModuleTwoArr, ['number'], ['desc']),
        ...orderBy(readingModuleOneArr, ['number'], ['desc']),
      ];
};

export const paginate = (data: IQuestionTableRow[], pageSize: PageSize, currentPage: number) => {
  if (pageSize === 'all') return data;

  const pageSizeNum = parseInt(pageSize, 10);
  const paginated = data.slice(pageSizeNum * (currentPage - 1), pageSizeNum * currentPage);
  return paginated;
};
