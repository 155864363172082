import { CircularButton } from '@cb/apricot-react-button';
import { Popover } from '@cb/apricot-react-popover';

interface ITablePopoverHeader {
  title: string;
  content?: string;
  customComponent?: JSX.Element;
}

const TablePopoverHeader: React.FC<ITablePopoverHeader> = ({ title, content, customComponent }) => {
  const getPopoverContent = () => {
    if (content) return content;
    if (customComponent) return customComponent;
    return null;
  };

  return (
    <div className="table-column-header display-flex cb-roboto-bold">
      <p className="table-header-title cb-margin-right-8">{title}</p>
      <CircularButton
        icon="question-circle"
        label={`${title} column tooltip`}
        id={`showColumnPopover_${title}`}
        className="cb-roboto-bold tooltip-button"
        small
      />
      <Popover placement="top" trigger={`showColumnPopover_${title}`}>
        {getPopoverContent()}
      </Popover>
    </div>
  );
};

export default TablePopoverHeader;
