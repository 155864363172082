import { Route, Switch } from 'react-router-dom';
import ScoresProvider from '../../../contexts/api/ScoresProvider';
import TestingRulesStatusProvider from '../../../contexts/api/TestingRulesStatusProvider';
import AppLoginManager from '../../../contexts/session/index';
import DrupalNotification from '../../drupalNotification/DrupalAppNotification';
import Dashboard from './dashboard';
import Details from './details';
import ROUTES from '../../../routes';
import QuestionsProvider from '../../../contexts/api/QuestionsProvider';
import TempCredsNotification from './tempCredsNotification';
import Questionbank from './questionbank';
import Essay from './essay';
import MetricLoggingProvider from '../../../contexts/api/MetricLoggingProvider';

/**
 * Component to render main application content. Does not
 * render the TempCredsLogin component.
 */
const Main: React.FC = () => {
  return (
    <AppLoginManager>
      <main id="main">
        <DrupalNotification />
        <TempCredsNotification />
        <ScoresProvider>
          <TestingRulesStatusProvider>
            <MetricLoggingProvider>
              <Switch>
                <Route path={ROUTES.dashboard} component={Dashboard} />
                <Route path={ROUTES.essay} component={Essay} />
                <Route path={ROUTES.questionbank} component={Questionbank} />
                <QuestionsProvider>
                  <Route path={ROUTES.details} component={Details} />
                </QuestionsProvider>
              </Switch>
            </MetricLoggingProvider>
          </TestingRulesStatusProvider>
        </ScoresProvider>
      </main>
    </AppLoginManager>
  );
};

export default Main;
