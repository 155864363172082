/**
 * Possible expected status codes from DAP Auth api response.
 */
export const TEMP_AUTH_CODES = {
  SUCCESS: 'SUCCESS',
  ERROR_BAD_CREDENTIALS: 'ERROR_BAD_CREDENTIALS',
  EXPIRED_CREDENTIALS: 'EXPIRED CREDENTIALS',
  INACTIVE_CREDENTIALS: 'INACTIVE CREDENTIALS',
  SECURITY_LOCKOUT_5: 'SECURITY_LOCKOUT:5',
  SECURITY_LOCKOUT_25: 'SECURITY_LOCKOUT:25',
  SECURITY_LOCKOUT: 'SECURITY_LOCKOUT',
};

/**
 * Codes to report to Keyper that correspond to DAP Auth api response codes
 */
export const TEMP_AUTH_KEYPER_CODES = {
  SUCCESS: 'login-success',
  BAD_CREDENTIALS: 'login-bad-credentials',
  EXPIRED: 'login-expired',
  INACTIVE: 'login-inactive',
};
