import React, { useEffect, useState } from 'react';
import { Modal } from '@satreg/modal';
import { convertSecondsToMinutesSecondsReadableFormat, ONE_MINUTE, ONE_SECOND } from '@satreg/time-utils';
import Cookies from 'universal-cookie';

interface TimeoutModal {
  isVisible: boolean;
  onContinue: () => void;
  redirectPath: string;
}

const THREE_MINUTES = ONE_MINUTE * 3;

/**
 * An icon to display along side the modal header to indicate that this is an important notification
 * @constructor
 */
function GlyphIcon() {
  return (
    <div className="col-xl-1 col-lg-1 col-xs-1 col-sm-1 glyph-icon">
      <span className="cb-glyph cb-exclamation-circle" aria-hidden="true" />
    </div>
  );
}

/**
 * A simple timeout modal that displays the remaining time on a countdown and gives the option to either extend their session or log out.
 * @param isVisible
 * @param onContinue
 * @param redirectPath
 * @constructor
 */
const TimeoutModal: React.FC<TimeoutModal> = ({ isVisible, onContinue, redirectPath }) => {
  const [timeLeft, setTimeLeft] = useState(THREE_MINUTES);

  const cookies = new Cookies();

  /**
   * Function to force a logout
   */
  const onLogout = () => {
    // set the expiration to a date in the past to remove the cookie
    cookies.set('__Host-tempJwtToken', '', {
      path: '/',
      sameSite: 'strict',
      secure: true,
      expires: new Date('1/1/2000'),
    });

    window.location.replace(redirectPath);
  };

  /**
   * A useEffect to update the timer and handle on timer expiration
   */
  useEffect(() => {
    if (!isVisible) {
      setTimeLeft(THREE_MINUTES);
      return;
    }

    if (!timeLeft) {
      onLogout();
      return;
    }

    const id = setTimeout(() => {
      setTimeLeft((previousTime) => previousTime - ONE_SECOND);
    }, ONE_SECOND);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(id);
  }, [timeLeft, isVisible]);

  return (
    <div className="session-timeout">
      <Modal
        visible={isVisible}
        confirmButtonText="Continue"
        cancelButtonText="Log Out"
        onConfirm={onContinue}
        onCancel={onLogout}
        icon={GlyphIcon()}
      >
        <div className="timer-content">
          <span className="sr-only">Warning</span>
          Your Session will expire in{' '}
          <span className="remaining-time">{convertSecondsToMinutesSecondsReadableFormat(timeLeft / 1000)}</span>
        </div>
      </Modal>
    </div>
  );
};

export default TimeoutModal;
