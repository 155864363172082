import { mathSkills, readingWritingSkills } from '../../../../components/pages/main/questionbank/results/constants';
import {
  mathCheckboxValues,
  readingWritingCheckboxValues,
} from '../../../../components/pages/main/questionbank/search/constants';
import { IFilterCheckbox, IFilterSkillGroup } from '../types';

export const getInitialDifficultyFilters: () => IFilterCheckbox[] = () => {
  return [
    { value: 'Easy', checked: false },
    { value: 'Medium', checked: false },
    { value: 'Hard', checked: false },
  ];
};

export const getInitialSkillFilters: (domainScores: string[]) => IFilterSkillGroup[] = (domainScores) => {
  const initialSkillFilters: IFilterSkillGroup[] = [];

  if (domainScores.includes('INI')) {
    initialSkillFilters.push({
      domain: readingWritingCheckboxValues[0].label,
      checkboxes: readingWritingSkills['Information and Ideas'].map((skill) => {
        return { value: skill, checked: false };
      }),
    });
  }
  if (domainScores.includes('CAS')) {
    initialSkillFilters.push({
      domain: readingWritingCheckboxValues[1].label,
      checkboxes: readingWritingSkills['Craft and Structure'].map((skill) => {
        return { value: skill, checked: false };
      }),
    });
  }
  if (domainScores.includes('EOI')) {
    initialSkillFilters.push({
      domain: readingWritingCheckboxValues[2].label,
      checkboxes: readingWritingSkills['Expression of Ideas'].map((skill) => {
        return { value: skill, checked: false };
      }),
    });
  }
  if (domainScores.includes('SEC')) {
    initialSkillFilters.push({
      domain: readingWritingCheckboxValues[3].label,
      checkboxes: readingWritingSkills['Standard English Conventions'].map((skill) => {
        return { value: skill, checked: false };
      }),
    });
  }
  if (domainScores.includes('H')) {
    initialSkillFilters.push({
      domain: mathCheckboxValues[0].label,
      checkboxes: mathSkills.Algebra.map((skill) => {
        return { value: skill, checked: false };
      }),
    });
  }
  if (domainScores.includes('P')) {
    initialSkillFilters.push({
      domain: mathCheckboxValues[1].label,
      checkboxes: mathSkills['Advanced Math'].map((skill) => {
        return { value: skill, checked: false };
      }),
    });
  }
  if (domainScores.includes('Q')) {
    initialSkillFilters.push({
      domain: mathCheckboxValues[2].label,
      checkboxes: mathSkills['Problem-Solving and Data Analysis'].map((skill) => {
        return { value: skill, checked: false };
      }),
    });
  }
  if (domainScores.includes('S')) {
    initialSkillFilters.push({
      domain: mathCheckboxValues[3].label,
      checkboxes: mathSkills['Geometry and Trigonometry'].map((skill) => {
        return { value: skill, checked: false };
      }),
    });
  }

  return initialSkillFilters;
};
