import { useContext } from 'react';
import { CircularButton } from '@cb/apricot-react-button';
import { Checkbox } from '@cb/apricot-react-forms';
import { Popover } from '@cb/apricot-react-popover';
import { filteredTableDataContext } from '../../../../../../../contexts/questionbank/filteredTableData';

const LiveItemsFilter: React.FC = () => {
  const { setFilterLiveItems } = useContext(filteredTableDataContext);

  return (
    <div className="bluebook-questions-filter">
      <Checkbox
        label=""
        aria-label="Exclude Active Questions Toggle"
        width={20}
        height={20}
        onChange={(checked) => {
          setFilterLiveItems(checked);
        }}
      />
      <p id="no-wrap" className="cb-margin-left-16">
        Exclude Active Questions
      </p>
      <CircularButton
        icon="question-circle"
        label="Skill filter tooltip"
        id="showFilterPopover_LiveItems"
        className="cb-roboto-bold"
        small
        greyscale
      />
      <p className="filter-popover-icon">
        <Popover trigger="showFilterPopover_LiveItems" className="live-items-popover">
          <p>Use this filter to remove questions that are also included in official full-length practice tests.</p>
        </Popover>
      </p>
    </div>
  );
};

export default LiveItemsFilter;
