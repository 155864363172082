import { useEffect, useContext, useState, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Spinner } from '@satreg/spinner';
import { MathmlProvider, TestQuestionsDisplay } from '@satui/test-questions';
import { useFunctionalContentItems } from '@satui/drupal-profile';
import { webAnalyticsContext } from '@satreg/analytics';
import QuestionTallies from './questionTallies';
import TableNav from './tableNav';
import QuestionsTable from './questionsTable';
import { questionsSortFunction } from './questionsTable/utils';
import DetailsHeader from './header';
import { scoresContext } from '../../../../contexts/api/ScoresProvider';
import { questionsContext } from '../../../../contexts/api/QuestionsProvider';
import formatScoreData from './utils/formatScoreData';
import { IDetailsQuestionData, IDetailsScoreData, IQuestionTableRow } from './types';
import formatQuestionsData from './utils/formatQuestionsData';
import Breadcrumbs from './breadcrumbs';
import routes from '../../../../routes';
import { DETAILS_KEYS, PDF_KEYS } from '../../../drupal';
import { WA_ACTION_TYPES, WA_EVENTS } from '../../../analytics/constants';
import { IScoreAssessmentRes } from '../../../../types/scores';
import formatPdfScore from './utils/formatPdfData';
import { IScoreCardSummary } from '../dashboard/types';
import KnowledgeAndSkills from './knowledgeAndSkills';
import './styles/index.scss';

/**
 * Page containing details about a test.
 * Includes an overview of total, correct, incorrect and omitted questions, a table of each question with correct and student answers, and tabs to filter between test sections.
 */
const DetailsPage: React.FC = () => {
  const [scoreData, setScoreData] = useState<IDetailsScoreData>();
  const [questionsData, setQuestionsData] = useState<IDetailsQuestionData>();
  const [tab, setTab] = useState<number>(0);
  const [questionsTableData, setQuestionsTableData] = useState<IQuestionTableRow[]>();
  const { search } = useLocation();
  const history = useHistory();
  const { reportPageData, notifyAnalytics } = useContext(webAnalyticsContext);
  const [reportQuestion, setReportQuestion] = useState<number>();
  const [pdfScore, setPdfScore] = useState<IScoreCardSummary>();

  // if no query parameter, send user back to dashboard
  if (!search) history.push(routes.dashboard);

  // Questions Review - Open/Close flag
  const [openTestReview, setOpenTestReview] = useState(false);
  const [reviewButtonActive, setReviewButtonActive] = useState(true);

  // get scores context
  const scoresApiContext = useContext(scoresContext);
  const scoresLoaded = scoresApiContext.isLoaded;
  const { scores, studentInfo } = scoresApiContext;

  // get questions context
  const questionsApiContext = useContext(questionsContext);
  const questionsLoaded = questionsApiContext.isLoaded;
  const { getQuestions, questions } = questionsApiContext;

  // scroll to top on page load
  useEffect(() => window.scrollTo({ top: 0 }), []);

  // redirect user to dashboard or throw error if api calls fail
  useEffect(() => {
    if (scoresApiContext.error) {
      history.push(routes.dashboard);
    }

    if (questionsApiContext.error) {
      throw new Error('Questions service unavailable');
    }
  }, [scoresApiContext.error, questionsApiContext.error]);

  // set data from scores api to state
  useEffect(() => {
    if (scoresLoaded) {
      scores.forEach((score) => {
        if (score.rosterEntryId === search.substring(1)) {
          setPdfScore(formatPdfScore(score as IScoreAssessmentRes, studentInfo));
          setScoreData(formatScoreData(score as IScoreAssessmentRes));
        }
      });
    }
  }, [search, scores, scoresLoaded]);

  // call the questions api
  useEffect(() => {
    if (scoreData) {
      // set title
      document.title = `MyPractice - ${scoreData.scoreDetails.test} - ${scoreData.scoreDetails.date} - Details`;

      const rosterEntryId = search.substring(1);
      getQuestions(rosterEntryId, scoreData.asmtFamilyCd, scoreData.channelSource);
    }
  }, [scoreData, search, getQuestions]);

  // after questions api is called, if questions are loaded format the data and set to state
  useEffect(() => {
    if (questionsLoaded) {
      setQuestionsData(formatQuestionsData(questions!));
    }
  }, [questionsLoaded, questions]);

  /** On mount report the page change to WA */
  useEffect(() => {
    if (scoreData) {
      reportPageData({
        flowCode: 'satpractice',
        pageCode: 'score-details',
        appViewCode: 'all-questions',
        stateCode: scoreData.test.title,
      });
    }
  }, [scoreData]);

  /** Get a list of questions for a specific tab */
  const getTabQuestions = (tabIndex?: number) => {
    if (!questionsData) return undefined;
    switch (tabIndex) {
      case 0:
        return questionsData.tableQuestionData.all;
      case 1:
        return questionsData.tableQuestionData.reading;
      case 2:
        return questionsData.tableQuestionData.math;
      default:
        return undefined;
    }
  };

  // set question data according to the selected tab
  useEffect(() => {
    if (questionsData) {
      setQuestionsTableData(getTabQuestions(tab));
    }
  }, [questionsData, tab]);

  // if there is a question loaded in the question modal, report it to WA.
  useEffect(() => {
    if (reportQuestion !== undefined) {
      // report the currently selected number
      reportPageData({
        flowCode: 'satpractice',
        pageCode: 'question',
        appViewCode: `${reportQuestion + 1}`.padStart(3, '0'),
        stateCode: scoreData!.test.title,
      });
      // reset the reported question
      setReportQuestion(undefined);
    }
  }, [reportQuestion]);

  /**
   * Get All questions sorted by number for Review - for the Review Test button
   */
  const getReviewQuestions = () => {
    // get all questions
    const tabQuestions = getTabQuestions(0);
    if (!tabQuestions) return undefined;
    return questionsSortFunction(tabQuestions, { field: 'number', ascending: true }).map((row) => ({
      number: `${row.number}`,
      question: row.question,
    }));
  };

  const reviewQuestions = useMemo(getReviewQuestions, [questionsData]);

  const { drupalContent } = useFunctionalContentItems(Object.values({ ...DETAILS_KEYS, ...PDF_KEYS }));

  if (!drupalContent) return <Spinner />;
  const {
    [DETAILS_KEYS.HEADER]: headerContent,
    [DETAILS_KEYS.KHAN_LINK]: khanLinkContent,
    [DETAILS_KEYS.QUESTIONS]: questionsContent,
    [PDF_KEYS.PDF_LEGAL]: legalContent,
    [PDF_KEYS.PDF_RESOURCE]: resourceContent,
    [PDF_KEYS.PDF_COPYWRIGHT]: copywrightContent,
  } = drupalContent;

  /**
   * On tab change set current tab index to state and report tab to WA
   */
  const onTabChange = (panelId: string) => {
    const tabIndex = Number(panelId.split(' ')[1]);
    setTab(tabIndex);

    let appViewCode;
    switch (tabIndex) {
      case 0:
        appViewCode = 'all-questions';
        break;
      case 1:
        appViewCode = 'reading-writing';
        break;
      case 2:
        appViewCode = 'math';
        break;
      default:
        appViewCode = '';
    }

    reportPageData({
      flowCode: 'satpractice',
      pageCode: 'score-details',
      appViewCode,
      stateCode: scoreData!.test.title,
    });
  };

  /**
   * Close the Test Review dialog
   */
  const onCleanup = () => {
    setOpenTestReview(false);

    setTimeout(() => {
      setReviewButtonActive(true);
    }, 500);
  };

  const testTitle = scoreData ? `${scoreData.scoreDetails.test} - ${scoreData.scoreDetails.date}` : '';

  return scoreData && questionsTableData ? (
    <MathmlProvider>
      {reviewQuestions && (
        <div id="full-review">
          <TestQuestionsDisplay
            open={openTestReview}
            index={0}
            questions={reviewQuestions}
            onCleanup={onCleanup}
            hideDifficulty
            showExplanation
            allowPrint={false}
            withAnswerTrigger
            withExpandableAnswer={false}
            titleClassName="cb-roboto-black cb-h4"
            titlePrefix={testTitle}
            prefixClassName="display-block"
            onQuestionRendered={setReportQuestion}
          />
        </div>
      )}
      <DetailsHeader
        header={headerContent.title || ''}
        title={scoreData.header.title || ''}
        subTitle={testTitle}
        asmtId={scoreData.asmtId}
        asmtEventId={scoreData.asmtEventId}
        asmtFamilyCd={scoreData.asmtFamilyCd.toString()}
        asmtSubmissionStartTime={scoreData.asmtSubmissionStartTime}
        rosterEntryId={scoreData.rosterEntryId}
        startDate={scoreData.startDate}
        pdfScore={pdfScore!}
        legalContent={legalContent}
        resourceContent={resourceContent}
        copywrightContent={copywrightContent}
        onReview={() => {
          // Open the Review Test dialog
          notifyAnalytics(WA_EVENTS.TRACK_PRACTICE, {
            actionType: WA_ACTION_TYPES.FIRST_QUESTION,
            practiceTestNumber: scoreData.test.number.toString(),
            questionNumber: '001',
            stateCode: scoreData.test.title,
          });

          setReviewButtonActive(false);
          setOpenTestReview(true);
        }}
        khanAcademyLink={scoreData.khanAcademyLink}
        khanAcademyLinkText={khanLinkContent.extDescription || ''}
        questionBankData={scoreData.questionBankData}
      />
      <Breadcrumbs {...scoreData.breadcrumb} />
      <TableNav labelArr={scoreData.tableNavLabelArr} onTabChange={onTabChange} />
      <div id="test-details-container" className="container cb-padding-bottom-24">
        {!!scoreData.readingWritingKsdInfo.length && (
          <KnowledgeAndSkills
            asmtId={scoreData.asmtId}
            readingWritingKsdInfo={scoreData.readingWritingKsdInfo}
            mathKsdInfo={scoreData.mathKsdInfo}
            tab={tab}
            testId={scoreData.testId}
          />
        )}
        <div id="test-questions-overview" className="row cb-margin-bottom-48">
          <QuestionTallies title={questionsContent.title || ''} questionTallies={scoreData.questionTallies[tab]} />
        </div>
        <div id="questions-table-container" className="row">
          <QuestionsTable
            test={scoreData.test}
            data={questionsTableData}
            testTitle={testTitle}
            reviewButtonActive={reviewButtonActive}
            setReviewButtonActive={setReviewButtonActive}
            tab={tab}
          />
        </div>
      </div>
    </MathmlProvider>
  ) : (
    <Spinner />
  );
};

export default DetailsPage;
