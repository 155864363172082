import './styles/index.scss';
import { IQuestionTallies } from '../types';

interface IQuestionTalliesProps {
  title: string;
  questionTallies: IQuestionTallies;
}

/**
 * Component to render test overview tallies of total, correct, incorrect, and omitted questions.
 * Takes an object with this information as a prop.
 * @param questionTallies
 */
const QuestionTallies: React.FC<IQuestionTalliesProps> = ({ title, questionTallies }) => {
  return (
    <>
      <div id="questions-overview-title" className="col-xs-12">
        <div className="cb-margin-bottom-24">
          <h3>{title}</h3>
          <p>Review your results for each question from this practice test.</p>
        </div>
      </div>
      <div id="questions-overview-tallies" className="col-xs-12">
        <div className="total-quetions tally-card">
          <p className="number">{questionTallies.totalQuestions}</p>
          <p className="description">Total Questions</p>
        </div>
        <div className="correct-answers tally-card">
          <p className="number">{questionTallies.correctAnswers}</p>
          <p className="description">Correct Answers</p>
        </div>
        <div className="incorrect-answers tally-card">
          <p className="number">{questionTallies.incorrectAnswers}</p>
          <p className="description">Incorrect Answers</p>
        </div>
      </div>
    </>
  );
};

export default QuestionTallies;
