import { BlackButton } from '@cb/apricot-react-button';
import { Modal } from '@cb/apricot-react-modal';
import Cookies from 'universal-cookie';
import './styles/index.scss';
import ROUTES from '../../../../routes';

interface ITempSignoutModal {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
}

/**
 * Modal containing button allowing temp creds users to logout. Opens when temp creds users
 * click the icon on top right of header.
 */
const TempSignoutModal: React.FC<ITempSignoutModal> = ({ modalOpen, setModalOpen, name }) => {
  const cookies = new Cookies();
  /**
   * Function to logout as temp creds user.
   */
  const singOut = () => {
    // set the expiration to a date in the past to remove the cookie
    cookies.set('__Host-tempJwtToken', '', {
      path: '/',
      sameSite: 'strict',
      secure: true,
      expires: new Date('1/1/2000'),
    });

    // remember where user was (practice or questionbank) when logging out
    if (window.location.pathname.includes('questionbank')) {
      window.sessionStorage.setItem('route-to-qbank', 'true');
    } else if (!window.location.pathname.includes('login')) {
      window.sessionStorage.removeItem('route-to-qbank');
    }

    // Navigate back to temp creds login
    window.location.replace(ROUTES.access);
  };

  return (
    <Modal className="temp-signout-modal" open={modalOpen} withFooter={false} onClose={() => setModalOpen(false)}>
      <h2>
        <span>Hi, </span>
        {name}!
      </h2>
      <BlackButton className="signout-button" onClick={singOut}>
        Sign Out
      </BlackButton>
    </Modal>
  );
};

export default TempSignoutModal;
