import { createContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { getCatapultHeaders, getTempHeaders } from '../../api/utils';

interface IQbankLiveItemsDataContext {
  getQbankLiveItemsData: (params: { section: string }) => void;
  qbankLiveItemsData: string[];
  isLoaded: boolean;
  error: boolean;
}

const defaultState: IQbankLiveItemsDataContext = {
  getQbankLiveItemsData: () => {},
  qbankLiveItemsData: [],
  isLoaded: false,
  error: false,
};

export const qbankLiveItemsDataContext = createContext<IQbankLiveItemsDataContext>(defaultState);
const { Provider } = qbankLiveItemsDataContext;

/**
 * Provides a function for getting live items from the API, and provides the response.
 */
const QbankLiveItemsDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [qbankLiveItemsData, setQbankLiveItemsData] = useState<string[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  const cookies = new Cookies();

  /**
   * Calls the live-items api and sets the response to state
   */
  const getQbankLiveItemsData = useMemo(() => {
    const getData = (params: { section: string }) => {
      setQbankLiveItemsData([]);
      setIsLoaded(false);
      setError(false);
      const tempCookie = cookies.get('__Host-tempJwtToken');
      const headers = tempCookie ? getTempHeaders() : getCatapultHeaders();
      axios
        .post(process.env.REACT_APP_SQB_GET_LIVE_ITEMS_ENDPOINT!, params, { headers })
        .then(({ data }: { data: string[] }) => {
          setQbankLiveItemsData(data);
          setIsLoaded(true);
        })
        .catch(() => {
          setError(true);
          setIsLoaded(true);
        });
    };

    return getData;
  }, []);

  useEffect(() => {
    if (error) throw new Error('Questionbank live items service unavailable');
  }, [error]);

  return <Provider value={{ getQbankLiveItemsData, qbankLiveItemsData, isLoaded, error }}>{children}</Provider>;
};

export default QbankLiveItemsDataProvider;
